import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { Image } from 'src/redux/slices/sequenceSlice'
import styles from '../SequenceDemo.module.scss'

interface ISequenceHeaderImage {
  currentCardImage: Image
  isLoading: boolean
}

const SequenceHeaderImage: React.FC<ISequenceHeaderImage> = ({
  currentCardImage,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div className={styles.mobileBox__video}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <>
      {currentCardImage?.imageLocation ? (
        <div className={styles.mobileBox__image}>
          <img
            alt="heading"
            className={styles.headingImage}
            src={currentCardImage.imageLocation}
          ></img>
        </div>
      ) : (
        <div className={styles.mobileBox__video}>
          <p>Upload image from sidebar</p>
        </div>
      )}
    </>
  )
}

export default SequenceHeaderImage
