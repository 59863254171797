import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CopyIcon from '@material-ui/icons/FileCopy'
import { toast } from 'react-toastify'
import {
  changeUtmParam,
  selectShowUrls,
  selectUtmParams,
} from 'src/redux/slices/sequenceSlice'
import { UTMParams } from 'src/utils/sequenceSliceHelper'
import './WidgetSettingsUTMParams.css'

type UTMValidation = {
  [key in keyof UTMParams]: string | boolean
}

const WidgetSettingsUTMParams: React.FC<{
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  experienceId: string
}> = ({ setIsButtonDisabled, experienceId }) => {
  const dispatch = useDispatch()
  const utmParams = useSelector(selectUtmParams)
  const showUrls = useSelector(selectShowUrls)

  const [generatedUrl, setGeneratedUrl] = useState('')
  const [generatedAlternativeUrl, setGeneratedAlternativeUrl] = useState('')

  const [validationErrors, setValidationErrors] = useState<UTMValidation>({
    enabled: '',
    content: '',
    medium: '',
    campaign: '',
    source: '',
    term: '',
  })

  const handleButtonStatus = () => {
    if (
      utmParams['source'] === '' ||
      utmParams['medium'] === '' ||
      utmParams['campaign'] === ''
    ) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(false)
    }
  }

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
    toast.info('URL copied to clipboard')
  }

  const buildUrlFromUtmParams = () => {
    let url = showUrls?.[0] || '{{URL}}'
    const paramQueries = []
    for (const [key, value] of Object.entries(utmParams)) {
      if (value) {
        paramQueries.push(`utm_${key}=` + value)
      }
    }
    const generatedUrl = url + '?' + paramQueries.join('&')
    setGeneratedUrl(generatedUrl)
    setGeneratedAlternativeUrl(url + '?contester_display=true&contester_pid=' + experienceId)
  }

  useEffect(() => {
    buildUrlFromUtmParams()
  }, [utmParams, showUrls, experienceId])

  useEffect(() => {
    handleButtonStatus()
  }, [utmParams])

  const validate = (key: keyof UTMParams, value: string) => {
    if (value === '') {
      setValidationErrors({
        ...validationErrors,
        [key]: 'This field is required',
      })
    } else {
      setValidationErrors({
        ...validationErrors,
        [key]: '',
      })
    }
  }
  return (
    <div className="utm-params-wrapper">
      <form>
        <TextField
          margin="normal"
          fullWidth
          required
          error={!!validationErrors['source']}
          placeholder="The referrer (e.g. google, newsletter)"
          label="Campaign Source"
          defaultValue={utmParams['source']}
          onBlur={e => {
            validate('source', e.target.value)
            dispatch(
              changeUtmParam({
                key: 'source',
                value: e.target.value,
              }),
            )
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          required
          error={!!validationErrors['medium']}
          placeholder="Medium (e.g. cpc, banner, email)"
          label="Campaign Medium"
          defaultValue={utmParams['medium']}
          onBlur={e => {
            validate('medium', e.target.value)
            dispatch(
              changeUtmParam({
                key: 'medium',
                value: e.target.value,
              }),
            )
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          required
          error={!!validationErrors['campaign']}
          placeholder="Product, promo code (e.g. sprint_sale)"
          label="Campaign Name"
          defaultValue={utmParams['campaign']}
          onBlur={e => {
            validate('campaign', e.target.value)
            dispatch(
              changeUtmParam({
                key: 'campaign',
                value: e.target.value,
              }),
            )
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          placeholder="Use to differentiate ads"
          label="Campaign Content"
          defaultValue={utmParams['content']}
          onBlur={e => {
            dispatch(
              changeUtmParam({
                key: 'content',
                value: e.target.value,
              }),
            )
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          placeholder="Use to differentiate ads"
          label="Campaign Term"
          defaultValue={utmParams['term']}
          onBlur={e => {
            dispatch(
              changeUtmParam({
                key: 'term',
                value: e.target.value,
              }),
            )
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Generated URL"
          value={generatedUrl}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <CopyIcon
                className="copy-icon"
                onClick={() => copyToClipboard(generatedUrl)}
              />
            ),
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Alternative URL (works irregardless of provided UTM query parameters. Useful for ad campaigns)"
          value={generatedAlternativeUrl}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <CopyIcon
                className="copy-icon"
                onClick={() => copyToClipboard(generatedAlternativeUrl)}
              />
            ),
          }}
        />
      </form>
    </div>
  )
}

export default WidgetSettingsUTMParams
