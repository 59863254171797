import React, { useEffect, useState } from 'react'
import { DashboardModal } from '@uppy/react'
import Uppy, { BasePlugin } from '@uppy/core'
import { MediaStorageItemData } from '../../api/types'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { putImageMedia, saveImage } from '../../services/api/mediastorage'
import { mediaStorageGetSingle } from '../../api/services'

export interface UploaderProps {
  render: boolean
  media: MediaStorageItemData | null
  setMedia: React.Dispatch<React.SetStateAction<MediaStorageItemData | null>>
}

class ChunkedUploader extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts)
    this.id = opts.id || 'ChunkedUploader'
    this.type = 'upload'
    this.upload = this.upload.bind(this) // ← this!
    this.uploadCompleted = this.uploadCompleted.bind(this)
  }

  upload(fileIDs) {
    let file = this.uppy.getFile(fileIDs[0])
    this.uppy.setFileState(file.id, {
      progress: {
        uploadStarted: true,
      },
    })
    let media = this.uppy.getState().media
    let mediaItem
    if (media) {
      mediaItem = Promise.resolve(media)
    } else {
      mediaItem = Promise.resolve(putImageMedia())
    }

    return mediaItem.then(data => {
      let formData = new FormData()
      formData.append('file', file.data)
      return saveImage(formData, data.id, data => {
        this.uppy.setFileState(file.id, {
          progress: {
            uploadComplete: data.loaded === data.total,
            uploadStarted: true,
            percentage: (data.loaded / data.total) * 100,
          },
        })
        this.uppy.setState({
          totalProgress: (data.loaded / data.total) * 100,
        })
      })
        .then(() => {
          return mediaStorageGetSingle(data.id)
        })
        .then(data => {
          this.uppy.setState({
            media: data.data,
          })
        })
    })
  }

  uploadCompleted(fileIDs) {
    fileIDs.forEach(fileID => {
      const file = this.uppy.getFile(fileID)
      this.uppy.emit('preprocess-complete', file)
    })
  }

  install() {
    this.uppy.addUploader(this.upload)
    this.uppy.addPostProcessor(this.uploadCompleted)
  }

  uninstall() {
    this.uppy.removeUploader(this.upload)
    this.uppy.removePostProcessor(this.uploadCompleted)
  }
}

const Uploader: React.FC<UploaderProps> = ({ render, media, setMedia }) => {
  const uppy: Uppy = new Uppy({
    autoProceed: true,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
    },
  }).use(ChunkedUploader, { id: 'ChunkedUploader' })
  // .use(Webcam, { modes: ['audio-only'] })

  const [modalOpen, setModalOpen] = useState(false)
  const componentWillUnmount = function () {
    uppy.close({ reason: 'unmount' })
  }
  if (media !== null) {
    uppy.setState({
      media: media,
    })
  }

  useEffect(() => {
    return () => componentWillUnmount()
  }, [])

  const handleOpen = function () {
    setModalOpen(true)
  }

  const handleClose = function () {
    setModalOpen(false)
    setMedia(uppy.getState().media)
  }

  return (
    <div>
      {render && media !== null && (
        <img
          style={{ width: '50px', height: '50px' }}
          src={media.location}
          onClick={handleOpen}
        />
      )}
      {!render ||
        (media === null && (
          <button type="button" onClick={handleOpen}>
            Upload
          </button>
        ))}
      {modalOpen && (
        <DashboardModal
          uppy={uppy}
          closeModalOnClickOutside
          open={modalOpen}
          onRequestClose={handleClose}
          plugins={['Webcam']}
        />
      )}
    </div>
  )
}

export default Uploader
