import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { creatorProfileGet, userGetProfile } from '../../api/services'
import { CreatorProfileData, UserData } from '../../api/types'

interface IAuthSliceState {
  token: string
  isEmailVerified: boolean
  profileData: UserData | null
  creatorProfileData: CreatorProfileData | null
}

interface IAuthPayload {
  token: string
}

export const getProfileDataThunk = createAsyncThunk(
  'auth/getProfileData',
  async () => {
    let profileData = await userGetProfile()
    let data = profileData.data
    return {
      profileData: data,
    }
  },
)

export const getCreatorProfileDataThunk = createAsyncThunk(
  'auth/getCreatorProfileData',
  async () => {
    let creatorProfileData = await creatorProfileGet()
    let data = creatorProfileData.data
    return {
      creatorProfileData: data,
    }
  },
)

let initialState: IAuthSliceState = {
  token: '',
  isEmailVerified: false,
  profileData: null,
  creatorProfileData: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<IAuthPayload>) {
      state.token = action.payload.token
    },
    setEmailVerification(state, action: PayloadAction<boolean>) {
      state.isEmailVerified = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getProfileDataThunk.fulfilled, (state, action) => {
      state.profileData = action.payload.profileData
    })
    builder.addCase(getCreatorProfileDataThunk.fulfilled, (state, action) => {
      state.creatorProfileData = action.payload.creatorProfileData
    })
  },
})

export const { setAccessToken, setEmailVerification } = authSlice.actions
export default authSlice.reducer
