import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'src/redux/store'
import {
  getCreatorProfileDataThunk,
  getProfileDataThunk,
} from '../../../redux/slices/authSlice'
import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import CreatorProfile from './CreatorProfile'
import Profile from './Profile'

const UserSettings: React.FC = () => {
  const dispatch = useDispatch()
  const creatorPermissions = useSelector(
    (state: RootState) => state.organizationReducer.permissions.creator,
  )
  const profileData = useSelector(
    (state: RootState) => state.authReducer.profileData,
  )
  const creatorProfileData = useSelector(
    (state: RootState) => state.authReducer.creatorProfileData,
  )

  useEffect(() => {
    if (creatorPermissions.viewProfile && creatorPermissions.updateProfile) {
      dispatch(getCreatorProfileDataThunk())
    } else {
      dispatch(getProfileDataThunk())
    }
  }, [dispatch])

  return (
    <ContentWrapper title="User Details - Management">
      {creatorPermissions.viewProfile && creatorPermissions.updateProfile ? (
        <>
          {creatorProfileData && (
            <CreatorProfile creatorProfileData={creatorProfileData} />
          )}
        </>
      ) : (
        <Profile profileData={profileData} />
      )}
    </ContentWrapper>
  )
}

export default UserSettings
