import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { putImageMedia, saveImage } from '../../services/api/mediastorage'

interface UploadingMedia {
  [key: string]: boolean
}
interface IMediaSliceState {
  id: string
  location: any
  uploading?: UploadingMedia
  percentCompleted: number
}

let initialState: IMediaSliceState = {
  id: '',
  location: '',
  uploading: {},
  percentCompleted: 0,
}

export const putMediaIdDataThunk = createAsyncThunk(
  'media/getMediaId',
  async () => {
    return await putImageMedia()
  },
)

type postparams = { file: FormData; id: string }

export const postMediaIdDataThunk = createAsyncThunk(
  'media/setMediaId',
  async (obj: postparams, thunkApi) => {
    return await saveImage(obj.file, obj.id)
  },
)

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setMediaLoadingState(
      state,
      action: PayloadAction<{ cardId: string; status: boolean }>,
    ) {
      state.uploading[action.payload.cardId] = action.payload.status
    },
    setMediaLoadingProgress(state, action: PayloadAction<number>) {
      state.percentCompleted = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(putMediaIdDataThunk.fulfilled, (state, action) => {
        state.id = action.payload.id
      })
      .addCase(postMediaIdDataThunk.fulfilled, (state, action) => {
        state.id = action.payload.id
      })
  },
})

export const { setMediaLoadingState, setMediaLoadingProgress } =
  mediaSlice.actions
export default mediaSlice.reducer
