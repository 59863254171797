import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeHeaderStyles,
  HeadingType,
  ITreeItem,
  Title,
} from 'src/redux/slices/sequenceSlice'
import styles from '../../Sidebar.module.scss'

import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'

import { RootState } from 'src/redux/store'
import ColorPickerHeader from './ColorPickerHeader'

const useStyles = makeStyles(theme => ({
  rootSelect: {
    padding: '5px 0px 9px !important',
  },
  cardOption: {
    width: '100%',
    marginTop: '15px',
  },
  formControl: {
    margin: theme.spacing(2),
    width: '100%',
  },
}))

interface Props {
  currentCard: ITreeItem
}

const HeaderEditor: React.FC<Props> = ({ currentCard }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const fonts = useSelector((state: RootState) => state.helperReducer.fonts)
  const [cardAligment, setCardAligment] = useState('center')
  const [boldStatus, setBoldStatus] = useState(false)
  const [italicStatus, setItalicStatus] = useState(false)
  const [tempFontSlider, setTempFontSlider] = useState(25)

  useEffect(() => {
    let heading = currentCard.heading
    if (heading.type === HeadingType.title) {
      let styles = (heading as Title).styles
      setItalicStatus(!!styles?.['fontStyle'])
      setBoldStatus(!!styles?.['fontWeight'])
      setCardAligment(styles?.['aligment'] || 'center')
      setTempFontSlider(styles?.['fontSize'] || 25)
    }
  }, [currentCard])

  return (
    <>
      <div className={classes.cardOption}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Font</InputLabel>
          <Select
            id="card-type"
            onChange={e =>
              dispatch(
                changeHeaderStyles({
                  fontFamily: e.target.value,
                }),
              )
            }
            value={
              currentCard?.heading?.['styles']?.['fontFamily'] || 'Segoe UI'
            }
            label="Font"
          >
            {fonts.map(font => (
              <MenuItem value={font.name}>{font.name}</MenuItem>
            ))}

            {/* <MenuItem value="Segoe UI">Segoe UI</MenuItem>
            <MenuItem value="sans-serif">sans-serif</MenuItem>
            <MenuItem value="monospace">monospace</MenuItem>
            <MenuItem value="ITC Avant Garde Pro Bk">ITC Avant</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div className={classes.cardOption}>
        <Typography gutterBottom>Font size</Typography>
        <Slider
          value={tempFontSlider}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          min={2}
          max={75}
          defaultValue={20}
          onChange={(e, v) => {
            if (Array.isArray(v)) setTempFontSlider(v[0])
            else setTempFontSlider(v)
          }}
          onChangeCommitted={(e, v) => {
            dispatch(
              changeHeaderStyles({
                fontSize: v,
              }),
            )
          }}
        />
      </div>
      <div id="form-tooltips" className={styles.cardEditorTooltips}>
        <ColorPickerHeader styleProp="color" currentCard={currentCard} />
        <FormControl>
          <Select
            disableUnderline
            classes={{
              root: classes.rootSelect,
              select: classes.rootSelect,
            }}
            onChange={e => {
              setCardAligment(e.target.value)
              dispatch(
                changeHeaderStyles({
                  aligment: e.target.value,
                }),
              )
            }}
            value={cardAligment || 'center'}
            IconComponent={() => <></>}
          >
            <MenuItem value="center">
              <FormatAlignCenterIcon />
            </MenuItem>
            <MenuItem value="right">
              <FormatAlignRightIcon />
            </MenuItem>
            <MenuItem value="left">
              <FormatAlignLeftIcon />
            </MenuItem>
          </Select>
        </FormControl>
        <button
          className={boldStatus && styles.buttonStyleActive}
          onClick={() => {
            dispatch(
              changeHeaderStyles({
                fontWeight: !boldStatus ? 'bold' : 'unset',
              }),
            )
            setBoldStatus(!boldStatus)
          }}
        >
          B
        </button>
        <button
          onClick={() => {
            dispatch(
              changeHeaderStyles({
                fontStyle: !italicStatus ? 'italic' : 'normal',
              }),
            )
            setItalicStatus(!italicStatus)
          }}
          className={`${styles.italicButton} ${
            italicStatus && styles.buttonStyleActive
          }`}
        >
          I
        </button>
      </div>
    </>
  )
}

export default HeaderEditor
