import { DashboardOverallStatistics } from '../../api/types'
import Average from './SummaryOverview/Average'
import Total from './SummaryOverview/Total'
interface Props {
  overallStatistics: DashboardOverallStatistics
}
const SummaryOverview = ({ overallStatistics }: Props) => {
  return (
    <>
      <Total
        totalTimeSpent={overallStatistics?.timeSpent}
        totalLastCardClicks={overallStatistics?.lastCardClicks}
        totalVisitors={overallStatistics?.visitors}
      />

      <Average
        bounceRate={overallStatistics?.bounceRate}
        ctr={overallStatistics?.clickThroughRate}
        averageTime={overallStatistics?.averageTimeSpent}
      />
    </>
  )
}

export default SummaryOverview
