import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Grid,
  lighten,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core'

import SecurityIcon from '@material-ui/icons/Security'
import { experimentalStyled } from '@material-ui/core/styles'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from 'src/redux/store'
import NoDataImage from '../../assets/undraw_no_data.svg'
import { getBrandsDataThunk } from '../../redux/slices/brandSlice'
import {
  getSequencePollsDataThunk,
  setSequencePollObjList,
} from '../../redux/slices/sequencePollSlice'
import SequenceDataManager from './SequenceDataManager/SequenceDataManager'
import SequencePollImages from './SequencePollImages/SequencePollImages'
import styles from './SequencePollList.module.scss'
import SequencePollSearch from './SequencePollSearch/SequencePollSearch'
import {
  DashboardData,
  DashboardGetCreatorDataQueryParams,
  ExperienceCollectionData,
  PairDashboardTimeFilterString,
  SequencePollListData,
  WidgetSettings,
} from '../../api/types'
import { green, indigo } from '@material-ui/core/colors'
import { scrollToElm } from '../../utils/scroll'
import {
  dashboardGetAvailableTimeFilters,
  dashboardGetOrganizationData,
  experienceCollectionGetOrganizationOverview,
} from '../../api/services'
import SequencePollAnalytics from './SequencePollAnalytics/SequencePollAnalytics'
import { FilterType } from '../../pages/Dashboard'
import WidgetSettingsComponent from '../Home/WidgetSettingsComponent'
import { handlePromiseToast } from 'src/utils/toast.config'
import { saveSequencePoll } from '../../services/api/sequencePolls'
import { setSequenceId } from '../../redux/slices/sequenceSlice'
import { AlertData } from '../Home/Home'

const DrawerWrapper = experimentalStyled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 315px;
        height: calc(100% - ${theme.header.height});
        position: fixed;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.gradients.blue3};
        color: ${theme.colors.alpha.white[100]};
    }
`,
)

const ListDrawerWrapper = experimentalStyled(Drawer)(
  ({ theme }) => `
    width: calc(100% - 280px);
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: calc(100% - 675px);
        min-width: 315px;
        height: calc(100% - 255px);
        top: 250px;
        z-index: 1;
        border: 0;
        color: ${theme.colors.alpha.white[100]};
    }

    @media only screen and (min-width: 1280px) {
      & > .MuiPaper-root {
        left: 300px;        
        width: calc(100% - 670px);
      }
    }

    @media only screen and (max-width: 1279px) {
      & > .MuiPaper-root {
        left: 25px;
        width: calc(100% - 375px);
      }
    }    

    @media only screen and (max-width: 959px) {
      & > .MuiPaper-root {
        left: 25px;
        width: calc(100% - 365px);
      }
    }
`,
)

const CardActionAreaWrapper = experimentalStyled(CardActionArea)(
  ({ theme }) => `
      height: 225px;
      min-height: 225px;
      max-height: 225px;
      padding: 5px;
      margin-top: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;

      .MuiSvgIcon-root {
        opacity: .5;
      }

      .MuiTouchRipple-root {
        opacity: .3;
      }

      img {
        height: auto;
        width: 100%;
      }

      .MuiCardActionArea-focusHighlight {
        background: blue;
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: 0.04;
        }
      }
`,
)

const SequencePollCard = experimentalStyled(Card)(
  () => `
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    height: 310px;
    min-height: 310px;
    max-height: 310px;
  `,
)

const BrandsDropdown = experimentalStyled(Button)(
  () => `
  min-width: 125px;
`,
)

const SequenceBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
)

const SequenceNameBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
)

const BrandNameBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
)

const HeaderBox = experimentalStyled(Box)(
  () => `
      z-index: 1;
      background: white;
      padding-left: 23px;
      position: fixed;
`,
)

const SortButton = experimentalStyled(Button)(
  () => `
      min-width: 105px;
`,
)

const SequenceContainerWrapper = experimentalStyled(Container)(
  () => `
    overflow: hidden;
`,
)

export type TabValue =
  | 'Active'
  | 'Draft'
  | 'Archived'
  | 'Templates'
  | 'Creator experiences'

const SequencePollList: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()

  const [showAnalytics, setShowAnalytics] = useState(false)
  const [showVisiblityOptions, setShowVisibilityOptions] = useState(false)
  const [analyticsData, setAnalyticsData] = useState<DashboardData>()
  const [isInitial, setIsInitial] = useState(true)
  const [availableTimeFilters, setAvailableTimeFilters] = useState<
    PairDashboardTimeFilterString[]
  >([])

  const selectedSpQueryRef = useCallback(
    node => {
      if (node && isInitial) {
        setIsInitial(false)
        const container = document.querySelector('#sp-list-grid')?.firstChild
        if (container) {
          scrollToElm(container, node)
        }
      }
    },
    [isInitial],
  )
  const [firstItem, setFirstItem] = useState<boolean>(false)
  const [openBrand, setOpenMenuBrand] = useState<boolean>(false)
  const [filterByBrand, setFilterByBrand] = useState<string>('')
  const [experienceCollections, setExperienceCollections] = useState<
    ExperienceCollectionData[]
  >([])
  const [filterData, setFilterData] =
    useState<DashboardGetCreatorDataQueryParams>({
      brandId: undefined,
      timeFilter: 'LIFETIME',
    })
  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )
  const sequencePoll = useSelector((state: RootState) => state.sequenceReducer)
  const isEmailVerified = useSelector(
    (state: RootState) => state.authReducer.isEmailVerified,
  )
  const brands = useSelector((state: RootState) => state.brandsReducer.brands)

  const permissions = useSelector(
    (state: RootState) => state.organizationReducer.permissions,
  )

  const actionRef = useRef<any>(null)
  const actionRef1 = useRef<any>(null)
  const [currentTab, setCurrentTab] = useState<TabValue>(
    (new URLSearchParams(location.search).get('tab') as TabValue) ||
      (!isEmailVerified ? 'Draft' : 'Active'),
  )
  const tabs: { value: TabValue; label: string }[] = [
    { value: 'Active', label: 'Active' },
    { value: 'Draft', label: 'Draft' },
    { value: 'Archived', label: 'Archived' },
    { value: 'Templates', label: 'Templates' },
  ]

  if (permissions.experience.viewCreatorExperiences) {
    tabs.push({ value: 'Creator experiences', label: 'Creator experiences' })
  }

  const sortings = [
    {
      value: 'date',
      text: 'By date',
    },
    {
      value: 'name',
      text: 'By name',
    },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: TabValue): void => {
    setCurrentTab(value)
  }

  const dispatch = useDispatch()
  const sequencePollList = useSelector(
    (state: RootState) => state.sequencePollReducer.sequencePollList,
  )
  const activeOrganization = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )
  const [selectedPoll, setSelectedPoll] = useState<string>(
    new URLSearchParams(location.search).get('id') ||
      sequencePollList?.[0]?.sequencePoll?.id,
  )
  const [isLoading, setIsLoading] = useState(true)

  const [alertData, setAlertData] = useState<AlertData | null>(null)
  const [brand, setBrand] = useState<string>('All brands')
  const [openSort, setOpenMenuSort] = useState<boolean>(false)
  const [sort, setSort] = useState<string>(sortings[0].text)
  const [init, setInit] = useState(true)
  const [initialWidgetSettings, setInitialWidgetSettings] =
    useState<WidgetSettings>()
  const handleSort = (value: string) => {
    let filtered = []
    if (value === 'name')
      filtered = [...sequencePollList].sort((a, b) =>
        a?.sequencePoll?.name?.localeCompare(b?.sequencePoll?.name),
      )
    if (value === 'date') {
      filtered = [...sequencePollList]
        .sort((a, b) => {
          return (
            new Date(a?.sequencePoll?.createdAt).getTime() -
            new Date(b?.sequencePoll?.createdAt).getTime()
          )
        })
        .reverse()
    }
    dispatch(setSequencePollObjList(filtered))
  }

  const handleSelectOnePoll = (
    event: React.MouseEvent<HTMLElement>,
    pollId: string,
  ): void => {
    if (selectedPoll !== pollId) {
      setSelectedPoll(pollId)
    }
  }

  const getSequencePollsData = async () => {
    setIsLoading(true)
    await dispatch(
      getSequencePollsDataThunk({
        brandId: filterByBrand,
        tab: currentTab as TabValue,
        search: '',
      }),
    )
    setIsLoading(false)
  }

  const initData = async () => {
    dispatch(getBrandsDataThunk())
    try {
      let res = await experienceCollectionGetOrganizationOverview()
      setExperienceCollections(res.data)
    } catch (err) {
      // TODO - handle error
    }
  }
  const getAvailableTimeFilters = async () => {
    const res = await dashboardGetAvailableTimeFilters()
    setAvailableTimeFilters(res.data)
  }
  const getDashboardData = async (id: string, time?: any) => {
    setIsLoading(true)
    const res = await dashboardGetOrganizationData({
      timeFilter: time || filterData?.timeFilter,
      experienceId: id,
    })
    setShowAnalytics(true)
    setAnalyticsData(res.data)
    setIsLoading(false)
  }

  const handleFilterDataChange = (type: FilterType, value: any) => {
    if (type === 'BRAND') {
      setFilterData({
        ...filterData,
        brandId: value,
      })
    } else {
      getDashboardData(selectedPoll, value)
      setFilterData({
        ...filterData,
        timeFilter: value,
      })
    }
  }

  const handleSaveChanges = async (status?: boolean) => {
    console.log(sequencePoll)
    let tempStatus = sequencePoll?.widgetSettings?.displaySettings?.enabled
    let settings: WidgetSettings = {
      ...initialWidgetSettings,
      ...sequencePoll.widgetSettings,
      displaySettings: {
        ...sequencePoll.widgetSettings.displaySettings,
        enabled: status ?? initialWidgetSettings.displaySettings.enabled,
      },
      utm: {
        ...sequencePoll.widgetSettings.utm,
      },
    }
    if (status === undefined) {
      settings = {
        ...sequencePoll.widgetSettings,
        displaySettings: {
          ...sequencePoll.widgetSettings.displaySettings,
          enabled: status ?? tempStatus,
        },
        utm: {
          ...sequencePoll.widgetSettings.utm,
        },
      }
    }

    await handlePromiseToast(
      async () => {
        let result = await saveSequencePoll(
          sequencePoll.sequenceBox.items,
          sequencePoll.contentName,
          sequencePoll.brandId,
          sequencePoll.sequenceId,
          sequencePoll.status,
          sequencePoll.template,
          settings,
          sequencePoll.overlay,
          sequencePoll.ai,
        )

        if (!sequencePollId || sequencePollId === '') {
          dispatch(setSequenceId(result.id))
        }
        setShowVisibilityOptions(false)
      },
      'Widget settings saved successfully',
      'success',
      'Failed to save widget settings!',
      'error',
    )
  }

  useEffect(() => {
    getAvailableTimeFilters()
    initData()
  }, [])

  useEffect(() => {
    // setSelectedItem(location?.id)
    if (!isEmailVerified && !selectedPoll) {
      setCurrentTab('Draft')
    }
    if (!init) {
      getSequencePollsData()
    }
  }, [activeOrganization])

  useEffect(() => {
    getSequencePollsData()
  }, [currentTab, filterByBrand])

  useEffect(() => {
    if (!location.search.includes('id') && !selectedPoll) {
      setSelectedPoll(sequencePollList?.[0]?.sequencePoll?.id)
    }
  }, [sequencePollList])

  useEffect(() => {
    setInitialWidgetSettings(sequencePoll.widgetSettings)
  }, [sequencePoll?.sequenceId])

  // useEffect(() => {
  //   getDashbardData(selectedPoll)
  // }, [filterData, selectedPoll])

  return (
    <SequenceContainerWrapper maxWidth="lg">
      {!(showAnalytics || showVisiblityOptions) && (
        <>
          <HeaderBox
            sx={{
              width: {
                xs: 'calc(100% - 290px)',
                sm: 'calc(100% - 390px)',
                md: 'calc(100% - 400px)',
                lg: 'calc(100% - 700px)',
              },
              padding: 0,
            }}
          >
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Tabs
                onChange={handleTabsChange}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {tabs.map(tab => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <SortButton
                size="small"
                variant="outlined"
                ref={actionRef1}
                onClick={() => setOpenMenuSort(true)}
                endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
              >
                {sort}
              </SortButton>
              <Menu
                anchorEl={actionRef1.current}
                onClose={() => setOpenMenuSort(false)}
                open={openSort}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {sortings.map(_sort => (
                  <MenuItem
                    key={_sort.value}
                    onClick={() => {
                      setSort(_sort.text)
                      handleSort(_sort.value)
                      setOpenMenuSort(false)
                    }}
                  >
                    {_sort.text}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <Box sx={{ pb: 3 }} mt={3}> */}
            <Box display="flex" alignItems="center" sx={{ pb: 3 }} mt={3}>
              <BrandsDropdown
                sx={{ mr: 1 }}
                variant="outlined"
                color="secondary"
                ref={actionRef}
                onClick={() => setOpenMenuBrand(true)}
                endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
              >
                {brand}
              </BrandsDropdown>
              <Menu
                className="dropdown-class-maui"
                anchorEl={actionRef.current}
                onClose={() => setOpenMenuBrand(false)}
                open={openBrand}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  value="default"
                  onClick={() => {
                    setBrand('All brands')
                    setOpenMenuBrand(false)
                    setFilterByBrand('')
                  }}
                >
                  All brands
                </MenuItem>
                {brands.map(_brand => (
                  <MenuItem
                    key={_brand.id}
                    onClick={() => {
                      setBrand(_brand.name)
                      setOpenMenuBrand(false)
                      setFilterByBrand(_brand.id)
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={_brand?.logo?.location}
                        style={{
                          maxWidth: '24px',
                          maxHeight: '24px',
                        }}
                      />
                    </ListItemIcon>
                    <span>{_brand.name}</span>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: '#5569ff',
                        color: 'white',
                      }}
                    >
                      {_brand.statistics?.sequencePollActiveCount}
                    </Avatar>
                  </MenuItem>
                ))}
              </Menu>
              <SequencePollSearch
                filterByBrand={filterByBrand}
                status={currentTab}
                setIsLoading={setIsLoading}
              />
            </Box>
          </HeaderBox>
          <ListDrawerWrapper variant="permanent" anchor="left" open>
            <Scrollbars id="sp-list-grid">
              <Container maxWidth="md">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Grid
                      mt={0.5}
                      container
                      spacing={3}
                      style={{ gridTemplateColumns: 'auto' }}
                    >
                      {isLoading ? (
                        <CircularProgress className={styles.circularProgress} />
                      ) : sequencePollList?.length === 0 ? (
                        <div className="empty-list-experience">
                          <img alt="no images" src={NoDataImage} />
                          <h1>
                            Seems like there isn't any experiences created.
                          </h1>
                          <Button
                            onClick={() => {
                              history.push('/create-content')
                            }}
                          >
                            Create
                          </Button>
                        </div>
                      ) : (
                        sequencePollList?.map(
                          (listObject: SequencePollListData) => {
                            const sequencePoll = listObject.sequencePoll
                            const rootCards = listObject.rootCards
                            const brand = listObject.brand
                            const isPollSelected =
                              selectedPoll === sequencePoll.id
                            return (
                              <Grid
                                ref={
                                  isPollSelected
                                    ? selectedSpQueryRef
                                    : undefined
                                }
                                key={sequencePoll.id + Math.random()}
                                item
                                sx={{
                                  width: {
                                    xs: '240',
                                    sm: '900',
                                    md: '1060',
                                    lg: '1660',
                                    xl: '1920',
                                  },
                                }}
                                onClick={(
                                  event: React.MouseEvent<HTMLElement>,
                                ) => {
                                  handleSelectOnePoll(event, sequencePoll.id)
                                  setFirstItem(true)
                                }}
                              >
                                <SequencePollCard
                                  className="single-sequence-poll-card"
                                  style={{
                                    border: isPollSelected
                                      ? '1px solid blue'
                                      : '1px solid white',
                                  }}
                                >
                                  <CardActionAreaWrapper>
                                    <SequencePollImages
                                      key={sequencePoll.id}
                                      sequencePollItems={rootCards}
                                    />
                                  </CardActionAreaWrapper>
                                  <Divider />
                                  <CardActions
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      p: 2,
                                    }}
                                  >
                                    <Box>
                                      {() => {
                                        if (
                                          sequencePoll.globalTemplate ||
                                          sequencePoll.organizationTemplate ||
                                          sequencePoll.creatorTemplate
                                        )
                                          return (
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                              pb={0.5}
                                            >
                                              {sequencePoll.editable && (
                                                <>
                                                  <SecurityIcon
                                                    sx={{ color: green[500] }}
                                                  />
                                                  <SequenceBoxText>
                                                    <SequenceNameBoxLabel variant="body1">
                                                      {sequencePoll.name.replace(
                                                        /^(.{35}[^\s]*).*/,
                                                        '$1',
                                                      )}
                                                    </SequenceNameBoxLabel>
                                                    <BrandNameBoxLabel variant="body2">
                                                      Duplicate or edit to use
                                                    </BrandNameBoxLabel>
                                                  </SequenceBoxText>
                                                </>
                                              )}
                                              {!sequencePoll.editable && (
                                                <>
                                                  <SecurityIcon
                                                    sx={{ color: indigo[500] }}
                                                  />
                                                  <SequenceBoxText>
                                                    <SequenceNameBoxLabel variant="body1">
                                                      {sequencePoll.name.replace(
                                                        /^(.{35}[^\s]*).*/,
                                                        '$1',
                                                      )}
                                                    </SequenceNameBoxLabel>
                                                    <BrandNameBoxLabel variant="body2">
                                                      Duplicate to use
                                                    </BrandNameBoxLabel>
                                                  </SequenceBoxText>
                                                </>
                                              )}
                                            </Box>
                                          )
                                        else
                                          return (
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                              pb={0.5}
                                            >
                                              <Avatar src={brand.logo} />
                                              <SequenceBoxText>
                                                <SequenceNameBoxLabel variant="body1">
                                                  {sequencePoll.name.replace(
                                                    /^(.{35}[^\s]*).*/,
                                                    '$1',
                                                  )}
                                                </SequenceNameBoxLabel>
                                                <BrandNameBoxLabel variant="body2">
                                                  {brand.title}
                                                </BrandNameBoxLabel>
                                              </SequenceBoxText>
                                            </Box>
                                          )
                                      }}
                                    </Box>
                                  </CardActions>
                                </SequencePollCard>
                              </Grid>
                            )
                          },
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Scrollbars>
          </ListDrawerWrapper>
        </>
      )}
      {showAnalytics && (
        <SequencePollAnalytics
          availableTimeFilters={availableTimeFilters}
          filterData={filterData}
          setFilterData={setFilterData}
          analyticsData={analyticsData}
          handleFilterDataChange={handleFilterDataChange}
          showCloseButton={true}
          closeAnalytics={() => setShowAnalytics(false)}
        />
      )}
      {showVisiblityOptions && (
        <>
          <WidgetSettingsComponent
            setShowWidgetSettings={setShowVisibilityOptions}
            alertData={alertData}
            setAlertData={setAlertData}
            handleSaveChanges={handleSaveChanges}
            initialWidgetSettings={initialWidgetSettings}
            setInitialWidgetSettings={setInitialWidgetSettings}
          />
        </>
      )}
      <DrawerWrapper
        variant="permanent"
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        open
      >
        <Scrollbars>
          {selectedPoll && (
            <SequenceDataManager
              // filteredList={filteredList}
              // setFilteredList={setFilteredList}
              filterByBrand={filterByBrand}
              status={currentTab}
              sequencePollObj={sequencePollList?.find(sequencePollObj => {
                if (sequencePollObj.sequencePoll.id === selectedPoll)
                  return sequencePollObj
              })}
              experienceCollections={experienceCollections}
              getSequencePollsData={getSequencePollsData}
              showAnalytics={showAnalytics}
              setShowAnalytics={setShowAnalytics}
              setShowVisibilityOptions={setShowVisibilityOptions}
              getDashboardData={getDashboardData}
            />
          )}
        </Scrollbars>
      </DrawerWrapper>
    </SequenceContainerWrapper>
  )
}

export default SequencePollList
