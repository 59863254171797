import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { HeadingType, Title } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'

import styles from '../SequenceDemo.module.scss'

export interface SequenceTitleProps {
  name: string
}

const SequenceTitle: React.FC<SequenceTitleProps> = ({ name }) => {
  const currentHeading = useSelector(
    (state: RootState) =>
      state.sequenceReducer.currentProps.currentCard.heading,
  )

  let [heading, setHeading] = useState<Title | undefined>(
    currentHeading.type === HeadingType.title
      ? (currentHeading as Title)
      : undefined,
  )

  useEffect(() => {
    if (currentHeading.type === HeadingType.title)
      setHeading(currentHeading as Title)
  }, [currentHeading])

  if (!heading) {
    return <div>{name}</div>
  }

  if (currentHeading.type === HeadingType.title)
    return (
      <div
        className={styles.mobileBox__title}
        style={{
          ...heading?.styles,
          backgroundColor: 'transparent',
          width: '95%',
          justifyContent:
            heading?.styles?.['aligment'] === 'right'
              ? 'end'
              : heading?.styles?.['aligment'] === 'left'
              ? 'start'
              : 'center',
          opacity: 1,
          left:
            heading?.styles?.['aligment'] === 'left'
              ? '0%'
              : heading?.styles?.['aligment'] === 'center' ||
                heading?.styles?.['aligment'] === undefined
              ? '50%'
              : 'unset',
          right: heading?.styles?.['aligment'] === 'right' ? '0%' : 'unset',
        }}
      >
        <span>{name}</span>
      </div>
    )
}
export default SequenceTitle
