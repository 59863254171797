import { Card } from '@material-ui/core'
import './UpperCard.css'
interface Props {
  title: string
  fields: {
    key: string
    value: string
  }[]
}
const UpperCard = ({ title, fields }: Props) => {
  return (
    <div className="accountCard">
      <Card>
        <div className="header">
          <h4>{title}</h4>
        </div>
        <ul>
          {fields?.map(field => {
            return (
              <li key={field.key}>
                <span>{field.key}</span>
                <b>{field.value}</b>
              </li>
            )
          })}
        </ul>
      </Card>
    </div>
  )
}

export default UpperCard
