import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button } from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderUserbox from './Userbox/HeaderUserbox'

import RedoIcon from '@material-ui/icons/Redo'
import UndoIcon from '@material-ui/icons/Undo'
import { selectStep } from 'src/redux/slices/creatorSlice'
import { redoChange, undoChange } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import './Header.css'
import HeaderSubscriptionBox from './HeaderSubscriptionBox'

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: flex-end;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`,
)

function Header() {
  const dispatch = useDispatch()
  const step = useSelector(selectStep)
  const campaignName = useSelector(
    (state: RootState) => state.sequenceReducer.contentName,
  )
  const brands = useSelector((state: RootState) => state.brandsReducer.brands)
  const currentBrandId = useSelector(
    (state: RootState) => state.sequenceReducer.brandId,
  )
  const { user } = useAuth0()
  const isEmailVerified = useSelector(
    (state: RootState) => state.authReducer.isEmailVerified,
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
        e.preventDefault()
        return dispatch(undoChange())
      }
      if ((e.metaKey || e.ctrlKey) && e.key === 'y') {
        e.preventDefault()
        return dispatch(redoChange())
      }
    },
    [dispatch],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <HeaderWrapper display="flex">
      <div className={step === 2 ? 'root-single' : 'root-double'}>
        {step === 2 && (
          <div className="undo-redo-wrapper">
            <Button
              className="button-undo"
              variant="outlined"
              onClick={() => dispatch(undoChange())}
            >
              <UndoIcon />
            </Button>
            <Button
              size="small"
              className="button-redo"
              variant="outlined"
              onClick={() => dispatch(redoChange())}
            >
              <RedoIcon />
            </Button>
            <span className="header-brand-campaign-info">
              {brands.find(brand => brand.id === currentBrandId).name} /{' '}
              {campaignName}
            </span>
          </div>
        )}
        {!isEmailVerified && (
          <p className="email-verification">Email is not verified</p>
        )}
        <div style={{ display: 'flex' }}>
          <Box display="flex" alignItems="center">
            <HeaderSubscriptionBox />
          </Box>
          <Box display="flex" alignItems="center">
            {user ? <HeaderUserbox /> : ''}
          </Box>
        </div>
      </div>
    </HeaderWrapper>
  )
}

export default Header
