import { useAuth0 } from '@auth0/auth0-react'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileDataThunk } from '../../../redux/slices/authSlice'
import {
  editOrganizationProfileDataThunk,
  getActiveOrganizationThunk,
  getAvailableOrganizationsThunk,
  getUserInvitationsThunk,
} from '../../../redux/slices/organizationSlice'

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import { RootState } from 'src/redux/store'
import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import UserInvitations from './UserInvitations'
import { getBillingSubscriptionThunk } from '../../../redux/slices/billingSlice'
import { useHistory } from 'react-router-dom'

// const Input = experimentalStyled('input')({
//   display: 'none',
// });

// const IconButtonEdit = experimentalStyled(IconButton)(
//   ({ theme }) => `
//       margin-right: ${theme.spacing(0.5)};
//       padding: ${theme.spacing(0.4)};
//
//       .MuiSvgIcon-root {
//         font-size: ${theme.typography.pxToRem(17)};
//         color: ${theme.colors.primary.main};
//       }
//   `,
// );

// const AvatarWrapper = experimentalStyled(Box)(
//   ({ theme }) => `
//       position: relative;
//
//       .MuiAvatar-root {
//         width: ${theme.spacing(16)};
//         height: ${theme.spacing(16)};
//         margin-top: 5px;
//       }
//
//       .MuiAvatar-img{
//         width: 150px;
//         height: 150px;
//       }
//   `,
// );

// const ButtonUploadWrapper = experimentalStyled(Box)(
//   ({ theme }) => `
//       position: relative;
//       width: ${theme.spacing(6)};
//       height: ${theme.spacing(6)};
//       bottom: -${theme.spacing(2)};
//       margin: 0 auto;
//       margin-top: -40px;
//
//       .MuiIconButton-root {
//         border-radius: 100%;
//         background: ${theme.colors.primary.main};
//         color: ${theme.palette.primary.contrastText};
//         box-shadow: ${theme.colors.shadows.primary};
//         width: ${theme.spacing(6)};
//         height: ${theme.spacing(6)};
//         padding: 0;
//
//         &:hover {
//           background: ${theme.colors.primary.dark};
//         }
//       }
//   `,
// );

function OrganizationData() {
  const dispatch = useDispatch()

  const router = useHistory()

  const { user } = useAuth0()
  const organizationProfileData = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  const pendingInvitations = useSelector(
    (state: RootState) => state.organizationReducer.userInvitations,
  )

  const subscription = useSelector(
    (state: RootState) => state.billingReducer.subscription,
  )

  // const profileData = useSelector(
  //   (state: RootState) => state.authReducer.profileData,
  // );

  const [isEditing, setIsEditing] = useState(false)

  const [organizationName, setOrganizationName] = useState<string>(
    organizationProfileData?.name,
  )

  const [isRenaming, setRename] = useState<boolean>(false)
  const wrapperRef = useRef(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist()
    setOrganizationName(event.target.value)
  }

  const handleRenameInit = (): void => {
    setRename(true)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setOrganizationName(event.target.value)
      handleRename()
    }
  }

  const handleRename = async (): Promise<void> => {
    try {
      if (!organizationName) {
        setOrganizationName(organizationProfileData.name)
        setRename(false)
        return
      }
      const formData = {
        name: organizationName,
      }
      setRename(false)
      await dispatch(editOrganizationProfileDataThunk(formData))
      await dispatch(getActiveOrganizationThunk())
      await dispatch(getAvailableOrganizationsThunk())
    } catch (err) {
      console.error(err)
    }
    setIsEditing(false)
  }

  // const uploadOrganizationPicture = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   const logoFile = event.target.files;
  //
  //   if (!logoFile) return;
  //
  //   if (event.target.files.length !== 0) {
  //     setPictureUrl(URL.createObjectURL(logoFile[0]));
  //   }
  //   setPictureFileSelected(logoFile[0]);
  //
  //   if (event?.target?.files && event?.target.files[0]) {
  //     let fileData = new FormData();
  //     fileData.append('file', event?.target?.files[0]);
  //     putImageMedia().then(data => {
  //       saveImage(fileData, data.id).then(response => {
  //         const formData = {
  //           id: organizationProfileData.id,
  //           name: organizationProfileData.name,
  //         };
  //         dispatch(editOrganizationProfileDataThunk(formData));
  //       });
  //     });
  //   }
  // };

  useEffect(() => {
    dispatch(getUserInvitationsThunk())
  }, [dispatch])

  useEffect(() => {
    dispatch(getProfileDataThunk())
  }, [dispatch])

  useEffect(() => {
    dispatch(getBillingSubscriptionThunk())
  }, [dispatch])

  useEffect(() => {
    setOrganizationName(organizationProfileData?.name)
  }, [organizationProfileData])

  useEffect(() => {
    if (subscription && subscription.tier === 'PendingSelection') {
      router.push('/account/billing/subscription')
    }
  }, [subscription])

  return (
    <>
      <ContentWrapper title="User Details - Management">
        <Card sx={{ height: '100%' }}>
          {/*<AvatarWrapper>*/}
          {/*  <ButtonUploadWrapper>*/}
          {/*    <Input*/}
          {/*      accept="image/*"*/}
          {/*      id="icon-button-file-1"*/}
          {/*      name="icon-button-file-1"*/}
          {/*      type="file"*/}
          {/*      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
          {/*        uploadOrganizationPicture(e);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    {profileData.organizationAdmin && (*/}
          {/*      <label htmlFor="icon-button-file-1">*/}
          {/*        <IconButton component="span" color="primary">*/}
          {/*          <CloudUploadTwoToneIcon />*/}
          {/*        </IconButton>*/}
          {/*      </label>*/}
          {/*    )}*/}
          {/*  </ButtonUploadWrapper>*/}
          {/*</AvatarWrapper>*/}

          <Typography align="center" variant="h4" gutterBottom></Typography>

          <Box
            py={2}
            display="flex"
            alignItems="center"
            justifyContent="end"
            marginRight="30px"
          >
            {!isEditing ? (
              <Button
                variant="text"
                onClick={() => setIsEditing(true)}
                startIcon={<EditTwoToneIcon />}
              >
                Edit
              </Button>
            ) : (
              <Button onClick={handleRename}>Done</Button>
            )}
          </Box>

          <CardContent>
            <List sx={{ px: 2 }}>
              <Divider component="li" />
              <ListItem sx={{ py: 1.5 }}>
                <ListItemText
                  primary="Name"
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                />
                <Typography variant="subtitle2" color="text.primary">
                  <Box
                    px={2}
                    pt={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {isEditing ? (
                      <TextField
                        value={organizationName}
                        size="small"
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        ref={wrapperRef}
                        variant="outlined"
                        margin="dense"
                      />
                    ) : (
                      <Typography
                        color="inherit"
                        variant="h4"
                        onClick={handleRenameInit}
                      >
                        {organizationProfileData?.name?.trim()
                          ? organizationProfileData?.name
                          : user?.email}
                      </Typography>
                    )}
                    {/*{profileData.organizationAdmin && (*/}
                    {/*  <Box display="flex" alignItems="center">*/}
                    {/*    <Tooltip*/}
                    {/*      arrow*/}
                    {/*      placement="top"*/}
                    {/*      title='Rename'*/}
                    {/*    >*/}
                    {/*      <IconButtonEdit*/}
                    {/*        onClick={*/}
                    {/*          isRenaming ? handleRename : handleRenameInit*/}
                    {/*        }*/}
                    {/*      >*/}
                    {/*        <EditTwoToneIcon />*/}
                    {/*      </IconButtonEdit>*/}
                    {/*    </Tooltip>*/}
                    {/*  </Box>*/}
                    {/*)}*/}
                  </Box>
                </Typography>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </ContentWrapper>
      <ContentWrapper>
        <UserInvitations invitations={pendingInvitations} />
      </ContentWrapper>
    </>
  )
}

export default OrganizationData
