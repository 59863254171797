import { CircularProgress } from '@material-ui/core'
import { useEffect, useState } from 'react'
import 'src/components/Dashboard/Dashboard.css'
import SummaryOverview from 'src/components/Dashboard/SummaryOverview'
import UpperCard from 'src/components/Dashboard/UpperCard'
import {
  dashboardGetAvailableBrands,
  dashboardGetAvailableTimeFilters,
  dashboardGetOrganizationData,
} from '../api/services'
import {
  BrandData,
  DashboardData,
  DashboardGetCreatorDataQueryParams,
  PairDashboardTimeFilterString,
} from '../api/types'
import FullList from '../components/Dashboard/FullList/FullList'
import RecentlyCard from '../components/Dashboard/RecentlyCard'
import SummaryFilter from '../components/Dashboard/SummaryFilter'
import ExperienceTable from '../components/Dashboard/Tables/ExperienceTable'
import UserChoiceTable from '../components/Dashboard/Tables/UserChoiceTable'

export type TableType = 'EXPERIENCE' | 'USER' | undefined
export type FilterType = 'TIME' | 'BRAND'

const Dashboard = () => {
  const [showFullList, setShowFullList] = useState<TableType>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [filterData, setFilterData] =
    useState<DashboardGetCreatorDataQueryParams>({
      brandId: undefined,
      timeFilter: 'LAST_MONTH',
    })

  const [availableTimeFilters, setAvailableTimeFilters] = useState<
    PairDashboardTimeFilterString[]
  >([])
  const [availableBrands, setAvailableBrands] = useState<BrandData[]>([])

  const [dashboardData, setDashboardData] = useState<DashboardData>()

  const getDashbardData = async () => {
    const res = await dashboardGetOrganizationData({
      timeFilter: filterData.timeFilter,
      brandId: filterData?.brandId,
    })
    setDashboardData(res.data)
    setIsLoading(false)
  }

  // value any because we can't reuse type from generated types
  const handleFilterDataChange = (type: FilterType, value: any) => {
    if (type === 'BRAND') {
      setFilterData({
        ...filterData,
        brandId: value,
      })
    } else {
      setFilterData({
        ...filterData,
        timeFilter: value,
      })
    }
  }

  const getAvailableTimeFilters = async () => {
    const res = await dashboardGetAvailableTimeFilters()
    setAvailableTimeFilters(res.data)
  }

  const getAvailableBrands = async () => {
    const res = await dashboardGetAvailableBrands()
    setAvailableBrands(res?.data)
  }

  useEffect(() => {
    getAvailableTimeFilters()
    getAvailableBrands()
  }, [filterData])

  useEffect(() => {
    getDashbardData()
  }, [filterData])

  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <div className="dashboard">
      <div className="sticky-left-bar">
        <UpperCard
          title="Account"
          fields={[
            {
              key: 'Licence',
              value: dashboardData?.accountSummary?.licenseTier,
            },

            {
              key: 'Domain integration',
              value:
                dashboardData?.accountSummary?.origins?.current?.toString(),
            },
            {
              key: 'Brands and collection',
              value: dashboardData?.accountSummary?.brands?.current?.toString(),
            },
            {
              key: 'Active experiences',
              value:
                dashboardData?.accountSummary?.activeExperiences?.current?.toString(),
            },
          ]}
        />
        <RecentlyCard recentlyEdited={dashboardData?.recentlyEdited} />
      </div>

      {showFullList ? (
        <FullList
          experiences={dashboardData?.topPerformingExperiences}
          tableType={showFullList}
          setShowFullList={setShowFullList}
          userChoices={dashboardData?.topUserChoices}
        />
      ) : (
        <div className="summary-data">
          <div className="filters-wrapper">
            <SummaryFilter
              type="TIME"
              availableFilters={availableTimeFilters}
              filterData={filterData}
              setFilterData={handleFilterDataChange}
            />
            <SummaryFilter
              type="BRAND"
              availableFilters={availableBrands}
              filterData={filterData}
              setFilterData={handleFilterDataChange}
            />
          </div>
          <SummaryOverview
            overallStatistics={dashboardData?.overallStatistics}
          />
          <ExperienceTable
            experiences={dashboardData?.topPerformingExperiences}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
          <UserChoiceTable
            userChoices={dashboardData?.topUserChoices}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
        </div>
      )}
    </div>
  )
}

export default Dashboard
