import { Card } from '@material-ui/core'
import { DashboardCount } from '../../../api/types'
import GraphBg1 from '../../../assets/dash-bg-01.svg'
import GraphBg2 from '../../../assets/dash-bg-02.svg'
import GraphBg3 from '../../../assets/dash-bg-03.svg'
import './SummaryOverview.css'
interface Props {
  totalVisitors: DashboardCount
  totalLastCardClicks: DashboardCount
  totalTimeSpent: DashboardCount
}
const Total = ({
  totalTimeSpent,
  totalLastCardClicks,
  totalVisitors,
}: Props) => {
  return (
    <Card className="dashboard-summary-card">
      <div className="dashboard-summary-title">
        <p>Total</p>
      </div>
      <div className="dashboard-summary-average">
        <div className="dashboard-summary-time">
          <img src={GraphBg1} className="dashboard-graph-bg" />
          <div className="dashboard-summary-time-wrapper">
            <b>{totalVisitors?.displayValue}</b>
          </div>
          <p>Visitors</p>
        </div>

        <div className="dashboard-summary-time">
          <img src={GraphBg2} className="dashboard-graph-bg" />
          <div className="dashboard-summary-time-wrapper">
            <b>{totalTimeSpent?.displayValue}</b>
          </div>
          <p>Time spent</p>
        </div>

        <div className="dashboard-summary-time">
          <img src={GraphBg3} className="dashboard-graph-bg" />
          <div className="dashboard-summary-time-wrapper">
            <b>{totalLastCardClicks?.displayValue}</b>
          </div>
          <p>Last card clicks</p>
        </div>
      </div>
    </Card>
  )
}

export default Total
