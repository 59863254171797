import { experimentalStyled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import React from 'react'

const ImagesWrapper = experimentalStyled(Box)(
  () => `
    display: flex;
    flex-wrap: wrap;
`,
)

const ImageContentWrapper = experimentalStyled(Box)(
  () => `
    position: relative;
    width: 48%;
    margin-left: 1px;
    margin-bottom: 2px;
    padding: 1px;
    img {
      width: 112px;
      height: 104px;
      object-fit: cover;
      animation: fadeIn 0.5s ease 1;
    }
`,
)

type Props = {
  sequencePollItems: any
}

const SequencePollImages: React.FC<Props> = ({ sequencePollItems }) => {
  return (
    <>
      {sequencePollItems ? (
        <ImagesWrapper>
          {sequencePollItems.map(item => {
            return (
              <ImageContentWrapper key={item.id + Math.random()}>
                <img src={item.image} />
              </ImageContentWrapper>
            )
          })}
        </ImagesWrapper>
      ) : (
        ''
      )}
    </>
  )
}

export default SequencePollImages
