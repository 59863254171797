import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LiveStreamListData, LiveStreamUpdateRequest } from '../../api/types'
import {
  liveStreamsCreate,
  liveStreamsGetAll,
  liveStreamsUpdate,
  liveStreamsDelete,
} from '../../api/services'
interface IStreamSliceState {
  liveStreams: LiveStreamListData[]
  isLoading: boolean
}

let initialState: IStreamSliceState = {
  liveStreams: [],
  isLoading: true,
}

export type SortType = 'scheduledAt' | 'title'

export const getLiveStreamsThunk = createAsyncThunk(
  'liveStreams/getLiveStreams',
  async (isFuture?: boolean, sort: string = 'scheduledAt') => {
    let liveStreams = await liveStreamsGetAll({
      perPage: 500,
      future: isFuture,
      sort: sort,
      sortOrder: 'asc',
    })

    // API returns numbers in seconds, moment.js deals in milliseconds.
    liveStreams.data.forEach(d => (d.scheduledAt *= 1000))
    return { liveStreams, success: true }
  },
)

export const addLiveStreamThunk = createAsyncThunk(
  'liveStreams/addLiveStream',
  async (liveStream: any, thunkAPI) => {
    try {
      const res = await liveStreamsCreate(liveStream)
      if (res.status === 200) {
        thunkAPI.dispatch(getLiveStreamsThunk(true))
        return { success: true }
      }
    } catch (err) {
      thunkAPI.rejectWithValue(err.data)
      return { success: false }
    }
  },
)

export const updateLiveStreamThunk = createAsyncThunk(
  'liveStreams/updateLiveStream',
  async (
    request: { id: string; liveStream: LiveStreamUpdateRequest },
    thunkAPI,
  ) => {
    try {
      const res = await liveStreamsUpdate(request.id, request.liveStream)
      if (res.status === 200) {
        thunkAPI.dispatch(getLiveStreamsThunk(true))
        return { success: true }
      }
    } catch (err) {
      thunkAPI.rejectWithValue(err.data)
      return { success: false }
    }
  },
)

export const deleteLiveStreamThunk = createAsyncThunk(
  'liveStreams/deleteLiveStream',
  async (id: string, thunkAPI) => {
    try {
      const res = await liveStreamsDelete(id)
      if (res.status === 200) {
        return { id, success: true }
      }
    } catch (err) {
      thunkAPI.rejectWithValue(err.data)
      return { success: false }
    }
  },
)

const liveStreamsSlice = createSlice({
  name: 'liveStreams',
  initialState,
  reducers: {
    setLiveStreamsList(state, action: PayloadAction<LiveStreamListData[]>) {
      state.liveStreams = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getLiveStreamsThunk.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getLiveStreamsThunk.fulfilled, (state, action) => {
      state.liveStreams = action.payload.liveStreams.data
      state.isLoading = false
    })
    builder.addCase(deleteLiveStreamThunk.fulfilled, (state, action) => {
      state.liveStreams = state.liveStreams.filter(
        stream => stream.id !== action.payload.id,
      )
    })
  },
})

export const { setLiveStreamsList } = liveStreamsSlice.actions

export default liveStreamsSlice.reducer
