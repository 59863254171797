import { Route } from 'react-router-dom'
import React from 'react'

const ProtectedRoute = ({
  component: Component,
  isAccessAvailable,
  ...args
}) => {
  if (isAccessAvailable) {
    return <Route render={() => <Component {...args} />} />
  } else {
    return <></>
  }
}
export default ProtectedRoute
