import { routes } from 'src/constants'
import { request } from '../apiServices'

export const updateProfile = async (data: {
  firstName: string
  lastName: string
}) => {
  try {
    return await request({
      url: routes.UPDATE_PROFILE,
      method: 'POST',
      data,
    })
  } catch (err) {
    return err
  }
}
