import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import React, { useEffect } from 'react'
import { getBillingSubscriptionThunk } from '../../../redux/slices/billingSlice'
import { Alert } from '@material-ui/core'
import { Link } from 'react-router-dom'

function HeaderSubscriptionBox() {
  let dispatch = useDispatch()

  const subscription = useSelector(
    (state: RootState) => state.billingReducer.subscription,
  )

  useEffect(() => {
    if (!subscription) {
      dispatch(getBillingSubscriptionThunk())
    }
  }, [dispatch])

  return (
    <>
      {subscription && subscription.tier === 'CreatorStandard' && (
        <Link to={'/account/billing/subscription'}>
          <Alert severity={'success'}>
            Creator account
            {/*until {subscription.nextBillingDate}*/}
          </Alert>
        </Link>
      )}
      {subscription &&
        subscription.tier !== 'CreatorStandard' &&
        subscription.tier !== 'PendingSelection' &&
        subscription.status === 'Active' && (
          <Link to={'/account/billing/subscription'}>
            <Alert severity={'success'}>
              Subscription is active
              {/*until {subscription.nextBillingDate}*/}
            </Alert>
          </Link>
        )}
      {subscription &&
        subscription.tier !== 'CreatorStandard' &&
        subscription.tier !== 'PendingSelection' &&
        subscription.status === 'Trial' && (
          <Link to={'/account/billing/subscription'}>
            <Alert severity={'warning'}>
              Trial subscription
              {/*ends on {subscription.nextBillingDate}*/}
            </Alert>
          </Link>
        )}
      {subscription &&
        subscription.tier !== 'CreatorStandard' &&
        subscription.tier !== 'PendingSelection' &&
        subscription.status === 'Suspended' && (
          <Link to={'/account/billing/subscription'}>
            <Alert severity={'warning'}>
              Your subscription is suspended
              {/*ends on {subscription.nextBillingDate}*/}
            </Alert>
          </Link>
        )}
      {subscription &&
        subscription.tier !== 'CreatorStandard' &&
        subscription.tier !== 'PendingSelection' &&
        subscription.status === 'Cancelled' && (
          <Link to={'/account/billing/subscription'}>
            <Alert severity={'warning'}>
              Your subscription is cancelled
              {/*ends on {subscription.nextBillingDate}*/}
            </Alert>
          </Link>
        )}
    </>
  )
}

export default HeaderSubscriptionBox
