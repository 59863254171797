import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SequenceBoxItems from './Items/SequenceBoxItems'

import { ITreeItem, setTreeItems } from 'src/redux/slices/sequenceSlice'
import styles from './SequenceBox.module.scss'

import { RootState } from 'src/redux/store'

export interface SequenceBoxProps {
  dndType: string
  selectable: boolean
}

const SequenceBox: React.FC<SequenceBoxProps> = ({ dndType, selectable }) => {
  const dispatch = useDispatch()
  const sequenceBox = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceBox,
  )

  const [activeItem, setActiveItem] = useState<string>('')

  const addProductFromList = (items: ITreeItem[]) => {
    dispatch(setTreeItems(items))
  }

  return (
    <div className={styles.sequenceBox}>
      <SequenceBoxItems
        dndType={dndType}
        data={sequenceBox.items}
        handleUpdateItem={addProductFromList}
        selectable={selectable}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
      {sequenceBox.items.length === 0 && (
        <span className={styles.emptyBoxMessage}>
          Drag and drop categories and products here or create a manual item to
          make an agile app
        </span>
      )}
    </div>
  )
}

export default SequenceBox
