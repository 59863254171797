import { routes } from 'src/constants'
import { request } from '../apiServices'

export const getStripeSetupData = async () => {
  try {
    return await request({
      url: `${routes.BILLING_CARDS}/setup`,
      method: 'GET',
    })
  } catch (err) {
    return err
  }
}

export const getBillingCardsList = async () => {
  try {
    return await request({
      url: `${routes.BILLING_CARDS}`,
      method: 'GET',
    })
  } catch (err) {
    return err
  }
}

export const getBillingDetails = async () => {
  try {
    return await request({
      url: `${routes.BILLING_DETAILS}`,
      method: 'GET',
    })
  } catch (err) {
    return err
  }
}
export const updateBillingDetails = async data => {
  try {
    return await request({
      url: `${routes.BILLING_DETAILS}`,
      method: 'PUT',
      data: data,
    })
  } catch (err) {
    return err
  }
}

export const getBillingPricingList = async () => {
  try {
    return await request({
      url: `${routes.BILLING_PRICING}`,
      method: 'GET',
    })
  } catch (err) {
    return err
  }
}

export const setActiveBillingCard = async cardId => {
  try {
    return await request({
      url: `${routes.BILLING_CARDS}/${cardId}/setActive`,
      method: 'POST',
    })
  } catch (err) {
    return err
  }
}

export const removePaymentCard = async cardId => {
  try {
    return await request({
      url: `${routes.BILLING_CARDS}/${cardId}`,
      method: 'DELETE',
    })
  } catch (err) {
    return err
  }
}
