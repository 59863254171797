import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IModalData {
  name: string
  data?: any
}
interface IModalState {
  modalsList: IModalData[]
}

let initialState: IModalState = {
  modalsList: [],
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModalItem(state, action: PayloadAction<IModalData>) {
      state.modalsList.push({
        name: action.payload.name,
        data: action.payload.data,
      })
    },
    closeModalItem(state, action: PayloadAction<string>) {
      state.modalsList = state.modalsList.filter(
        modalName => modalName.name !== action.payload,
      )
    },
  },
})
export const { openModalItem, closeModalItem } = modalSlice.actions
export default modalSlice.reducer
