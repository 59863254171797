import { Box, Button, Menu, MenuItem } from '@material-ui/core'
import { useRef, useState } from 'react'
import {
  DashboardGetCreatorDataQueryParams,
  PairDashboardTimeFilterString,
  BrandData,
} from '../../api/types'
import { FilterType } from '../../pages/Dashboard'

interface Props {
  availableFilters: PairDashboardTimeFilterString[] | BrandData[]
  filterData: DashboardGetCreatorDataQueryParams
  type: FilterType
  setFilterData: (type: FilterType, value: string) => void
}

// TODO - refactor
const SummaryFilter = ({
  availableFilters,
  filterData,
  type,
  setFilterData,
}: Props) => {
  const actionRef = useRef<any>(null)
  const [filterOpen, setFilterOpen] = useState(false)
  return (
    <div>
      <Box className={'summary-filter-wrapper'}>
        {type === 'TIME' && (
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            color="secondary"
            ref={actionRef}
            onClick={() => setFilterOpen(true)}
          >
            {
              availableFilters.find(function (i) {
                return i.first == filterData.timeFilter
              })?.second
            }
          </Button>
        )}
        {type === 'BRAND' && (
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            color="secondary"
            ref={actionRef}
            onClick={() => setFilterOpen(true)}
          >
            {availableFilters.find(function (i) {
              return i.id == filterData.brandId
            })?.name || 'All brands'}
          </Button>
        )}
        <Menu
          className="dropdown-class-maui"
          anchorEl={actionRef.current}
          onClose={() => setFilterOpen(false)}
          open={filterOpen}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {type === 'BRAND' && (
            <MenuItem
              key={'All brands'}
              onClick={() => {
                setFilterOpen(false)
                setFilterData(type, undefined)
              }}
            >
              <span>All brands</span>
            </MenuItem>
          )}
          {availableFilters.map(filter => (
            <MenuItem
              key={filter.first || filter?.id}
              onClick={() => {
                setFilterOpen(false)
                setFilterData(type, filter.first || filter?.id)
              }}
            >
              <span>{filter.second || filter?.name}</span>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </div>
  )
}

export default SummaryFilter
