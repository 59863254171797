import * as React from 'react'

import SequenceHeader from '../SequenceDemo/SequenceHeader/SequeceHeader'
import SequenceImages from '../SequenceDemo/Sequenceimages/SequenceImages'
import SequenceTitle from '../SequenceDemo/SequenceTitle/SequenceTitle'

import { useSelector } from 'react-redux'
import { HeadingType, Image, Video } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import styles from '../SequenceDemo/SequenceDemo.module.scss'
import SequenceHeaderImage from '../SequenceDemo/SequenceHeader/SequenceHeaderImage'
import SequenceHeaderVideo from '../SequenceDemo/SequenceHeader/SequenceHeaderVideo'

export interface SequencePreviewProps {}

const SequencePreview: React.FC<SequencePreviewProps> = props => {
  const { items } = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceBox,
  )
  const mediaLoadingProgress = useSelector(
    (state: RootState) => state.mediaReducer.percentCompleted,
  )
  const hasTitle = items[0].heading?.type === HeadingType.title
  const hasVideoOrImage =
    items[0].heading?.type === HeadingType.video ||
    items[0].heading?.type === HeadingType.image

  const titleStyle = hasTitle ? styles.hasTitle : ''
  const hasSixItems = items[0].children && items[0].children.length > 4
  const sixItemsStyle = hasSixItems ? styles.hasSixItems : ''
  const hasVideoStyle = hasVideoOrImage ? styles.hasVideoOrImage : ''

  return (
    <div className={styles.sequenceDemo}>
      <div
        className={`${styles.mobileBox} ${titleStyle} ${sixItemsStyle} ${hasVideoStyle}`}
      >
        <SequenceHeader />
        {hasTitle && <SequenceTitle name={items[0]?.heading?.titleText} />}
        {items[0]?.heading?.type === HeadingType.image && (
          <SequenceHeaderImage
            isLoading={false}
            currentCardImage={items[0].heading as Image}
          />
        )}
        {items[0]?.heading?.type === HeadingType.video && (
          <SequenceHeaderVideo
            loadingProgress={mediaLoadingProgress}
            isLoading={false}
            currentCardVideo={items[0].heading as Video}
          />
        )}
        <SequenceImages data={items[0]} type={items[0].cardTreeType} />
      </div>
    </div>
  )
}

export default SequencePreview
