import { AnyAction, configureStore, PayloadAction } from '@reduxjs/toolkit'
import { rootReducer } from './rootReducer'

const historyMiddleware = store => next => (action: AnyAction) => {
  next(action)
  if (action.type) {
    const actionList = [
      'addTagsToCard',
      'duplicateTreeItem',
      'addFileToCard',
      'changeCardType',
      'changeHeaderStyles',
      'changeCardHeader',
      'changeCardLabelStyles',
      'addNewTreeItem',
      'setTreeItems',
      'resetSequenceData',
    ]
    const shouldTrigger = actionList.some(el =>
      (action.type as string).includes(el),
    )
    if ((action.type as string).startsWith('sequence/') && shouldTrigger) {
      const sideEffectAction: PayloadAction = {
        payload: null,
        type: 'sequence/saveActionToHistory',
      }

      next(sideEffectAction)
    }
  }
}

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware, historyMiddleware)),
// );

const store = configureStore({
  reducer: { ...rootReducer },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(historyMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
