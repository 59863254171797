import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  changeCardLabelStyles,
  ITreeItem,
} from 'src/redux/slices/sequenceSlice'

interface Props {
  currentCard: ITreeItem
  disabledStatus: boolean
  styleProp: string
}

const ColorPicker: React.FC<Props> = ({
  disabledStatus,
  currentCard,
  styleProp,
}) => {
  const dispatch = useDispatch()
  const [currColor, setCurrColor] = useState(
    currentCard.cardType?.['styles']?.[styleProp],
  )
  const [colorQuery, setColorQuery] = useState(
    currentCard.cardType?.['styles']?.[styleProp],
  )

  useEffect(() => {
    let color = currentCard.cardType?.['styles']?.[styleProp]
    if (color) setCurrColor(color)
    else setCurrColor(styleProp === 'backgroundColor' ? '#000000' : '#ffffff')
  }, [currentCard.cardId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        changeCardLabelStyles({
          [styleProp]: colorQuery,
        }),
      )
    }, 200)
    return () => clearTimeout(timeout)
  }, [colorQuery, dispatch])

  return (
    <input
      disabled={disabledStatus}
      type="color"
      value={currColor || '#ffffff'}
      onChange={e => {
        setCurrColor(e.target.value)
        setColorQuery(e.target.value)
      }}
    />
  )
}

export default ColorPicker
