import { Divider, FormControl, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { changeOverlayUrl } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import '../WidgetSettings.css'
import React from 'react'

const WidgetSettingsOverlay = () => {
  const dispatch = useDispatch()
  const overlayUrl = useSelector(
    (state: RootState) => state.sequenceReducer.overlay?.url,
  )
  const isOverlayEditable = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.updateOverlaySettings,
  )
  return (
    isOverlayEditable && (
      <>
        <Divider variant="middle" />
        <FormControl className="overlay-url-wrapper">
          <p>
            <b>Overlay settings</b>
          </p>
          <TextField
            defaultValue={overlayUrl || ''}
            onChange={e => dispatch(changeOverlayUrl(e.target.value))}
            label="Overlay URL to start this video experience"
            fullWidth
          />
        </FormControl>
      </>
    )
  )
}

export default WidgetSettingsOverlay
