import styled from 'styled-components'

export const ImageWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  &::after {
    content: '';
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: ${props => props.background};
    opacity: ${props => props.opacity};
  }
`
