import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import React, { ChangeEvent, useState } from 'react'
import './OrganizationData.css'
import { OrganizationPendingInvitation } from '../../../api/types'

interface Props {
  invitations: OrganizationPendingInvitation[]
  type: 'Member' | 'Creator'
  canCancelInvitation: boolean
  handleSetCancelInvitationId: (id: string) => void
}

const applyInvitationsPagination = (
  invitations?: OrganizationPendingInvitation[],
  page?: number,
  limit?: number,
): OrganizationPendingInvitation[] => {
  return invitations?.slice(page * limit, page * limit + limit)
}

const InvitationsTable = ({
  invitations,
  type,
  canCancelInvitation,
  handleSetCancelInvitationId,
}: Props) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState<number>(10)

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  const paginatedInvitations = applyInvitationsPagination(
    invitations.filter(invitation => invitation.type === type),
    page,
    limit,
  )

  return (
    paginatedInvitations?.length > 0 && (
      <div className="table-wrapper">
        <Card>
          <TableContainer>
            <Typography
              style={{ padding: '5px 10px ' }}
              fontWeight="normal"
              variant="h3"
            >
              {' '}
              {(type === 'Creator' ? 'Creator' : 'Member') + 's'}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInvitations?.map(invitation => {
                  return (
                    <TableRow hover key={invitation.id} style={{ height: 80 }}>
                      <TableCell>
                        <Typography variant="h5">{invitation.email}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        {canCancelInvitation && (
                          <Typography noWrap>
                            <Tooltip title="Remove" arrow>
                              <IconButton
                                onClick={() =>
                                  handleSetCancelInvitationId(invitation.id)
                                }
                                color="primary"
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={
                invitations.filter(invitation => invitation.type === type)
                  ?.length
              }
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </Card>
      </div>
    )
  )
}

export default InvitationsTable
