import { ReactNode } from 'react'

import AutoGraphRoundedIcon from '@material-ui/icons/AutoGraphRounded'
import SettingsSuggestOutlinedIcon from '@material-ui/icons/SettingsSuggestOutlined'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import CenterFocusWeakRoundedIcon from '@material-ui/icons/CenterFocusWeakRounded'
import AutoAwesomeRoundedIcon from '@material-ui/icons/AutoAwesomeRounded'

import { IActiveOrganizationState } from '../../../../redux/slices/organizationSlice'

export interface MenuItem {
  link?: string
  icon?: ReactNode
  badge?: string
  items?: MenuItem[]
  name: string
  resetSequenceData?: boolean
  active?: boolean
  // permission may render this menu item unavailable
  available: (state: IActiveOrganizationState) => boolean
}

export interface MenuItems {
  items: MenuItem[]
  heading: string
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Summary',
        icon: AutoGraphRoundedIcon,
        link: '/summary',
        available: state => {
          return state.permissions?.menu?.analytics === true
        },
      },
      {
        name: 'Account',
        link: '/account',
        icon: SettingsSuggestOutlinedIcon,
        available: () => {
          return true
        },
        items: [
          {
            name: 'User settings',
            link: '/account/user-settings',
            available: state => {
              return (
                state.permissions?.menu?.user ||
                state.permissions?.menu?.creatorProfile
              )
            },
          },
          {
            name: 'Organization',
            link: '/account/organization',
            available: state => {
              return state.permissions?.menu?.organization === true
            },
          },
          {
            name: 'Team',
            link: '/account/team',
            available: state => {
              return state.permissions?.menu?.team === true
            },
          },
          {
            name: 'Subscription',
            link: '/account/billing/subscription',
            available: state => {
              return state.permissions?.menu?.billing === true
            },
          },
          {
            name: 'Integration',
            link: '/account/integration',
            available: state => {
              return state.permissions?.menu?.integration === true
            },
          },
        ],
      },
      {
        name: 'Create',
        icon: AddRoundedIcon,
        link: '/create-content',
        resetSequenceData: true,
        available: state => {
          return state.permissions?.experience?.createExperience === true
        },
      },
      {
        name: 'Manage',
        icon: CenterFocusWeakRoundedIcon,
        link: '/content',
        available: state => {
          return (
            state.permissions?.menu?.experiences === true ||
            state.permissions?.menu?.brands === true
          )
        },
        items: [
          {
            name: 'Brands',
            link: '/content/brands',
            available: state => {
              return state.permissions?.menu?.brands === true
            },
          },
          {
            name: 'Library',
            link: '/content/library',
            available: state => {
              return state.permissions?.menu?.liveStreams === true
            },
          },
          {
            name: 'Experiences',
            link: '/content/experiences',
            available: state => {
              return state.permissions?.menu?.experiences === true
            },
          },
        ],
      },
      {
        name: 'Creators',
        icon: AutoAwesomeRoundedIcon,
        link: '/creators',
        available: state => {
          return state.permissions?.menu?.creatorAnalytics === true
        },
        items: [
          {
            name: 'Overview',
            link: '/creators/overview',
            available: state => {
              return state.permissions?.menu?.creatorAnalytics === true
            },
          },
          {
            name: 'Creators',
            link: '/creators/list',
            available: state => {
              return state.permissions?.menu?.creatorAnalytics === true
            },
          },
          {
            name: 'Experiences',
            link: '/creators/experiences',
            available: state => {
              return state.permissions?.menu?.creatorAnalytics === true
            },
          },
        ],
      },
    ],
  },
]

export default menuItems
