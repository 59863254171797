// code taken from SO
export const scrollToElm = (container, elm, duration) => {
  var pos = getRelativePos(elm)
  scrollTo(container, pos.top, 1) // duration in seconds
}

const getRelativePos = elm => {
  var pPos = elm.parentNode.getBoundingClientRect()
  var cPos = elm.getBoundingClientRect()
  var pos = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }

  pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop
  pos.right = cPos.right - pPos.right
  pos.bottom = cPos.bottom - pPos.bottom
  pos.left = cPos.left - pPos.left

  return pos
}

const scrollTo = (element, to, duration, onDone) => {
  var start = element.scrollTop
  var change = to - start + 25
  var startTime = performance.now()
  var now, elapsed, t

  function animateScroll() {
    now = performance.now()
    elapsed = (now - startTime) / 1000
    t = elapsed / duration

    element.scrollTop = start + change * easeInOutQuad(t)

    if (t < 1) window.requestAnimationFrame(animateScroll)
    else onDone && onDone()
  }

  animateScroll()
}

function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
}
