import {
  CardProps,
  CardTreeType,
  CardType,
  HeadingProps,
  HeadingType,
  Image,
  ITreeItem,
  SequenceSlice,
  Title,
  Video,
} from 'src/redux/slices/sequenceSlice'
import { v4 as uuidv4 } from 'uuid'
import { getParent } from './sequenceSliceUtils'
import {
  AISettings,
  OverlaySettings,
  Settings,
  Template,
  WidgetSettings,
} from '../api/types'

export interface DisplaySettings {
  enabled?: boolean
  maximized: boolean
  rememberClose: boolean
  resetExperienceInNewWindow: boolean
}

export interface UTMParams {
  enabled: boolean
  source: string
  medium: string
  campaign: string
  content: string
  term: string
}

export type HEADING_TYPE =
  | 'NONE'
  | 'TEXT'
  | 'IMAGE'
  | 'VIDEO'
  | 'INHERIT_PARENT'

// Interface of BE route
export interface SequencePoll {
  campaignName: string
  brandId: string
  status: string
  roots: BeTreeItem[]
  settings: Settings
}

interface BeTreeItem {
  id: string
  type: string
  label?: string
  labelStyle?: string
  image?: {
    id: string
    location: string
  }
  headingTitle?: string
  headingImage?: {
    id: string
    location: string
  }
  headingVideo?: {
    id: string
    location: string
  }
  headingType?: HEADING_TYPE
  liveStreamId: string | null
  headingTitleStyle: string
  directLinkUrl?: string
  sessionTags?: string[]
  items: BeTreeItem[]
}

const formatTreeItem = (item: ITreeItem): BeTreeItem => {
  let itemStyle = item?.cardType?.styles?.['color']
    ? JSON.stringify(item?.cardType?.styles)
    : JSON.stringify({ ...item?.cardType?.styles, color: '#ffffff' })
  let headingTitleStyles: Title
  if (item?.heading?.type === HeadingType.title)
    headingTitleStyles = item.heading as Title
  return {
    id: item.cardId,
    type: item?.cardType?.cardType,
    label: item.cardType.directLinkLabel ?? '',
    labelStyle: itemStyle,
    image: {
      id: item?.media?.id,
      location: item?.media?.location,
    },
    headingTitle: item.heading?.titleText ?? '',
    headingImage: {
      id:
        item?.heading?.type === HeadingType.image
          ? (item.heading as Image).imageId
          : '',
      location:
        item?.heading?.type === HeadingType.image
          ? '' /*(item.heading as Image).imageBlob - todo */
          : '',
    },
    liveStreamId: item.liveStreamId,
    headingTitleStyle: headingTitleStyles?.styles
      ? JSON.stringify(headingTitleStyles.styles)
      : '',
    headingVideo: {
      id:
        item?.heading?.type === HeadingType.video
          ? (item.heading as Video).videoId
          : '',
      location: item?.heading?.type === HeadingType.video ? '' : '',
    },
    headingType: item?.headingType,
    sessionTags: item.tags || [],
    directLinkUrl: item.cardType.directLinkUrl ?? '',
    items: Array.isArray(item.children)
      ? item.children.map(elem => formatTreeItem(elem))
      : [],
  }
}

export const prepareSequencePollForBackend = (
  items: ITreeItem[],
  campaignName: string,
  brandId: string,
  status: string,
  template: Template,
  widgetSettings: WidgetSettings,
  overlay: OverlaySettings,
  ai: AISettings,
) => {
  const root = items[0]
  let headingTitleStyles: Title
  if (root.heading.type === HeadingType.title)
    headingTitleStyles = root.heading as Title
  return {
    campaignName,
    brandId,
    status,
    roots: [
      {
        id: root.cardId ?? uuidv4(),
        image: {
          id: root?.media?.id,
          location: root?.media?.location,
        },
        type: 'ROOT',
        headingTitle: root?.heading?.titleText ?? '',
        headingImage: {
          id:
            root?.heading?.type === HeadingType.image
              ? (root?.heading as Image).imageId
              : '',
          location: root?.heading?.type === HeadingType.image ? '' : '',
        },
        liveStreamId: root?.liveStreamId,
        headingTitleStyle: headingTitleStyles?.styles
          ? JSON.stringify(headingTitleStyles.styles)
          : '',
        headingVideo: {
          id:
            root?.heading?.type === HeadingType.video
              ? (root?.heading as Video).videoId
              : '',
          location: root?.heading?.type === HeadingType.video ? '' : '',
        },
        headingType: root?.headingType,
        items: Array.isArray(root.children)
          ? root.children.map(elem => formatTreeItem(elem))
          : [],
      },
    ],
    settings: {
      widget: widgetSettings,
      overlay: overlay,
      template: {
        isGlobalTemplate: template.isGlobalTemplate,
        isOrganizationTemplate: template.isOrganizationTemplate,
        isCreatorTemplate: template.isCreatorTemplate,
      },
      ai: ai,
    },
  } as SequencePoll
}

const getCardType = (item: BeTreeItem): CardProps => {
  let itemLabelStyle: undefined | object
  try {
    itemLabelStyle = JSON.parse(item?.labelStyle || '{}')
  } catch (err) {
    itemLabelStyle = undefined
  }
  let cardType: CardProps
  if (item.type === 'DIRECT_LINK') {
    cardType = {
      cardType: CardType.directLink,
      directLinkLabel: item?.label,
      directLinkUrl: item.directLinkUrl,
      styles: itemLabelStyle === undefined ? {} : itemLabelStyle,
    }
  } else if (item.type === 'INTERMEDIATE') {
    cardType = {
      cardType: CardType.intermediate,
      directLinkLabel: item?.label,
      styles: itemLabelStyle === undefined ? {} : itemLabelStyle,
    }
  } else if (item.type === 'ROOT') {
    cardType = {
      cardType: CardType.root,
    }
  }
  return cardType
}

const getHeadingType = (item: BeTreeItem): HeadingProps => {
  let headingType: HeadingProps
  if (item?.headingType === 'INHERIT_PARENT') {
    headingType = {
      type: HeadingType.asPrevious,
    }
  } else if (item?.headingImage?.location) {
    headingType = {
      type: HeadingType.image,
      imageId: item.headingImage.id,
      imageLocation: item.headingImage.location,
    }
  } else if (item?.headingVideo?.location) {
    headingType = {
      type: HeadingType.video,
      videoId: item?.headingVideo?.id,
      videoLocation: item?.headingVideo?.location,
    }
  } else if (item?.headingTitle) {
    headingType = {
      type: HeadingType.title,
      titleText: item.headingTitle,
      styles: JSON.parse(item.headingTitleStyle || '{}'),
    }
  } else {
    headingType = {
      type: HeadingType.noHeading,
    }
  }
  return headingType
}

const getCardTreeType = (item: BeTreeItem): CardTreeType => {
  let cardTreeType: CardTreeType
  if (item.items.length === 0) {
    cardTreeType = CardTreeType.child
  } else {
    cardTreeType = CardTreeType.parent
  }
  return cardTreeType
}

export const formatBeItem = (item: BeTreeItem): ITreeItem => {
  let cardType = getCardType(item)
  let heading = getHeadingType(item)
  let cardTreeType = getCardTreeType(item)

  return {
    cardId: item.id ?? uuidv4(),
    name: '',
    title: item.headingTitle,
    cardTreeType,
    cardType,
    children: item.items.map(elem => formatBeItem(elem)),
    heading: heading,
    headingType: item.headingType,
    liveStreamId: item?.liveStreamId,
    media: {
      id: item?.image?.id,
      location: item?.image?.location,
    },
    tags: item?.sessionTags ? item?.sessionTags?.filter(el => el !== '') : [],
    expanded: true,
  }
}

const configureAsPreviousHeaders = (
  items: ITreeItem[],
  children: ITreeItem[],
) => {
  children.forEach(item => {
    if (item.headingType === 'INHERIT_PARENT') {
      const parent = getParent(items, item.cardId, null)
      item.heading = parent.heading
    }
    if (Array.isArray(item?.children))
      return configureAsPreviousHeaders(items, item?.children)
  })
}

export const prepareSequencePollForRedux = (
  response: SequencePoll,
  sequencePollId: string,
): SequenceSlice => {
  // when duplicating a template, BE explicitly returns null for all IDs to avoid attempt to edit a template by other organizations.
  // In such case we need to set id to new random value, which will then be ignored by BE but necessary for FE to function properly.
  // Do same for children below.
  let rootId = response?.roots?.[0].id ?? uuidv4()

  let sequencePoll: SequenceSlice = {
    sequenceId: sequencePollId,
    brandId: response?.brandId,
    status: response?.status,
    contentName: response.campaignName,
    currentProps: {
      currentCard: {
        cardId: rootId,
      },
    },
    cardType: {},
    headings: {},
    sequenceBox: {
      items: [
        {
          cardId: rootId,
          cardTreeType: CardTreeType.parent,
          cardType: {
            cardType: CardType.root,
          },
          heading: getHeadingType(response.roots?.[0]),
          headingType: response?.roots?.[0].headingType,
          liveStreamId: response?.roots?.[0].liveStreamId,
          expanded: true,
          media: {
            id: response?.roots?.[0]?.image?.id,
            location: response?.roots?.[0]?.image?.location,
          },
          children: response.roots?.[0].items.map(elem => formatBeItem(elem)),
        },
      ],
    },
    widgetSettings: response?.settings.widget,
    overlay: response.settings.overlay,
    template: response.settings.template,
    ai: response.settings.ai,
  }

  configureAsPreviousHeaders(
    sequencePoll.sequenceBox.items,
    sequencePoll.sequenceBox.items,
  )
  return sequencePoll
}
