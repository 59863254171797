import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { organizationClaimShopifyShop } from '../../api/services'
import { getBillingSubscriptionThunk } from '../../redux/slices/billingSlice'
import { useDispatch } from 'react-redux'

function ShopifyClaim() {
  let location = useLocation()
  let router = useHistory()
  let dispatch = useDispatch()

  let [owner, setOwner] = useState(null)
  let [organization, setOrganization] = useState(null)

  let shop = location.pathname.split('/').reverse()[0]

  useEffect(() => {
    organizationClaimShopifyShop(shop).then(response => {
      if (response.data.reload === 'true') {
        window.location.reload()
      } else if (response.data.owner) {
        setOwner(response.data.owner)
        setOrganization(response.data.organization)
      } else if (response.data.deepLink) {
        window.location.replace(response.data.deepLink)
      } else {
        dispatch(getBillingSubscriptionThunk())
        router.push('/account/organization')
      }
    })
  }, [])

  return (
    <>
      {!owner && <>Integrating with Shopify, please wait...</>}
      {owner && (
        <>
          <h2>
            It appears that the shop '{shop}' that you are trying to integrate
            with is already claimed by another organization named '
            {organization}' with owner email '{owner}'.
          </h2>
          If you think this is a mistake, please contact us at
          info@contester.net.
        </>
      )}
    </>
  )
}

export default ShopifyClaim
