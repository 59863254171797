import {
  Box,
  experimentalStyled,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { useEffect, useState } from 'react'
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone'
import { useDispatch } from 'react-redux'
import { getSequencePollsDataThunk } from 'src/redux/slices/sequencePollSlice'
import { TabValue } from '../SequencePollList'
const TextfieldWrapper = experimentalStyled(Box)(
  ({ theme }) => `
      width: 100%;
      min-width: 159px;
      .MuiOutlinedInput-root {
        background: ${theme.colors.alpha.white[100]};
        display: flex;
      }
  `,
)

interface Props {
  filterByBrand: string
  status: string
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const SequencePollSearch: React.FC<Props> = ({
  filterByBrand,
  status,
  setIsLoading,
}) => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [isInitial, setInitial] = useState(true)

  useEffect(() => {
    if (isInitial) {
      setInitial(false)
      return
    }
    const delayDebounceFn = setTimeout(async () => {
      setIsLoading(true)
      await dispatch(
        getSequencePollsDataThunk({
          brandId: filterByBrand,
          tab: status as TabValue,
          search: search,
        }),
      )
      setIsLoading(false)
    }, 500)

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [search])
  return (
    <TextfieldWrapper>
      <TextField
        size="small"
        sx={{ m: 0 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchTwoToneIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        onChange={e => setSearch(e.target.value)}
        placeholder="Search..."
      />
    </TextfieldWrapper>
  )
}

export default SequencePollSearch
