import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getStripeSetupData,
  setActiveBillingCard,
  getBillingCardsList,
  getBillingPricingList,
  removePaymentCard,
  getBillingDetails,
  updateBillingDetails,
} from '../../services/api/billing'
import {
  BillingPricingResponse,
  BillingSubscriptionDetails,
} from '../../api/types'
import {
  billingGetPricing,
  billingGetSubscriptionDetails,
} from '../../api/services'

interface BillingDataState {
  userId: string
  stripePublishableKey: string
  stripeCustomerId: string
  stripeCardSetupIntentId: string
}
interface CardDataState {
  id: string
  brand: string
  last4Digits: string
  expiresMonth: number
  expiresYear: number
}

interface PriceItemState {
  amount: number
  currency: string
}

interface PricingPeriodState {
  title: string
  price: PriceItemState
  discount: PriceItemState
  months: number
  vat: PriceItemState
  total: PriceItemState
  totalWithVat: PriceItemState
}

interface PricingListState {
  monthly: PricingPeriodState
  quarterly: PricingPeriodState
  yearly: PricingPeriodState
}

interface BillingDetailsState {
  address: {
    city: string
    country: string
    line1: string
    line2: string
    postalCode: string
    state: string
  }
  email: string
  name: string
}

interface IBillingState {
  isLoading: boolean
  stripeSetupData: BillingDataState
  cards: CardDataState[]
  active: CardDataState
  pricingList: PricingListState
  billingDetails: BillingDetailsState
  subscription: BillingSubscriptionDetails
  pricing: BillingPricingResponse
}

export const removePaymentCardThunk = createAsyncThunk(
  'billing/removePaymentCardThunk',
  async (cardId: string) => {
    let removeRequest = await removePaymentCard(cardId)
    if (removeRequest.status === 'ok') {
      return cardId
    }
  },
)

export const setActiveBillingCardThunk = createAsyncThunk(
  'billing/setActiveBillingCardThunk',
  async (cardId: string) => {
    let setActiveCardRequest = await setActiveBillingCard(cardId)
    if (setActiveCardRequest.status === 'ok') {
      return cardId
    }
  },
)

export const getStripeSetupThunk = createAsyncThunk(
  'billing/getStripeSetupThunk',
  async () => {
    let stripeSetupData = await getStripeSetupData()
    return {
      stripeSetupData,
    }
  },
)
export const getBillingSubscriptionThunk = createAsyncThunk(
  'billing/getBillingSubscriptionThunk',
  async () => {
    let subscription = await billingGetSubscriptionDetails()
    return {
      subscription: subscription.data,
    }
  },
)
export const getBillingPricingThunk = createAsyncThunk(
  'billing/getBillingPricingThunk',
  async () => {
    let pricing = await billingGetPricing()
    return {
      pricing: pricing.data,
    }
  },
)
export const getBillingCardsListThunk = createAsyncThunk(
  'billing/getBillingCardsListThunk',
  async () => {
    let { cards, active } = await getBillingCardsList()
    return {
      cards,
      active,
    }
  },
)

export const getBillingDetailsThunk = createAsyncThunk(
  'billing/getBillingDetailsThunk',
  async () => {
    let billingDetails = await getBillingDetails()
    return {
      billingDetails,
    }
  },
)
export const updateBillingDetailsThunk = createAsyncThunk(
  'billing/updateBillingDetailsThunk',
  async (data: any) => {
    return await updateBillingDetails(data)
  },
)

export const getBillingPricingListThunk = createAsyncThunk(
  'billing/getBillingPricingListThunk',
  async () => {
    let pricingList = await getBillingPricingList()
    return {
      pricingList,
    }
  },
)

let initialState: IBillingState = {
  isLoading: false,
  stripeSetupData: {
    userId: '',
    stripePublishableKey: null,
    stripeCustomerId: '',
    stripeCardSetupIntentId: '',
  },
  cards: [],
  active: null,
  pricingList: null,
  billingDetails: null,
  subscription: null,
  pricing: null,
}

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(removePaymentCardThunk.fulfilled, (state, action) => {
      state.cards = state.cards.filter(item => item.id !== action.payload)
    })
    builder.addCase(setActiveBillingCardThunk.fulfilled, (state, action) => {
      state.active = state.cards.find(item => item.id === action.payload)
    })
    builder.addCase(getBillingCardsListThunk.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getBillingSubscriptionThunk.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
      state.isLoading = false
    })
    builder.addCase(getBillingPricingThunk.fulfilled, (state, action) => {
      state.pricing = action.payload.pricing
      state.isLoading = false
    })
    builder.addCase(getBillingCardsListThunk.fulfilled, (state, action) => {
      state.cards = action.payload.cards
      state.active = action.payload.active
      state.isLoading = false
    })
    builder.addCase(getBillingDetailsThunk.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getBillingDetailsThunk.fulfilled, (state, action) => {
      state.billingDetails = action.payload.billingDetails
      state.isLoading = false
    })
    builder.addCase(getBillingPricingListThunk.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getBillingPricingListThunk.fulfilled, (state, action) => {
      state.pricingList = action.payload.pricingList
      state.isLoading = false
    })
    builder.addCase(getStripeSetupThunk.fulfilled, (state, action) => {
      state.stripeSetupData = action.payload.stripeSetupData
    })
  },
})

export default billingSlice.reducer
