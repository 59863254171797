import * as React from 'react'

import styles from './Button.module.scss'

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => (
  <button className={`${styles.button} ${props.className}`} {...props}>
    {children}
  </button>
)

export default Button
