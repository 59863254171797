import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import {
  liveStreamsDisconnect,
  liveStreamsGetCredentials,
} from '../../api/services'
import WHIPClient from './WHIPClient'

interface Props {
  liveStreamId: string
  isActiveLiveStreamVisible: boolean
  goLiveCallback: () => void
  disconnectCallback: () => void
}

const LiveStreams: React.FC<Props> = ({
  liveStreamId,
  isActiveLiveStreamVisible,
  goLiveCallback,
  disconnectCallback,
}) => {
  let [whipClient, setWhipClient] = useState<any>(null)

  const mountedRef = useRef(true)

  useEffect(() => {
    if (isActiveLiveStreamVisible && !whipClient) {
      goLive()
    }
    if (whipClient && !isActiveLiveStreamVisible) {
      disconnect()
    }
    return () => {
      mountedRef.current = false
    }
  }, [isActiveLiveStreamVisible, whipClient])

  let goLive = async function () {
    let credentials = await liveStreamsGetCredentials(liveStreamId)
    if (whipClient != null) {
      await whipClient.disconnectStream()
      setWhipClient(null)
    }
    let videoElement = document.getElementById('live-stream-local-mirror-id')
    setWhipClient(new WHIPClient(credentials.data.webRTCUrl, videoElement))
    goLiveCallback()
  }

  let disconnect = async function () {
    if (mountedRef !== null) {
      if (whipClient != null) {
        await liveStreamsDisconnect(liveStreamId)
        await whipClient.disconnectStream()

        setWhipClient(null)
        disconnectCallback()
      }
    }
  }

  let beforeUnload = async function (e) {
    if (whipClient != null) {
      e.preventDefault()
      e.returnValue = 'Please disconnect stream first!'
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [beforeUnload])

  return (
    <>
      <Container maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} sx={{ height: '100%', pt: 0 }}>
            <div
              style={{
                height: '80px',
                backgroundColor: '#000',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}
            ></div>
            <video
              style={{
                backgroundColor: 'grey',
                width: '100%',
                height: '100%',
                display: 'flex',
              }}
              id={'live-stream-local-mirror-id'}
              autoPlay
              muted
            ></video>
            <div
              style={{
                height: '80px',
                backgroundColor: '#000',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
            ></div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default LiveStreams
