import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { billingConfirmSubscription } from '../../api/services'
import { useDispatch } from 'react-redux'
import { getBillingSubscriptionThunk } from '../../redux/slices/billingSlice'

function ConfirmSubscription() {
  let router = useHistory()
  let dispatch = useDispatch()

  useEffect(() => {
    billingConfirmSubscription().then(() => {
      dispatch(getBillingSubscriptionThunk())
      router.push('/account/billing/subscription')
    })
  }, [])

  return <></>
}

export default ConfirmSubscription
