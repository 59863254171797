import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { experimentalStyled } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app

import { setSequenceId, setTreeItems } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import { saveSequencePoll } from 'src/services/api/sequencePolls'
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import '../../styles/global.scss'
import Sidebar from '../Products/Products'
import SequenceBox from '../SequenceBox/SequenceBox'
import SequenceDemo from '../SequenceDemo/SequenceDemo'
import SequencePreview from '../SequencePreview/SequencePreview'
import SequencePreviewButtons from '../SequencePreviewButtons/SequencePreviewButtons'

import StepCreate from 'src/components/Home/Steps/StepCreate'
import WidgetSettingsComponent from 'src/components/Home/WidgetSettingsComponent'
import SequenceSliceHandler from 'src/utils/SequenceSliceHandler'

import { toast } from 'react-toastify'
import { selectStep, setStep } from 'src/redux/slices/creatorSlice'
import { handlePromiseToast } from 'src/utils/toast.config'
import './Home.css'
import { SequencePollUpdateData, WidgetSettings } from '../../api/types'

const DrawerWrapper = experimentalStyled(Drawer)(
  ({ theme }) => `
    width: 300px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 250px;
        height: calc(100% - ${theme.header.height});
        position: fixed;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        color: ${theme.colors.alpha.white[100]};
        border-left: unset;
    }
`,
)

const ListDrawerWrapper = experimentalStyled(Drawer)(
  ({ theme }) => `
    width: calc(100% - 280px);
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: calc(100% - 550px);
        min-width: 315px;
        height: calc(100% - 120px);
        top: 125px;
        z-index: 1;
        border: 0;
        color: ${theme.colors.alpha.white[100]};
    }

    @media only screen and (min-width: 1280px) {
      & > .MuiPaper-root {
        left: 320px;        
        width: calc(100% - 585px);
      }
    }

    @media only screen and (max-width: 1279px) {
      & > .MuiPaper-root {
        left: 45px;
        width: calc(100% - 315px);
      }
    }    

    @media only screen and (max-width: 959px) {
      & > .MuiPaper-root {
        left: 45px;
        width: calc(100% - 315px);
      }
    }
`,
)

const MainContentWrapper = experimentalStyled(Box)(
  () => `
  flex-grow: 1;
`,
)

const ButtonsWrapper = experimentalStyled(Box)(
  () => `
    display: flex;
    justify-content: center;
  `,
)

const PublishButton = experimentalStyled(Button)(
  () => `
    margin-left: 6px;
    width: 140px;
  `,
)

const SetupButton = experimentalStyled(Button)(
  () => `
    width: 140px;
  `,
)

const MainGridWrapper = experimentalStyled(Grid)(
  () => `
    height: 94%;
  `,
)

const GridWrapper = experimentalStyled(Grid)(
  () => `
    height: 100%;
  `,
)

export interface AlertData {
  title: string
  msg: string
}

const sequencePollHandler = SequenceSliceHandler.Instance

const Home: React.FC = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth0()
  const theme = useTheme()
  const step = useSelector(selectStep)

  const dndType = 'test'

  const [isLoading, setIsLoading] = useState(false)
  const [sequenceData, setSequenceData] = useState<
    SequencePollUpdateData | undefined
  >(undefined)
  const [isAbleToCreate, setIsAbleToCreate] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const [alertData, setAlertData] = useState<AlertData | null>(null)

  const [brandContentWarning, setBrandContentWarning] = useState(false)

  const [showWidgetSettings, setShowWidgetSettings] = useState(false)

  const [initialWidgetSettings, setInitialWidgetSettings] =
    useState<WidgetSettings>()

  const sequencePoll = useSelector((state: RootState) => state.sequenceReducer)

  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )

  const contentName = useSelector(
    (state: RootState) => state.sequenceReducer.contentName,
  )

  const brandId = useSelector(
    (state: RootState) => state.sequenceReducer.brandId,
  )

  const { items } = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceBox,
  )

  const widgetSettings = useSelector(
    (state: RootState) => state.sequenceReducer.widgetSettings,
  )

  const handleChangeStep = (type: 'next' | 'back') => {
    let tempItems = JSON.parse(JSON.stringify(items))
    // changeIds(tempItems);
    dispatch(setTreeItems(tempItems))
    if (type === 'next' && step === 1) {
      if (!brandId || !contentName) {
        setBrandContentWarning(true)
        return
      }
    }
    type === 'next'
      ? step < 3 && dispatch(setStep(step + 1))
      : dispatch(setStep(step - 1))
  }

  const handleSaveChanges = async (status?: boolean) => {
    if (sequencePoll?.sequenceId) {
      let tempStatus = sequencePoll?.widgetSettings?.displaySettings?.enabled
      let settings: WidgetSettings = {
        ...initialWidgetSettings,
        ...sequencePoll.widgetSettings,
        displaySettings: {
          ...sequencePoll.widgetSettings.displaySettings,
          enabled: status ?? initialWidgetSettings.displaySettings.enabled,
        },
        utm: {
          ...sequencePoll.widgetSettings.utm,
        },
      }
      if (status === undefined) {
        settings = {
          ...sequencePoll.widgetSettings,
          displaySettings: {
            ...sequencePoll.widgetSettings.displaySettings,
            enabled: status ?? tempStatus,
          },
          utm: {
            ...sequencePoll.widgetSettings.utm,
          },
        }
      }

      await handlePromiseToast(
        async () => {
          let result = await saveSequencePoll(
            sequencePoll.sequenceBox.items,
            sequencePoll.contentName,
            sequencePoll.brandId,
            sequencePoll.sequenceId,
            sequencePoll.status,
            sequencePoll.template,
            settings,
            sequencePoll.overlay,
            sequencePoll.ai,
          )

          if (!sequencePollId || sequencePollId === '') {
            dispatch(setSequenceId(result.id))
          }
        },
        'Widget settings saved successfully',
        'success',
        'Failed to save widget settings!',
        'error',
      )
      return
    }
    toast.success(
      'Widget settings saved successfully, but will be applied after you publish the experience.',
    )
    // do not hide widget settings, as user may want to see newly generated UTM link
    // setShowWidgetSettings(false);
  }

  const checkPlan = async () => {
    setIsLoading(true)
    // let status = await checkPaymentPlan();
    // setIsAbleToCreate(status?.canCreateSequencePoll);
    setIsAbleToCreate(true)
    setOpenDialog(true)
    setIsLoading(false)
  }

  const publishHandler = async () => {
    setIsLoading(true)
    try {
      let response = await saveSequencePoll(
        items,
        contentName,
        brandId,
        sequencePollId,
        'Active',
        sequencePoll.template,
        widgetSettings,
        sequencePoll.overlay,
        sequencePoll.ai,
      )
      handleChangeStep('next')
      setSequenceData(response)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkPlan()
  }, [])

  useEffect(() => {
    setInitialWidgetSettings(sequencePoll.widgetSettings)
  }, [sequencePoll?.sequenceId])

  /**
   * Activate autosave on step 2, stop on step 3
   */
  useEffect(() => {
    if (step === 2) {
      sequencePollHandler.startInterval()
    }
    if (step == 3) {
      sequencePollHandler.stopInterval()
    }
    return () => {
      sequencePollHandler.stopInterval()
    }
  }, [step])

  useEffect(() => {
    return () => {
      dispatch(setStep(1))
    }
  }, [])

  if (!isAuthenticated) return <Redirect to="/login" />

  if (isLoading)
    return <CircularProgress className="circular-progress-center" />

  // if (user?.email_verified === false) return <EmailVerification />;

  return (
    <>
      {brandContentWarning && (
        <Dialog
          open={brandContentWarning}
          onClose={() => setBrandContentWarning(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="dialog-title">
            <IconButton onClick={() => setBrandContentWarning(false)}>
              <span className="material-icons-outlined">X</span>
            </IconButton>
          </DialogTitle>
          {/* <DialogTitle id="alert-dialog-title">{title}</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                <b>Fields are required</b>
              </Typography>
              <Typography>
                Name of your content and brand fields are required!
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {!isAbleToCreate && (
        <Dialog
          open={openDialog}
          // onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You reached your plan limit. In order to create new experiences:
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                Update your plan{' '}
                <Link to="/account/organization">Here &gt;</Link>{' '}
              </Typography>{' '}
              <Typography>
                Archive active experiences{' '}
                <Link to="/experiences">Here &gt;</Link>{' '}
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {step === 1 && (
        <>
          <StepCreate
            alertData={alertData}
            isAbleToCreate={
              isAbleToCreate &&
              contentName.length > 0 &&
              brandId &&
              brandId !== 'default'
            }
            showWidgetSettings={showWidgetSettings}
            setAlertData={setAlertData}
            setShowWidgetSettings={setShowWidgetSettings}
            handleSaveChanges={handleSaveChanges}
            handleChangeStep={handleChangeStep}
          />
          {showWidgetSettings && (
            <WidgetSettingsComponent
              setShowWidgetSettings={setShowWidgetSettings}
              alertData={alertData}
              setAlertData={setAlertData}
              handleSaveChanges={handleSaveChanges}
              initialWidgetSettings={initialWidgetSettings}
              setInitialWidgetSettings={setInitialWidgetSettings}
            />
          )}
        </>
      )}
      {step === 3 && (
        <ContentWrapper title="Experience List">
          <Container maxWidth="md">
            <Grid
              mt={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={10}
                  style={{ gridTemplateColumns: 'auto' }}
                >
                  <Grid mt={1} item xs={12} sm={12} md={6} lg={6}>
                    <SequencePreview />
                  </Grid>
                  <Grid
                    style={{ minHeight: '50vh' }}
                    mt={1}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                  >
                    <SequencePreviewButtons
                      sequenceDataUrl={sequenceData?.url}
                      sequenceId={sequenceData?.id}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </ContentWrapper>
      )}
      {step === 2 && (
        <ContentWrapper title="Experience List">
          <Box>
            <MainContentWrapper>
              <ListDrawerWrapper variant="permanent" anchor="left" open>
                <Scrollbars>
                  <MainGridWrapper
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={3}
                  >
                    <GridWrapper item xs={12}>
                      <GridWrapper
                        pr={3}
                        container
                        spacing={3}
                        style={{ gridTemplateColumns: 'auto' }}
                      >
                        <GridWrapper mt={1} item xs={12} sm={12} md={7} lg={7}>
                          <SequenceBox
                            dndType={dndType}
                            selectable={step === 2}
                          />
                        </GridWrapper>
                        <GridWrapper mt={1} item xs={12} sm={12} md={5} lg={5}>
                          <SequenceDemo />
                        </GridWrapper>
                        <Grid mt={0.5} item xs={12} sm={12} md={12} lg={12}>
                          <ButtonsWrapper>
                            <SetupButton
                              variant="outlined"
                              color="primary"
                              onClick={() => handleChangeStep('back')}
                            >
                              {'< Setup'}
                            </SetupButton>
                            <PublishButton
                              variant="outlined"
                              color="primary"
                              disabled={items[0].children.length < 1}
                              onClick={publishHandler}
                              title="add items and click to next"
                            >
                              {/* {step === 3 ? 'Publish' : step === 1 ? 'Next'} */}
                              {'Publish >'}
                            </PublishButton>
                          </ButtonsWrapper>
                        </Grid>
                      </GridWrapper>
                    </GridWrapper>
                  </MainGridWrapper>
                </Scrollbars>
              </ListDrawerWrapper>
            </MainContentWrapper>
          </Box>
        </ContentWrapper>
      )}
      {step === 2 && (
        <DrawerWrapper
          variant="permanent"
          anchor={theme.direction === 'rtl' ? 'left' : 'right'}
          open
        >
          <Scrollbars>
            <Sidebar step={step} />
          </Scrollbars>
        </DrawerWrapper>
      )}
    </>
  )
}

export default Home
