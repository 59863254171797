import { Box, Button, CardActionArea, Typography } from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import {
  LiveStreamListData,
  LiveStreamStreamingCredentials,
} from '../../../api/types'
import { liveStreamsGetCredentials } from '../../../api/services'

const LinkWrapper = experimentalStyled(Box)(
  () => `
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    `,
)

const ButtonsWrapper = experimentalStyled(Box)(
  () => `
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    `,
)

const TypographyPrimary = experimentalStyled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
)

const ButtonWrapper = experimentalStyled(Button)(
  () => `
      margin-top: 10px;
    `,
)
const ColorButton = experimentalStyled(ButtonWrapper)(
  ({ theme }) => `
  color: #fff;
  background: rgb(255,0,0);
  background: linear-gradient(73deg, rgba(255,0,0,1) 0%, rgba(255,0,136,1) 100%);
  '&:hover': {
    backgroundColor: purple[700],
  },
  `,
)

const ImageContentWrapper = experimentalStyled(Box)(
  () => `
    position: relative;
    width: 100%;
    margin-bottom: 2px;
    padding: 1px;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      animation: fadeIn 0.5s ease 1;
    }
`,
)

const CardActionAreaWrapper = experimentalStyled(CardActionArea)(
  ({ theme }) => `
      height: 225px;
      min-height: 225px;
      max-height: 225px;
      padding: 0px;
      margin-top: 0px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;

      .MuiSvgIcon-root {
        opacity: .5;
      }

      .MuiTouchRipple-root {
        opacity: .3;
      }

      img {
        height: auto;
        width: 100%;
      }

      .MuiCardActionArea-focusHighlight {
        background: blue;
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: 0.04;
        }
      }
`,
)

interface Props {
  stream: LiveStreamListData
  openEditStreamDialog: () => void
  removeLiveStream: () => void
  setActiveSection: (any) => void
  setActiveLiveStreamVisible: (isVisible: boolean) => void
  isActiveLiveStreamVisible: boolean
}

const StreamDataManager: React.FC<Props> = ({
  stream,
  openEditStreamDialog,
  setActiveSection,
  removeLiveStream,
  setActiveLiveStreamVisible,
  isActiveLiveStreamVisible,
}) => {
  let [credentials, setCredentials] =
    useState<LiveStreamStreamingCredentials>(null)

  useEffect(() => {
    if (stream) {
      liveStreamsGetCredentials(stream.id).then(creds => {
        setCredentials(creds.data)
      })
    }
  }, [stream])

  const disconnectLiveStream = () => {
    setActiveLiveStreamVisible(false)
  }
  const openLiveStream = () => {
    setActiveLiveStreamVisible(true)
    setActiveSection('LiveStream')
  }

  return (
    <>
      <Box>
        {stream && credentials && (
          <Box sx={{ p: { xs: 2, lg: 4 } }}>
            <Box sx={{ p: { xs: 2, lg: 4 } }}>
              <CardActionAreaWrapper>
                <ImageContentWrapper>
                  <img
                    style={{ objectFit: 'cover', width: '100%' }}
                    src={stream?.poster?.location}
                  />
                </ImageContentWrapper>
              </CardActionAreaWrapper>
            </Box>
            <Box display="flex">
              <Box pt={2} width="100%">
                <TypographyPrimary variant="subtitle2">
                  <span>LIVE TITLE:</span> {stream?.title}
                </TypographyPrimary>
                <TypographyPrimary variant="subtitle2">
                  <span>SCHEDULED: </span>
                  <Moment format="DD MMM YYYY[,] HH:mm">
                    {Number(stream?.scheduledAt)}
                  </Moment>
                </TypographyPrimary>
                <TypographyPrimary variant="subtitle2">
                  <span>VISIBILITY:</span> {stream?.visibility} livestream
                </TypographyPrimary>
              </Box>
            </Box>
            <LinkWrapper></LinkWrapper>
            <ButtonsWrapper>
              <ButtonWrapper
                variant="outlined"
                color="primary"
                onClick={openEditStreamDialog}
              >
                Edit
              </ButtonWrapper>
              <ButtonWrapper
                variant="outlined"
                color="primary"
                onClick={() => removeLiveStream()}
              >
                Delete
              </ButtonWrapper>
              {stream.canGoLiveInBrowser && (
                <>
                  {isActiveLiveStreamVisible ? (
                    <ColorButton onClick={disconnectLiveStream}>
                      Stop streaming
                    </ColorButton>
                  ) : (
                    <ColorButton onClick={openLiveStream}>Go Live!</ColorButton>
                  )}
                </>
              )}
            </ButtonsWrapper>
            <Box display="flex">
              <Box pt={2} width="100%">
                {/* SRT streaming does not work yet */}

                {/*<TypographyPrimary variant="subtitle2">*/}
                {/*  <span>SRT:</span>*/}
                {/*</TypographyPrimary>*/}
                {/*<TypographyPrimary variant="subtitle2">*/}
                {/*  <span>Url: {credentials.srtUrl}?passphrase={credentials.srtPassphrase}&streamid={credentials.srtStreamId}*/}
                {/*    </span>*/}
                {/*</TypographyPrimary>*/}

                {stream.sourceType === 'Rtmps' && (
                  <>
                    <TypographyPrimary variant="subtitle2">
                      <span>Stream through RTMPS:</span>
                    </TypographyPrimary>
                    <TypographyPrimary variant="subtitle2">
                      <span>Url: {credentials.rtmpsUrl}</span>
                    </TypographyPrimary>
                    <TypographyPrimary variant="subtitle2">
                      <span>StreamKey: {credentials.rtmpsStreamKey}</span>
                    </TypographyPrimary>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default StreamDataManager
