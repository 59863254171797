import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'
import {
  addTagsToCard,
  CardProps,
  CardTreeType,
  CardType,
  HeadingType,
  changeCardHeader,
  changeCardType,
  HeadingProps,
} from 'src/redux/slices/sequenceSlice'
import CloseIcon from '@material-ui/icons/Close'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import styles from '../Sidebar.module.scss'

import {
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  ButtonBase,
  Autocomplete,
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import SequenceImageSingle from 'src/components/SequenceDemo/SequenceImageSingle/SequenceImageSingle'
import { RootState } from 'src/redux/store'
import {
  addLiveStreamToCard,
  removeLiveStreamFromCard,
} from 'src/redux/slices/sequenceSlice'
import { getAllLiveStreamsSelector } from 'src/redux/selectors/liveStreamsSelectors'
import { experimentalStyled } from '@material-ui/core/styles'
import demoStyles from '../../SequenceDemo/SequenceDemo.module.scss'
import CardEditor from './CardEditor/CardEditor'
import CardHeaderContainer from './CardHeaderContainer/CardHeaderContainer'

interface MyInputProps {
  onKeyDown: (event: object) => void
}
interface MyParams extends AutocompleteRenderInputParams {
  inputProps: MyInputProps
}

const StyledStreamButton = experimentalStyled(ButtonBase)(
  () => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 0 5px 0 16px;
  border-radius: 6px;
  position: relative;
  height: 53px;
  color: #223354;
  width: 100%;
  svg {
    color: rgba(34, 51, 84, 0.5);
  }
  &:before {
    content: 'Live stream';
    font-size: 11px;
    position: absolute;
    color: rgba(34, 51, 84, 0.7);
    top: -7px;
    left: 14px;
    background-color: #fff;
    padding: 0 5px;
  }
`,
)

const RemoveButton = experimentalStyled(Box)(
  () => `
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;

`,
)

const StyledListItemText = experimentalStyled(ListItemText)(
  () => `
  .MuiListItemText-primary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #000;
    max-width: 135px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .MuiListItemText-secondary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: rgb(153,158,187);
  }
  .MuiSvgIcon-root {
    width: 24px;
  }
  `,
)

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  mgB: {
    marginBotom: '15px',
  },
  cardOption: {
    width: '100%',
    marginTop: '15px',
  },
  paperWrapper: {
    padding: '10px 8px',
    marginBottom: '25px',
    marginTop: '45px',
  },
  paperPreview: {
    padding: '10px 8px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    boxShadow: '0 3px 6px #00000029',
  },
  title: {
    marginBottom: '20px',
    fontSize: '15px',
  },
}))

const SequenceOptions: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { currentCard } = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps,
  )
  const [urlText, setUrlText] = useState(currentCard?.cardType?.directLinkUrl)
  const [streamsListVisibility, setStreamsListVisibility] = useState(false)
  const [selectedStream, setSelectedStream] = useState(null)
  const { tags } = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps.currentCard,
  )
  const assignLiveStreams = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.liveStream.assignLiveStreams,
  )
  const allLiveStreams = useSelector(getAllLiveStreamsSelector)
  const [filteredLiveStreams, setLiveStreamsList] = useState(allLiveStreams)
  const currentHeadingType = currentCard.heading?.type

  const handleKeyDown = event => {
    switch (event.key) {
      case ',':
      case ' ':
      case 'Enter': {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.value.length > 0) {
          dispatch(addTagsToCard([...(tags || []), event.target.value]))
        }
        break
      }
      default:
    }
  }

  const handleChange = (options: HeadingProps) => {
    dispatch(changeCardHeader({ cardId: currentCard.cardId, options }))
  }

  const handleCardType = (options: CardProps) => {
    dispatch(changeCardType({ cardId: currentCard.cardId, options }))
  }

  const showStreamsList = () => {
    setStreamsListVisibility(true)
  }

  const handleSelectOneStream = (stream): void => {
    if (selectedStream?.id !== stream.id) {
      setSelectedStream(stream)
      dispatch(addLiveStreamToCard(stream))
    }
  }

  const removeSelectedStream = e => {
    e.preventDefault()
    setSelectedStream(null)
    dispatch(removeLiveStreamFromCard())
    setStreamsListVisibility(false)
  }

  useEffect(() => {
    if (currentCard?.liveStreamId) {
      setLiveStreamsList(
        allLiveStreams.filter(
          stream => stream.id === currentCard?.liveStreamId,
        ),
      )
      setStreamsListVisibility(true)
    } else {
      setLiveStreamsList(allLiveStreams)
      setStreamsListVisibility(false)
    }
  }, [currentCard, allLiveStreams])

  useEffect(() => {
    setUrlText(currentCard?.cardType?.directLinkUrl || '')
  }, [currentCard])

  const isNoStreamButtonVisibile =
    !streamsListVisibility &&
    assignLiveStreams &&
    currentHeadingType === HeadingType.video &&
    !currentCard?.liveStreamId &&
    !isEmpty(filteredLiveStreams)

  const isStreamsListVisible =
    assignLiveStreams && streamsListVisibility && !isEmpty(filteredLiveStreams)

  return (
    <div className={styles.sequenceOptions}>
      <CardHeaderContainer
        handleChange={handleChange}
        currentCard={currentCard}
      />
      {isNoStreamButtonVisibile && (
        <Paper
          variant="outlined"
          sx={{ py: 2 }}
          className={`${classes.paperPreview} ${styles.sidebarPreview}`}
        >
          <StyledStreamButton onClick={showStreamsList}>
            No Live Stream <ArrowDropDown />
          </StyledStreamButton>
        </Paper>
      )}
      {currentCard?.cardTreeType === CardTreeType.parent && (
        <Paper
          variant="outlined"
          className={`${classes.paperPreview} ${styles.sidebarPreview}`}
        >
          <div className={demoStyles.sequenceDemoPreview}>
            <SequenceImageSingle image={currentCard.media?.location} />
          </div>
        </Paper>
      )}

      {isStreamsListVisible && (
        <Paper
          variant="outlined"
          sx={{ p: 0 }}
          className={`${classes.paperPreview} ${styles.sidebarPreview}`}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            spacing={3}
            sx={{
              p: 2,
              position: 'relative',
              height: 'auto',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {filteredLiveStreams.map(stream => {
              const { id, scheduledAt, title, poster } = stream
              return (
                <Grid key={id} item xs={12} sm={12}>
                  {currentCard?.liveStreamId && (
                    <Box
                      sx={{
                        color: 'rgb(182, 186, 207)',
                        cursor: 'pointer',
                      }}
                      flexDirection="row"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography>Live stream</Typography>
                      <RemoveButton
                        component="span"
                        onClick={e => removeSelectedStream(e)}
                      >
                        Remove
                        <CloseIcon />
                      </RemoveButton>
                    </Box>
                  )}
                  <Box
                    flexDirection="row"
                    display="flex"
                    onClick={() => {
                      handleSelectOneStream(stream)
                    }}
                  >
                    <ListItem sx={{ pl: 0, cursor: 'pointer' }}>
                      <ListItemAvatar>
                        <Avatar
                          variant="square"
                          sx={{
                            width: 60,
                            height: 60,
                            mr: 2,
                            borderRadius: '6px',
                          }}
                        >
                          <img src={poster?.location} />
                        </Avatar>
                      </ListItemAvatar>
                      <StyledListItemText
                        primary={title}
                        secondary={
                          <Moment format="DD MMM YYYY[,] HH:mm">
                            {scheduledAt}
                          </Moment>
                        }
                      />
                    </ListItem>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Paper>
      )}

      {currentCard.cardType.cardType !== CardType.root && (
        <>
          <CardEditor
            currentCard={currentCard}
            handleCardType={handleCardType}
            handleChange={handleChange}
          />
          <Paper
            variant="outlined"
            className={`${classes.paperWrapper} ${styles.sidebarBox}`}
            style={{
              display:
                currentCard.cardTreeType === CardTreeType.parent
                  ? 'none'
                  : 'block',
            }}
          >
            <div className={styles.cardOption}>
              <FormControl
                fullWidth
                variant="outlined"
                className={styles.formControl}
              >
                <InputLabel>Card type</InputLabel>
                <Select
                  id="card-type"
                  onChange={e =>
                    handleCardType({
                      cardType: e.target.value,
                      directLinkUrl: '',
                      directLinkLabel: '',
                    })
                  }
                  value={currentCard?.cardType?.cardType}
                  label="Card type"
                >
                  <MenuItem value={CardType.directLink}>Direct link</MenuItem>
                  <MenuItem value={CardType.intermediate}>
                    Intermediate
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {currentCard?.cardType?.cardType === CardType.directLink &&
              currentCard?.cardTreeType !== CardTreeType.parent && (
                <div className={classes.cardOption}>
                  <TextField
                    fullWidth
                    placeholder="Enter/paste the URL"
                    value={urlText || ''}
                    onChange={e => {
                      setUrlText(e.target.value)
                    }}
                    onBlur={e => {
                      dispatch(
                        changeCardType({
                          cardId: currentCard?.cardId,
                          options: {
                            cardType: CardType?.directLink,
                            directLinkLabel:
                              currentCard.cardType?.directLinkLabel,
                            directLinkUrl: e.target.value,
                            styles: currentCard.cardType?.['styles'],
                          },
                        }),
                      )
                    }}
                  />
                </div>
              )}
          </Paper>
          <Paper variant="outlined" className={`${styles.sidebarBox}`}>
            <Grid>
              <Box p={1}>
                <Autocomplete
                  multiple
                  freeSolo
                  id="tags-outlined"
                  options={[]}
                  getOptionLabel={option => {
                    if (typeof option === 'string') {
                      return option
                    }
                    return option.toString()
                  }}
                  value={tags ?? []}
                  onChange={(event, newValue) => {
                    if (Array.isArray(newValue))
                      dispatch(addTagsToCard(newValue as string[]))
                    else dispatch(addTagsToCard([newValue]))
                  }}
                  filterSelectedOptions
                  renderInput={(params: MyParams) => {
                    params.inputProps.onKeyDown = handleKeyDown
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        placeholder="ex. Dress"
                        margin="normal"
                        fullWidth
                      />
                    )
                  }}
                />
              </Box>
            </Grid>
          </Paper>
        </>
      )}
    </div>
  )
}

export default SequenceOptions
