import { Button, Checkbox, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AlertData } from 'src/components/Home/Home'
import SequenceBrand from 'src/components/SequenceBrand/SequenceBrand'

import { changeWidgetSetting } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import StepOnePlaceholderImage from '../../../assets/brand-step-one.png'
import MoreIconSvg from '../../../assets/more-icon.svg'
import { getLiveStreamsThunk } from 'src/redux/slices/liveStreamsSlice'

interface Props {
  alertData: AlertData | null
  isAbleToCreate: boolean
  showWidgetSettings: boolean
  setAlertData: React.Dispatch<React.SetStateAction<AlertData | null>>
  setShowWidgetSettings: React.Dispatch<React.SetStateAction<boolean>>
  handleSaveChanges: (status?: boolean) => void
  handleChangeStep: (step: 'next' | 'back') => void
}

const StepCreate = ({
  alertData,
  isAbleToCreate,
  showWidgetSettings,
  setAlertData,
  setShowWidgetSettings,
  handleSaveChanges,
  handleChangeStep,
}: Props) => {
  const dispatch = useDispatch()
  const widgetSettings = useSelector(
    (state: RootState) => state.sequenceReducer.widgetSettings,
  )
  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )
  return (
    <div>
      <div className="home-step-one-form">
        <div className="home-step-one-form-inner">
          <SequenceBrand sequencePollId={sequencePollId} />
          <div className="editor-buttons">
            <div className="home-buttons-wrapper">
              <Button
                disabled={!isAbleToCreate}
                variant="outlined"
                color="primary"
                style={{ minWidth: '250px', marginLeft: '25px' }}
                onClick={() => {
                  dispatch(getLiveStreamsThunk(true))
                  handleChangeStep('next')
                  toast.info('Start creating something amazing!')
                }}
              >
                {sequencePollId ? 'Edit' : 'Create'}
              </Button>
              <div className="home-widget-checkbox-status">
                <Button
                  onClick={() => {
                    setAlertData(null)
                    setShowWidgetSettings(!showWidgetSettings)
                  }}
                >
                  Widget settings{' '}
                  <img
                    width={16}
                    height={16}
                    style={{ marginLeft: '8px' }}
                    src={MoreIconSvg}
                  />
                </Button>
                <Checkbox
                  color="primary"
                  value={widgetSettings?.displaySettings?.enabled}
                  checked={widgetSettings?.displaySettings?.enabled}
                  onChange={() => {
                    handleSaveChanges(!widgetSettings?.displaySettings?.enabled)
                    dispatch(
                      changeWidgetSetting({
                        key: 'enabled',
                        value: !widgetSettings?.displaySettings?.enabled,
                      }),
                    )
                  }}
                />
                <Typography className="widget-settings__enabled-checkbox">
                  Enabled
                </Typography>
              </div>
            </div>
          </div>
          {!!alertData && (
            <div style={{ textAlign: 'center' }}>
              <p>
                <b>{alertData.title}</b>
              </p>
              <p>{alertData.msg}</p>
            </div>
          )}
        </div>
        <div className="step-one-image">
          <img src={StepOnePlaceholderImage} alt="step-one" />
        </div>
      </div>
    </div>
  )
}

export default StepCreate
