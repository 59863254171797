import { routes } from 'src/constants'
import { request } from '../apiServices'

export const putImageMedia = async () => {
  try {
    return await request({
      url: routes.PUT_IMAGE,
      method: 'PUT',
      data: {
        type: 'DIRECT',
      },
    })
  } catch (err) {
    return err
  }
}

export const saveImage = async (
  formData: FormData,
  id: string,
  uploadProgress: any = () => {},
): Promise<{ id: string; location: string }> => {
  let file = formData.get('file')
  let size = file.size
  let fileName = file.name

  let summary = await request({
    url: `${routes.POST_IMAGE}${id}/chunked/${size}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  let total = summary.chunks.length
  let loaded = 0

  let chain = new Promise<void>(resolve => {
    console.log('begin chunk upload')
    resolve()
  })

  summary.chunks.forEach(function (chunk) {
    let data = new FormData()
    data.append('file', new File([file.slice(chunk.from, chunk.to)], fileName))
    chain = chain
      .then(async () => {
        await request({
          url: `${routes.POST_IMAGE}${id}/chunked/${size}/${chunk.number}`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        })
      })
      .then(function () {
        loaded = loaded + 1
        uploadProgress({ total: total, loaded: loaded })
      })
  })
  return await chain.then(function () {
    return request({
      url: `${routes.POST_IMAGE}${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  })
}
