import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/redux/store'
interface State {
  step: number
}

const initialState: State = {
  step: 1,
}

const creatorSlice = createSlice({
  name: 'createSlice',
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload
    },
  },
})

export const selectStep = (state: RootState) => state.creatorReducer.step

export const { setStep } = creatorSlice.actions
export default creatorSlice.reducer
