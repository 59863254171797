import React, { useContext } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { SidebarContext } from '../../../contexts/SidebarContext'
import ContesterLogo from '../../../assets/Logo-text-white.jpg'

import { Box, Divider, Drawer } from '@material-ui/core'

import { experimentalStyled } from '@material-ui/core/styles'
import SidebarMenu from './SidebarMenu/SidebarMenu'
import SidebarBottomLinks from './SidebarBottomLinks'

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }
`,
)

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(3, 4)};
`,
)

const SidebarDivider = experimentalStyled(Divider)(
  ({ theme }) => `
        background: ${theme.sidebar.dividerBg};
`,
)

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
  const closeSidebar = () => toggleSidebar()

  return (
    <>
      <SidebarWrapper sx={{ display: { lg: 'block', xs: 'none' } }}>
        <Scrollbars autoHide>
          <TopSection>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flexStart"
              pb={0.5}
            >
              <img className="logo" src={ContesterLogo} />
            </Box>
          </TopSection>
          <SidebarDivider sx={{ my: 2, mx: 2 }} />
          <SidebarMenu />
          <Divider style={{ marginBottom: '2em' }} />
          <SidebarBottomLinks />
        </Scrollbars>
      </SidebarWrapper>
      <Drawer
        sx={{ display: { lg: 'none', xs: 'block' } }}
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>Contester</TopSection>
            <SidebarDivider sx={{ my: 2, mx: 2 }} />
            <SidebarMenu />
            <Divider style={{ marginBottom: '2em' }} />
            <SidebarBottomLinks />
          </Scrollbars>
        </SidebarWrapper>
      </Drawer>
    </>
  )
}

export default Sidebar
