import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteBrandDataThunk } from '../../../redux/slices/brandSlice'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { handleThunkPromiseToast } from '../../../utils/toast.config'

type BrandDeleteProps = {
  openDelete: boolean
  brandDeleteId
  closeHandler: () => void
}

const BrandDelete: React.FC<BrandDeleteProps> = ({
  openDelete,
  brandDeleteId,
  closeHandler,
}) => {
  const dispatch = useDispatch()

  const handleDeleteBrand = async () => {
    await handleThunkPromiseToast(
      () => dispatch(deleteBrandDataThunk(brandDeleteId)),
      'Brand deleted',
      'success',
      'Failed to delete brand',
      'error',
    )
    closeHandler()
  }

  return (
    <>
      <Dialog maxWidth="md" open={openDelete} onClose={closeHandler}>
        <DialogTitle sx={{ p: 3 }} disableTypography>
          <Typography variant="h4" gutterBottom>
            {'Are you sure you want to delete this brand?'}
          </Typography>
        </DialogTitle>
        <DialogActions sx={{ p: 3 }}>
          <Button color="secondary" onClick={closeHandler}>
            No
          </Button>
          <Button
            variant="contained"
            onClick={async (event: React.MouseEvent<HTMLElement>) => {
              await handleDeleteBrand()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BrandDelete
