import { toast, UpdateOptions } from 'react-toastify'

export type ToastType = 'info' | 'warning' | 'success' | 'error'

export const toastPromiseConfig = (
  msg: string,
  type: ToastType,
): UpdateOptions => {
  return {
    render: msg,
    type: type,
    isLoading: false,
    autoClose: 2000,
    draggable: true,
    draggableDirection: 'x',
    closeButton: true,
  }
}

export const handleThunkPromiseToast = async (
  fn: any,
  successMsg: string,
  successType: ToastType,
  failureMsg: string,
  failureType: ToastType,
) => {
  const id = toast.loading('Loading...')
  const res = await fn()
  if (res?.['payload']?.['success']) {
    toast.update(id, toastPromiseConfig(successMsg, successType))
  } else {
    toast.update(id, toastPromiseConfig(failureMsg, failureType))
  }
}

export const handlePromiseToast = async (
  fn: any,
  successMsg: string,
  successType: ToastType,
  failureMsg: string,
  failureType: ToastType,
) => {
  const id = toast.loading('Loading...')
  try {
    await fn()
    toast.update(id, toastPromiseConfig(successMsg, successType))
  } catch (err) {
    toast.update(id, toastPromiseConfig(failureMsg, failureType))
  }
}
