/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import { AxiosRequestConfig } from 'axios'
import { SwaggerResponse } from './config'
import { Http } from './httpRequest'
//@ts-ignore
import qs from 'qs'
import {
  MediaStorageGetListQueryParams,
  LiveStreamsGetAllQueryParams,
  SequencePollGetAllQueryParams,
  DashboardGetOrganizationDataQueryParams,
  DashboardGetCreatorDataQueryParams,
  MediaStorageItemCreateRequest,
  MediaStorageItemData,
  LiveStreamCreateRequest,
  BrandUpdateRequest,
  BillingDetailsData,
  UserData,
  SequencePollSetFeaturedListImageRequest,
  ValidateExternalUrlRequest,
  ValidateExternalUrlResponse,
  SequencePollUpdateRequest,
  SequencePollUpdateData,
  OrganizationOriginUpdateRequest,
  OrganizationData,
  OrganizationInviteRequest,
  OrganizationInviteResponse,
  ChunkedDataSummary,
  LiveStreamUpdateRequest,
  CreatorProfileData,
  SubscribeRequest,
  SelectSubscriptionTierTypeRequest,
  OrganizationPendingInvitation,
  OrganizationSwitchable,
  UIPermissions,
  UIConstants,
  SequencePollOverlayData,
  NumberedListSequencePollListData,
  SequencePollExperienceEditorConfiguration,
  PairMediaStorageItemObjectAggregatedTypeString,
  OrganizationOriginDataObject,
  OrganizationMemberData,
  OrganizationFonts,
  NumberedListMediaStorageItemData,
  LiveStreamListData,
  LiveStreamSingleData,
  LiveStreamStreamingCredentials,
  ExperienceCollectionData,
  DashboardData,
  PairDashboardTimeFilterString,
  BrandData,
  BillingSubscriptionDetails,
  BillingPricingResponse,
  SubscriptionTierLimits,
  BillingCardsData,
  BillingCardsSetupData,
} from './types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig,
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach(key => {
    const re = new RegExp(`{${key}}`, 'i')
    path = path.replace(re, obj[key])
  })

  return path
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData()

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value)
  })

  return formData
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody)
}

export const billingCancelSubscription = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    billingCancelSubscription.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride),
  )
}

/** Key is end point string without base url */
billingCancelSubscription.key = '/api/bo/billing/subscription/cancel'

export const billingConfirmSubscription = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.putRequest(
    billingConfirmSubscription.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingConfirmSubscription.key = '/api/bo/billing/subscription/confirm'

export const billingGetCards = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingCardsData>> => {
  return Http.getRequest(
    billingGetCards.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetCards.key = '/api/bo/billing/cards'

export const billingGetCardsSetupData = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingCardsSetupData>> => {
  return Http.getRequest(
    billingGetCardsSetupData.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetCardsSetupData.key = '/api/bo/billing/cards/setup'

export const billingGetDetails = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingDetailsData>> => {
  return Http.getRequest(
    billingGetDetails.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetDetails.key = '/api/bo/billing/details'

export const billingGetPricing = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingPricingResponse>> => {
  return Http.getRequest(
    billingGetPricing.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetPricing.key = '/api/bo/billing/pricing'

export const billingGetSubscriptionDetails = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingSubscriptionDetails>> => {
  return Http.getRequest(
    billingGetSubscriptionDetails.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetSubscriptionDetails.key = '/api/bo/billing/subscription'

export const billingGetSubscriptionTierLimits = (
  tier:
    | 'PendingSelection'
    | 'BusinessStandard'
    | 'BusinessCreator'
    | 'BusinessEnterprise'
    | 'CreatorStandard',
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SubscriptionTierLimits>> => {
  return Http.getRequest(
    template(billingGetSubscriptionTierLimits.key, { tier }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetSubscriptionTierLimits.key = '/api/bo/billing/limits/{tier}'

export const billingRemoveCard = (
  cardId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(billingRemoveCard.key, { cardId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingRemoveCard.key = '/api/bo/billing/cards/{cardId}'

export const billingSelectSubscriptionTierType = (
  requestBody: SelectSubscriptionTierTypeRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    billingSelectSubscriptionTierType.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingSelectSubscriptionTierType.key = '/api/bo/billing/subscription/type'

export const billingSetCardActive = (
  cardId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(billingSetCardActive.key, { cardId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingSetCardActive.key = '/api/bo/billing/cards/{cardId}/setActive'

export const billingSubscribe = (
  requestBody: SubscribeRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    billingSubscribe.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingSubscribe.key = '/api/bo/billing/subscription'

export const billingUpdateDetails = (
  requestBody: BillingDetailsData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.putRequest(
    billingUpdateDetails.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingUpdateDetails.key = '/api/bo/billing/details'

export const brandsCreateBrand = (
  requestBody: BrandUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.putRequest(
    brandsCreateBrand.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsCreateBrand.key = '/api/bo/brands'

export const brandsDeleteBrand = (
  brandId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(brandsDeleteBrand.key, { brandId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsDeleteBrand.key = '/api/bo/brands/{brandId}'

export const brandsGetBrand = (
  brandId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BrandData>> => {
  return Http.getRequest(
    template(brandsGetBrand.key, { brandId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsGetBrand.key = '/api/bo/brands/{brandId}'

export const brandsGetBrands = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BrandData[]>> => {
  return Http.getRequest(
    brandsGetBrands.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsGetBrands.key = '/api/bo/brands'

export const brandsUpdateBrand = (
  brandId: string,
  requestBody: BrandUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(brandsUpdateBrand.key, { brandId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsUpdateBrand.key = '/api/bo/brands/{brandId}'

export const creatorProfileGet = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CreatorProfileData>> => {
  return Http.getRequest(
    creatorProfileGet.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
creatorProfileGet.key = '/api/bo/creators/profile'

export const creatorProfileUpdate = (
  requestBody: CreatorProfileData,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ [x: string]: { [x in string | number]: any } }>
> => {
  return Http.postRequest(
    creatorProfileUpdate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
creatorProfileUpdate.key = '/api/bo/creators/profile'

export const dashboardGetAvailableBrands = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BrandData[]>> => {
  return Http.getRequest(
    dashboardGetAvailableBrands.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetAvailableBrands.key = '/api/bo/dashboard/availableBrands'

export const dashboardGetAvailableTimeFilters = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PairDashboardTimeFilterString[]>> => {
  return Http.getRequest(
    dashboardGetAvailableTimeFilters.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetAvailableTimeFilters.key = '/api/bo/dashboard/availableTimeFilters'

export const dashboardGetCreatorData = (
  queryParams: DashboardGetCreatorDataQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardData>> => {
  return Http.getRequest(
    dashboardGetCreatorData.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetCreatorData.key = '/api/bo/dashboard/creatorData'

export const dashboardGetOrganizationData = (
  queryParams: DashboardGetOrganizationDataQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardData>> => {
  return Http.getRequest(
    dashboardGetOrganizationData.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetOrganizationData.key = '/api/bo/dashboard/data'

export const experienceCollectionGetOrganizationOverview = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceCollectionData[]>> => {
  return Http.getRequest(
    experienceCollectionGetOrganizationOverview.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceCollectionGetOrganizationOverview.key =
  '/api/bo/experiencecollections'

export const liveStreamsComplete = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveStreamsComplete.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsComplete.key = '/api/bo/livestreams/{id}/complete'

export const liveStreamsCreate = (
  requestBody: LiveStreamCreateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.putRequest(
    liveStreamsCreate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsCreate.key = '/api/bo/livestreams'

export const liveStreamsDelete = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(liveStreamsDelete.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsDelete.key = '/api/bo/livestreams/{id}'

export const liveStreamsDisconnect = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveStreamsDisconnect.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsDisconnect.key = '/api/bo/livestreams/{id}/disconnect'

export const liveStreamsGetAll = (
  queryParams?: LiveStreamsGetAllQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamListData[]>> => {
  return Http.getRequest(
    liveStreamsGetAll.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetAll.key = '/api/bo/livestreams'

export const liveStreamsGetCredentials = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamStreamingCredentials>> => {
  return Http.getRequest(
    template(liveStreamsGetCredentials.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetCredentials.key = '/api/bo/livestreams/{id}/credentials'

export const liveStreamsGetSingle = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamSingleData>> => {
  return Http.getRequest(
    template(liveStreamsGetSingle.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetSingle.key = '/api/bo/livestreams/{id}'

export const liveStreamsUpdate = (
  id: string,
  requestBody: LiveStreamUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveStreamsUpdate.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsUpdate.key = '/api/bo/livestreams/{id}'

export const mediaStorageChunked = (
  id: string,
  size: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ChunkedDataSummary>> => {
  return Http.getRequest(
    template(mediaStorageChunked.key, { id, size }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageChunked.key = '/api/bo/mediastorage/{id}/chunked/{size}'

export const mediaStorageChunkedUpload = (
  id: string,
  size: number,
  chunk: number,
  requestBody: {
    /**
     *
     * - Format: binary
     */
    file: string
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ChunkedDataSummary>> => {
  return Http.postRequest(
    template(mediaStorageChunkedUpload.key, { id, size, chunk }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageChunkedUpload.key =
  '/api/bo/mediastorage/{id}/chunked/{size}/{chunk}'

export const mediaStorageCreateItem = (
  requestBody: MediaStorageItemCreateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemData>> => {
  return Http.putRequest(
    mediaStorageCreateItem.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageCreateItem.key = '/api/bo/mediastorage'

export const mediaStorageGetList = (
  queryParams?: MediaStorageGetListQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListMediaStorageItemData>> => {
  return Http.getRequest(
    mediaStorageGetList.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageGetList.key = '/api/bo/mediastorage'

export const mediaStorageGetSingle = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemData>> => {
  return Http.getRequest(
    template(mediaStorageGetSingle.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageGetSingle.key = '/api/bo/mediastorage/{id}'

export const mediaStorageUploadContent = (
  id: string,
  requestBody: {
    /**
     *
     * - Format: binary
     */
    file: string
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemData>> => {
  return Http.postRequest(
    template(mediaStorageUploadContent.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageUploadContent.key = '/api/bo/mediastorage/{id}'

export const organizationAssignMemberAdmin = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationAssignMemberAdmin.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationAssignMemberAdmin.key =
  '/api/bo/organizations/members/{id}/setAdmin'

export const organizationCancelPendingInvitation = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationCancelPendingInvitation.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationCancelPendingInvitation.key =
  '/api/bo/organizations/invitations/{id}/cancel'

export const organizationClaimShopifyShop = (
  shop: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationClaimShopifyShop.key, { shop }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationClaimShopifyShop.key = '/api/bo/organizations/shopify/claim/{shop}'

export const organizationGetCreators = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationMemberData[]>> => {
  return Http.getRequest(
    organizationGetCreators.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetCreators.key = '/api/bo/organizations/creators'

export const organizationGetFonts = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationFonts>> => {
  return Http.getRequest(
    organizationGetFonts.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetFonts.key = '/api/bo/organizations/availableFonts'

export const organizationGetMembers = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationMemberData[]>> => {
  return Http.getRequest(
    organizationGetMembers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetMembers.key = '/api/bo/organizations/members'

export const organizationGetPendingInvitations = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationPendingInvitation[]>> => {
  return Http.getRequest(
    organizationGetPendingInvitations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetPendingInvitations.key = '/api/bo/organizations/invitations'

export const organizationInviteCreator = (
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationInviteResponse>> => {
  return Http.postRequest(
    organizationInviteCreator.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationInviteCreator.key = '/api/bo/organizations/creators/invite'

export const organizationInviteMember = (
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationInviteResponse>> => {
  return Http.postRequest(
    organizationInviteMember.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationInviteMember.key = '/api/bo/organizations/members/invite'

export const organizationRemoveMember = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationRemoveMember.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationRemoveMember.key = '/api/bo/organizations/members/{id}/remove'

export const organizationUnassignMemberAdmin = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationUnassignMemberAdmin.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationUnassignMemberAdmin.key =
  '/api/bo/organizations/members/{id}/unsetAdmin'

export const organizationUpdateProfile = (
  requestBody: OrganizationData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    organizationUpdateProfile.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationUpdateProfile.key = '/api/bo/organizations/profile'

export const originsDeleteSingle = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(originsDeleteSingle.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
originsDeleteSingle.key = '/api/bo/origins/{id}'

export const originsGetAll = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationOriginDataObject[]>> => {
  return Http.getRequest(
    originsGetAll.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
originsGetAll.key = '/api/bo/origins'

export const originsUpdateSingle = (
  id: string,
  requestBody: OrganizationOriginUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(originsUpdateSingle.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
originsUpdateSingle.key = '/api/bo/origins/{id}'

export const sequencePollGetAll = (
  queryParams?: SequencePollGetAllQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListSequencePollListData>> => {
  return Http.getRequest(
    sequencePollGetAll.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetAll.key = '/api/bo/sequencepolls/list'

export const sequencePollGetAvailableImageObjectFocusTypes = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<PairMediaStorageItemObjectAggregatedTypeString[]>
> => {
  return Http.getRequest(
    sequencePollGetAvailableImageObjectFocusTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetAvailableImageObjectFocusTypes.key =
  '/api/bo/sequencepolls/availableImageObjectFocusTypes'

export const sequencePollGetExperienceEditorConfiguration = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollExperienceEditorConfiguration>> => {
  return Http.getRequest(
    sequencePollGetExperienceEditorConfiguration.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetExperienceEditorConfiguration.key =
  '/api/bo/sequencepolls/experienceEditorConfiguration'

export const sequencePollGetForUpdate = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollUpdateRequest>> => {
  return Http.getRequest(
    template(sequencePollGetForUpdate.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetForUpdate.key = '/api/bo/sequencepolls/{sequencePollId}'

export const sequencePollGetOverlayData = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollOverlayData>> => {
  return Http.getRequest(
    template(sequencePollGetOverlayData.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetOverlayData.key =
  '/api/bo/sequencepolls/{sequencePollId}/overlay'

export const sequencePollGetTemplateForUpdate = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollUpdateRequest>> => {
  return Http.getRequest(
    template(sequencePollGetTemplateForUpdate.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetTemplateForUpdate.key = '/api/bo/sequencepolls/templates/{id}'

export const sequencePollGetTemplates = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListSequencePollListData>> => {
  return Http.getRequest(
    sequencePollGetTemplates.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetTemplates.key = '/api/bo/sequencepolls/templates'

export const sequencePollSaveUpdate = (
  requestBody: SequencePollUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollUpdateData>> => {
  return Http.postRequest(
    sequencePollSaveUpdate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSaveUpdate.key = '/api/bo/sequencepolls/update'

export const sequencePollSetActive = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetActive.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetActive.key = '/api/bo/sequencepolls/{sequencePollId}/setActive'

export const sequencePollSetArchived = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetArchived.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetArchived.key =
  '/api/bo/sequencepolls/{sequencePollId}/setArchived'

export const sequencePollSetFeaturedListImage = (
  sequencePollId: string,
  requestBody: SequencePollSetFeaturedListImageRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetFeaturedListImage.key, { sequencePollId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetFeaturedListImage.key =
  '/api/bo/sequencepolls/{sequencePollId}/brandFeaturedImage'

export const sequencePollSetFlag = (
  sequencePollId: string,
  flag: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetFlag.key, { sequencePollId, flag }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetFlag.key = '/api/bo/sequencepolls/{sequencePollId}/{flag}/set'

export const sequencePollUnsetFlag = (
  sequencePollId: string,
  flag: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollUnsetFlag.key, { sequencePollId, flag }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollUnsetFlag.key =
  '/api/bo/sequencepolls/{sequencePollId}/{flag}/unset'

export const sequencePollValidateExternalUrl = (
  requestBody: ValidateExternalUrlRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ValidateExternalUrlResponse>> => {
  return Http.postRequest(
    sequencePollValidateExternalUrl.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollValidateExternalUrl.key =
  '/api/bo/sequencepolls/validateExternalUrl'

export const uiGetConstants = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UIConstants>> => {
  return Http.getRequest(
    uiGetConstants.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
uiGetConstants.key = '/api/bo/ui/constants'

export const uiGetPermissions = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UIPermissions>> => {
  return Http.getRequest(
    uiGetPermissions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
uiGetPermissions.key = '/api/bo/ui/permissions'

export const userAcceptInvitation = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(userAcceptInvitation.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userAcceptInvitation.key = '/api/bo/users/invitations/{id}/accept'

export const userDeclineInvitation = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(userDeclineInvitation.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userDeclineInvitation.key = '/api/bo/users/invitations/{id}/decline'

export const userGetActiveOrganization = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationData>> => {
  return Http.getRequest(
    userGetActiveOrganization.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetActiveOrganization.key = '/api/bo/users/activeOrganization'

export const userGetAvailableOrganizations = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationSwitchable[]>> => {
  return Http.getRequest(
    userGetAvailableOrganizations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetAvailableOrganizations.key = '/api/bo/users/availableOrganizations'

export const userGetInvitations = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationPendingInvitation[]>> => {
  return Http.getRequest(
    userGetInvitations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetInvitations.key = '/api/bo/users/invitations'

export const userGetProfile = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UserData>> => {
  return Http.getRequest(
    userGetProfile.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetProfile.key = '/api/bo/users/profile'

export const userSwitchOrganization = (
  organizationId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(userSwitchOrganization.key, { organizationId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userSwitchOrganization.key = '/api/bo/users/switch/{organizationId}'

export const userUpdateProfile = (
  requestBody: UserData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    userUpdateProfile.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userUpdateProfile.key = '/api/bo/users/profile'
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
}
export const _CONSTANT1 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}
