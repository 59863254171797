import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Uploader from '../Uploader/Uploader'
import { mediaStorageGetSingle } from '../../api/services'

function CreatorsList() {
  const [item, setItem] = useState({
    id: 'e54ba641-3082-4b86-9dca-256dd6ab75d1',
    location:
      'https://staging-media.contester.net/data/965d15f0-7181-4833-949f-b2a8ca5c079c/e54ba641-3082-4b86-9dca-256dd6ab75d1-original.png',
  })
  // useEffect(() => {
  //     mediaStorageGetSingle('e54ba641-3082-4b86-9dca-256dd6ab75d1').then(data => {
  //         setItem(data.data)
  //     })
  // }, [item])

  return (
    <>
      <Container>
        <Grid maxWidth="lg" container>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            sidebar
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            main
            <Uploader render={true} media={item} setMedia={setItem} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default CreatorsList
