import {
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import { experimentalStyled } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AlertDialog from 'src/components/Modal/Modal'
import { history } from 'src/history'
import { getSequencePollsDataThunk } from 'src/redux/slices/sequencePollSlice'
import { setSequenceSliceData } from 'src/redux/slices/sequenceSlice'
import { putImageMedia, saveImage } from 'src/services/api/mediastorage'
import {
  activateSequencePoll,
  archiveSequencePoll,
  getSequencePollData,
  setFeaturedListForSequencePoll,
  unsetFeaturedListForSequencePoll,
} from 'src/services/api/sequencePolls'
import {
  prepareSequencePollForRedux,
  SequencePoll,
} from 'src/utils/sequenceSliceHelper'
import styles from '../../Products/Sidebar.module.scss'
import localStyles from './SequenceDataManager.module.scss'
import { DashboardData, SequencePollListData } from '../../../api/types'
import { TabValue } from '../SequencePollList'
import { RootState } from '../../../redux/store'
import { AutoAwesome } from '@material-ui/icons'
import { sequencePollSetFeaturedListImage } from '../../../api/services'

const LinkWrapper = experimentalStyled(Box)(
  () => `
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    `,
)

const ButtonsWrapper = experimentalStyled(Box)(
  () => `
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    `,
)

const TypographyPrimary = experimentalStyled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
    `,
)

const ButtonWrapper = experimentalStyled(Button)(
  () => `
      margin-top: 10px
    `,
)

const CheckboxWrapper = experimentalStyled(Checkbox)(
  ({ theme }) => `
    padding: ${theme.spacing(0.5)};
    color: white;
`,
)

const TextFieldWrapper = experimentalStyled(TextField)(
  () => `
    border: 1px solid rgba(85, 105, 255, 0.5);

    .MuiOutlinedInput-root, .MuiInputBase-root{
      color: #fff !important;
    }
`,
)

const CopyButton = experimentalStyled(Button)(
  () => `
      float: right;
    `,
)

interface Props {
  sequencePollObj: SequencePollListData
  experienceCollections: { id: string }[]
  getSequencePollsData: () => Promise<void>
  filterByBrand: string
  status: string
  showAnalytics: boolean
  setShowAnalytics: React.Dispatch<React.SetStateAction<boolean>>
  setShowVisibilityOptions: React.Dispatch<React.SetStateAction<boolean>>
  getDashboardData: (id: string) => Promise<void>
}

const SequenceDataManager: React.FC<Props> = ({
  sequencePollObj,
  experienceCollections,
  getSequencePollsData,
  filterByBrand,
  status,
  showAnalytics,
  setShowAnalytics,
  setShowVisibilityOptions,
  getDashboardData,
}) => {
  const dispatch = useDispatch()

  const uploadRef = useRef<HTMLInputElement>()

  const [action, setAction] = useState<'DUPLICATE' | 'ARCHIVE' | ''>('')
  const [flChecked, setFlChecked] = useState(
    sequencePollObj?.sequencePoll?.isInBrandFeaturedList,
  )
  // MODAL
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [modalText, setModalText] = useState({
    title: '',
    content: '',
  })

  const canSeeAISettings = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.updateAISettings,
  )

  const handleSequencePollEdit = async () => {
    let data = await getSequencePollData(sequencePollObj?.sequencePoll.id)
    let reduxData = prepareSequencePollForRedux(
      data,
      sequencePollObj?.sequencePoll.id,
    )
    dispatch(setSequenceSliceData(reduxData))
    // dispatch(setSequenceStatus('Draft'))
    history.push('/create-content')
  }

  // const createdDate = new Date(sequencePoll?.createdAt * 1000);
  const createdDate = sequencePollObj?.sequencePoll?.createdAt?.join('.')

  const handleSequencePollDuplication = async () => {
    let data: SequencePoll = await getSequencePollData(
      sequencePollObj?.sequencePoll.id,
    )
    let reduxData = prepareSequencePollForRedux(data, '')
    dispatch(setSequenceSliceData(reduxData))
    history.push('/create-content')
  }

  const handleSequencePollStatusAction = async () => {
    if (sequencePollObj?.sequencePoll.status === 'Archived')
      await activateSequencePoll(sequencePollObj?.sequencePoll.id)
    else await archiveSequencePoll(sequencePollObj?.sequencePoll.id)
  }

  const getData = () => {
    dispatch(
      getSequencePollsDataThunk({
        brandId: filterByBrand,
        tab: status as TabValue,
        search: '',
      }),
    )
  }

  const handleFeaturedListAction = async () => {
    // change checked immediatelly for UX and revert it if request fails
    setFlChecked(!flChecked)
    if (!flChecked) {
      setFeaturedListForSequencePoll(sequencePollObj?.sequencePoll.id)
        .then(res => {
          getData()
        })
        .catch(err => {
          // console.log(err);
          setFlChecked(!flChecked)
        })
    } else {
      unsetFeaturedListForSequencePoll(sequencePollObj?.sequencePoll.id)
        .then(res => {
          getData()
        })
        .catch(err => {
          // console.log(err);
          setFlChecked(!flChecked)
        })
    }
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const blob = URL.createObjectURL(event.target.files[0])
    setIsUploading(true)
    let fileData = new FormData()
    fileData.append('file', event?.target?.files[0])
    putImageMedia().then(data => {
      saveImage(fileData, data.id)
        .then(response => {
          sequencePollSetFeaturedListImage(sequencePollObj?.sequencePoll.id, {
            image: { id: response.id, location: response.location },
          })
            .then(res => {
              getData()
            })
            .catch(err => {
              // console.log(err);
            })
            .finally(() => {
              setIsUploading(false)
            })
        })
        .finally(() => {
          setIsUploading(false)
        })
    })
  }

  const handleDialogAction = async () => {
    setIsLoading(true)
    switch (action) {
      case 'DUPLICATE':
        await handleSequencePollDuplication()
        toast.info('Change the name of content for the duplicated experience')
        break
      case 'ARCHIVE':
        await handleSequencePollStatusAction()
        toast.info('The experience is archived, you can find it on Archive tab')
        break
      default:
    }

    await getSequencePollsData()
    setIsModalOpen(false)
    setIsLoading(false)
  }

  useEffect(() => {
    setFlChecked(sequencePollObj?.sequencePoll?.isInBrandFeaturedList)
  }, [sequencePollObj])

  return (
    <>
      <Box>
        {sequencePollObj?.sequencePoll && (
          <Box sx={{ p: { xs: 2, lg: 4 } }}>
            {sequencePollObj?.sequencePoll?.editable && (
              <Box display="flex">
                <Box pl={2} pt={2}>
                  <TypographyPrimary variant="subtitle2" gutterBottom>
                    NAME: {sequencePollObj?.sequencePoll.name}
                  </TypographyPrimary>
                  <TypographyPrimary variant="subtitle2">
                    BRAND: {sequencePollObj?.brand?.title}
                  </TypographyPrimary>
                  <TypographyPrimary variant="subtitle2">
                    DATE: <Moment format="DD/MM/YYYY">{createdDate}</Moment>
                  </TypographyPrimary>
                  <TypographyPrimary variant="subtitle2">
                    Status: {sequencePollObj?.sequencePoll?.status}
                  </TypographyPrimary>
                </Box>
              </Box>
            )}
            <LinkWrapper>
              <ButtonWrapper
                onClick={() => {
                  toast.info('Link is copied to the clipboard')
                  navigator.clipboard.writeText(
                    sequencePollObj?.sequencePoll.publicUrl,
                  )
                }}
                variant="outlined"
                color="primary"
              >
                Copy Link
              </ButtonWrapper>
              {sequencePollObj?.sequencePoll?.overlayData?.publicUrl && (
                <ButtonWrapper
                  onClick={() => {
                    toast.info('Link is copied to the clipboard')
                    navigator.clipboard.writeText(
                      sequencePollObj?.sequencePoll?.overlayData?.publicUrl,
                    )
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Copy Overlay Link
                </ButtonWrapper>
              )}
            </LinkWrapper>
            <ButtonsWrapper>
              {showAnalytics ? (
                <ButtonWrapper
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowAnalytics(false)}
                >
                  Close analytics
                </ButtonWrapper>
              ) : (
                <ButtonWrapper
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setShowVisibilityOptions(false)
                    getDashboardData(sequencePollObj?.sequencePoll?.id)
                  }}
                >
                  View analytics
                </ButtonWrapper>
              )}
              {sequencePollObj?.sequencePoll?.editable && (
                <ButtonWrapper
                  variant="outlined"
                  color="primary"
                  onClick={handleSequencePollEdit}
                  disabled={!sequencePollObj?.sequencePoll?.editable}
                >
                  Edit
                </ButtonWrapper>
              )}
              <ButtonWrapper
                variant="outlined"
                color="primary"
                onClick={async () => {
                  let data = await getSequencePollData(
                    sequencePollObj?.sequencePoll.id,
                  )
                  let reduxData = prepareSequencePollForRedux(
                    data,
                    sequencePollObj?.sequencePoll.id,
                  )
                  setShowAnalytics(false)
                  dispatch(setSequenceSliceData(reduxData))
                  setShowVisibilityOptions(true)
                }}
              >
                Edit visibility
              </ButtonWrapper>
              <ButtonWrapper
                variant="outlined"
                color="primary"
                onClick={() => {
                  setModalText({
                    title: 'Click accept to proceed with action',
                    content: 'Are you sure you want to duplicate experience?',
                  })
                  setAction('DUPLICATE')
                  setIsModalOpen(true)
                }}
              >
                Duplicate
              </ButtonWrapper>
              {sequencePollObj?.sequencePoll?.editable && (
                <ButtonWrapper
                  variant="outlined"
                  color="primary"
                  disabled={!sequencePollObj?.sequencePoll?.editable}
                  onClick={() => {
                    setModalText({
                      title: 'Click accept to proceed with action',
                      content: 'Are you sure you want to proceed with action?',
                    })
                    setAction('ARCHIVE')
                    setIsModalOpen(true)
                  }}
                >
                  {sequencePollObj?.sequencePoll.status === 'Archived'
                    ? 'Make it active'
                    : 'Move to archive'}
                </ButtonWrapper>
              )}
            </ButtonsWrapper>
            {sequencePollObj?.sequencePoll?.editable && (
              <>
                <label className={localStyles.pureMaterialCheckbox}>
                  <input
                    key={uuidv4()}
                    type="checkbox"
                    checked={flChecked}
                    onChange={handleFeaturedListAction}
                  />
                  <span>Show this experience in featured list</span>
                </label>
                <Paper>
                  <Box
                    p={2}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h6">Featured list image</Typography>
                    {sequencePollObj?.sequencePoll?.brandFeaturedImage && (
                      <img
                        className="img-featured-list-preview"
                        // TODO fix this later
                        // @ts-ignore
                        src={sequencePollObj?.sequencePoll?.brandFeaturedImage}
                        alt=""
                      />
                    )}
                    <div className={styles.cardOption}>
                      <input
                        ref={uploadRef}
                        type="file"
                        onChange={event => {
                          if (event?.target?.files && event?.target.files[0]) {
                            handleFileUpload(event)
                          }
                        }}
                        accept="image/*, video/*"
                        hidden
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        className={styles.fullWidth}
                        onClick={() => uploadRef.current.click()}
                      >
                        {isUploading
                          ? 'Uploading...'
                          : sequencePollObj?.sequencePoll?.brandFeaturedImage
                          ? 'Change featured image'
                          : 'Upload featured image'}
                      </Button>
                    </div>
                  </Box>
                </Paper>
              </>
            )}
            {canSeeAISettings && sequencePollObj?.sequencePoll?.aiStatistics && (
              <>
                <Divider />
                <h4 style={{ marginBottom: '0px', marginTop: '30px' }}>
                  <AutoAwesome sx={{ color: '#FF00EC' }} />
                  <span className="ai-features-heading">AI features</span>
                </h4>
                <span>
                  {
                    sequencePollObj.sequencePoll.aiStatistics.objectFocus
                      .summary
                  }
                </span>
              </>
            )}

            {/* <Box>
              <SyntaxHighlighter language="javascript" style={docco}>
                {sequenceHtmlCode(experienceCollections?.[0]?.id)}
              </SyntaxHighlighter>
              <TextFieldWrapper
                color="primary"
                size="small"
                multiline
                sx={{ mt: 3 }}
                fullWidth
                value={sequenceHtmlCode(experienceCollections?.[0]?.id)}
              />
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    sequenceHtmlCode(experienceCollections?.[0]?.id),
                  );
                }}
              >
                Copy code
              </CopyButton>
            </Box> */}
            {/* <Box>
              <TextFieldWrapper
                size="small"
                multiline
                sx={{ mt: 3 }}
                fullWidth
                value={sequenceImportHtmlCode}
              />
              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(sequenceImportHtmlCode);
                }}
              >
                Copy code
              </CopyButton>
            </Box> */}
          </Box>
        )}
      </Box>
      <AlertDialog
        open={isModalOpen}
        setOpen={setIsModalOpen}
        isLoading={isLoading}
        handleAction={handleDialogAction}
        title={modalText.title}
        content={modalText.content}
      />
    </>
  )
}

export default SequenceDataManager
