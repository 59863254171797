import { Button, FormControl } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import isEmpty from 'lodash.isempty'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  setMediaLoadingProgress,
  setMediaLoadingState,
} from 'src/redux/slices/mediaSlice'
import { putImageMedia, saveImage } from 'src/services/api/mediastorage'
import {
  CardType,
  changeCardHeader,
  HeadingProps,
  HeadingType,
  ITreeItem,
} from '../../../../redux/slices/sequenceSlice'
import styles from '../../Sidebar.module.scss'
import HeaderEditor from './HeaderEditor'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    width: '100%',
  },
  cardOption: {
    width: '100%',
    marginTop: '15px',
  },
  paperWrapper: {
    padding: '10px 8px',
    marginBottom: '25px',
    marginTop: '85px',
  },
  title: {
    marginBottom: '20px',
    fontSize: '15px',
  },
  divMargin: {
    marginTop: '15px',
  },
}))

const CardHeaderContainer = (props: {
  handleChange: (options: HeadingProps) => void
  currentCard: ITreeItem
}) => {
  const uploadRef = useRef<HTMLInputElement>()
  const { handleChange, currentCard } = props
  const currentHeadingType = currentCard.heading?.type
  const dispatch = useDispatch()
  const classes = useStyles()
  const [headingText, setHeadingText] = useState(
    currentCard?.heading?.titleText,
  )
  const [labelText, setLabelText] = useState('')
  //const [loadingProgress, setLoadingProgress] = useState(0);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const blob = URL.createObjectURL(event.target.files[0])
    let fileData = new FormData()
    fileData.append('file', event?.target?.files[0])
    dispatch(setMediaLoadingState({ cardId: currentCard.cardId, status: true }))
    dispatch(setMediaLoadingProgress(0))
    const loadingCallback = progressEvent => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      if (percentCompleted === 100) toast.info('Media is uploaded')
      dispatch(setMediaLoadingProgress(percentCompleted))
    }
    putImageMedia().then(data => {
      saveImage(fileData, data.id, loadingCallback).then(response => {
        let cardHeader: HeadingProps = {
          type: HeadingType.noHeading,
          titleText: '',
        }
        if (currentHeadingType === HeadingType.image)
          cardHeader = {
            type: currentHeadingType,
            imageId: response.id,
            imageLocation: response.location,
          }
        if (currentHeadingType === HeadingType.video) {
          cardHeader = {
            type: HeadingType.video,
            videoId: response.id,
            videoLocation: response.location,
          }
        }
        dispatch(
          changeCardHeader({
            cardId: currentCard.cardId,
            options: cardHeader,
          }),
        )
        dispatch(
          setMediaLoadingState({ cardId: currentCard.cardId, status: false }),
        )
      })
    })
  }

  const getCurrentHeadingType = () => {
    if (
      currentCard?.heading?.isAsPrevious ||
      currentCard?.headingType === 'INHERIT_PARENT'
    ) {
      return HeadingType.asPrevious
    } else {
      return currentHeadingType ?? HeadingType.noHeading
    }
  }

  useEffect(() => {
    setHeadingText(currentCard?.heading?.titleText || '')
  }, [currentCard])

  return (
    <Paper
      variant="outlined"
      className={`${classes.paperWrapper} ${styles.sidebarBox}`}
    >
      <div className={classes.title}>Heading</div>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        disabled={isEmpty(currentCard)}
      >
        <InputLabel>Header type</InputLabel>
        <Select
          onChange={e =>
            handleChange({
              type: e.target.value,
              titleText:
                e.target.value === HeadingType.title ? 'Some heading...' : '',
            })
          }
          label="Header type"
          defaultValue={HeadingType.noHeading}
          value={getCurrentHeadingType()}
        >
          <MenuItem value={HeadingType.noHeading}>No heading</MenuItem>
          <MenuItem value={HeadingType.title}>Title heading</MenuItem>
          <MenuItem value={HeadingType.video}>Video heading</MenuItem>
          {/*<MenuItem value={HeadingType.image}>Image heading</MenuItem>*/}
          {currentCard.cardType.cardType !== CardType.root && (
            <MenuItem value={HeadingType.asPrevious}>
              {' '}
              ⃪ Previous heading
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {!currentCard?.heading?.isAsPrevious && (
        <>
          {currentHeadingType === HeadingType.title && (
            <div className={classes.divMargin}>
              <TextField
                fullWidth
                placeholder="Some heading..."
                value={headingText || ''}
                onBlur={e => {
                  dispatch(
                    changeCardHeader({
                      cardId: currentCard?.cardId,
                      options: {
                        type: HeadingType.title,
                        titleText: e.target.value,
                      },
                    }),
                  )
                }}
                onChange={e => {
                  setHeadingText(e.target.value)
                }}
                label="Enter heading"
              />
              <HeaderEditor currentCard={currentCard} />
            </div>
          )}
          {(currentHeadingType === HeadingType.video ||
            currentHeadingType === HeadingType.image) && (
            <div className={styles.cardOption}>
              <input
                ref={uploadRef}
                type="file"
                onChange={event => {
                  if (event?.target?.files && event?.target.files[0]) {
                    handleFileUpload(event)
                  }
                }}
                accept={
                  currentHeadingType === HeadingType.video
                    ? 'video/*'
                    : 'image/*'
                }
                hidden
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                className={styles.fullWidth}
                onClick={() => uploadRef.current.click()}
              >
                Upload{' '}
                {currentHeadingType === HeadingType.video ? 'video' : 'image'}
              </Button>
            </div>
          )}
        </>
      )}
    </Paper>
  )
}

export default CardHeaderContainer
