import React from 'react'
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  getNodeAtPath,
  removeNodeAtPath,
} from 'react-sortable-tree'
import { v4 as uuidv4 } from 'uuid'

import AddItemIcon from '../../../assets/Create.svg'
import RemoveItemIcon from '../../../assets/Delete.svg'
import DuplicateItemIcon from '../../../assets/Duplicate.svg'
import styles from '../SequenceBox.module.scss'
// import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useDispatch, useSelector } from 'react-redux'
import {
  CardTreeType,
  CardType,
  changeCardTreeType,
  duplicateTreeItem,
  HeadingType,
  ITreeItem,
  setCurrentCard,
  setTreeItems,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import { changeImageProps } from '../../../redux/slices/sequenceSlice'
import { putImageMedia, saveImage } from '../../../services/api/mediastorage'

import { toast } from 'react-toastify'
import { DEFAULT_MEDIA_ID, DEFAULT_MEDIA_URL } from 'src/constants'
import noImage from '../../../assets/image_holder_x.jpg'

export interface SequenceBoxItemsProps {
  data: any
  selectable: boolean
  dndType?: string
  handleUpdateItem?: any
  setActiveItem: (item: string) => void
  activeItem: string
}

const SequenceBoxItems: React.FC<SequenceBoxItemsProps> = ({
  data,
  dndType,
  handleUpdateItem,
  selectable,
  activeItem,
  setActiveItem,
}) => {
  const dispatch = useDispatch()
  const getNodeKey = ({ treeIndex }: any) => treeIndex
  const currentCard = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps.currentCard,
  )

  const handleSelectItem = (node: ITreeItem) => {
    const itemType =
      node.children?.length > 0 ? CardTreeType.parent : CardTreeType.child
    dispatch(
      setCurrentCard({
        card: {
          ...node,
          cardTreeType: itemType,
        },
      }),
    )
  }

  const checkItemsLimit = (updateItem: any, node: any) => {
    ;(node.children && node.children.length < 6) || !node.children
      ? updateItem()
      : alert('You only can add 6 items for each parent')
  }

  const handleDuplicateItem = (
    data: any,
    path: (number | string)[],
    node: ITreeItem,
  ) => {
    const parentNode: ITreeItem = getNodeAtPath({
      treeData: data,
      path: path.length > 1 && [...path].slice(0, -1),
      getNodeKey,
    })
    dispatch(
      duplicateTreeItem({
        parentId: parentNode?.node?.cardId,
        itemId: node.cardId,
      }),
    )
  }

  const addNewNode = (
    path: (string | number)[],
    node: ITreeItem,
    name?: string,
  ) => {
    const newData = addNodeUnderParent({
      treeData: data,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        cardId: uuidv4(),
        name: 'name',
        cardTreeType: CardTreeType.child,
        cardType: {
          cardType: CardType.directLink,
        },
        heading: {
          type: HeadingType.noHeading,
          isAsPrevious: false,
        },
        headingType: 'NONE',
        media: {
          id: DEFAULT_MEDIA_ID,
          location: DEFAULT_MEDIA_URL,
        },
      } as ITreeItem,
      addAsFirstChild: false,
    }).treeData
    checkItemsLimit(() => dispatch(setTreeItems(newData)), node)
    dispatch(
      changeCardTreeType({
        cardId: node.cardId,
        treeType: CardTreeType.parent,
        type: CardType.intermediate,
      }),
    )
  }

  const removeNode = (
    path: (string | number)[],
    data: any,
    getNodeKey: ({ treeIndex }: any) => any,
    handleUpdateItem: any,
    dispatch,
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    let tempPath = [...path].slice(0, -1)
    const parentNode = getNodeAtPath({
      treeData: data,
      path: path.length > 1 && tempPath,
      getNodeKey,
    })
    const newData = removeNodeAtPath({
      treeData: data,
      path,
      getNodeKey,
    })
    handleUpdateItem(newData)
    dispatch(setCurrentCard())
    parentNode.node.children &&
      parentNode.node.children.length === 1 &&
      dispatch(
        changeCardTreeType({
          cardId: parentNode.node.cardId,
          treeType: CardTreeType.child,
          type: CardType.directLink,
        }),
      )
    e.preventDefault()
  }

  return (
    <SortableTree
      className="tree"
      treeData={data}
      isVirtualized={false}
      onChange={(data: any) => handleUpdateItem(data)}
      dndType={dndType}
      shouldCopyOnOutsideDrop={false}
      canDrag={e => (e.node as ITreeItem).cardType.cardType !== CardType.root}
      canDrop={e => {
        return e.nextPath.length > 1 && e.nextParent?.children?.length < 7
      }}
      generateNodeProps={({
        node,
        path,
      }: {
        node: ITreeItem
        path: (string | number)[]
      }) => ({
        onClick: e => {
          if (e?.target?.tagName !== 'IMG' && e?.target?.tagName !== 'INPUT')
            handleSelectItem(node)
        },
        className: `${node.cardId === currentCard.cardId ? 'nodeActive' : ''}`,
        title: [
          // heading input
          <label key={uuidv4()} htmlFor={`file-${node.cardId}`}>
            <img
              className={`${styles.itemImage} ${
                node.cardType.cardType === CardType.root ? styles.rootImage : ''
              }`}
              src={node.media?.location || noImage}
              alt="item"
            />
          </label>,
          <p key={uuidv4()} className={styles.itemNameInput}>
            {node?.heading?.titleText?.length > 8
              ? (node?.heading?.titleText).slice(0, 8) + '...'
              : node?.heading?.titleText}
          </p>,
          <form key={uuidv4()}>
            <input
              type="file"
              onClick={e =>
                node.cardType.cardType === CardType.root && e.preventDefault()
              }
              onChange={event => {
                if (event?.target?.files && event?.target.files[0]) {
                  let fileData = new FormData()
                  fileData.append('file', event?.target?.files[0])
                  putImageMedia().then(data => {
                    saveImage(fileData, data.id).then(response => {
                      dispatch(
                        changeImageProps({
                          cardId: node.cardId,
                          props: response,
                        }),
                      )
                      toast.info('Media is uploaded')
                    })
                  })
                  const image = URL.createObjectURL(event.target.files[0])
                  const newData = changeNodeAtPath({
                    treeData: data,
                    path,
                    getNodeKey,
                    newNode: { ...node, image },
                  })
                  dispatch(setTreeItems(newData))
                  handleUpdateItem(newData)
                }
              }}
              accept="image/*"
              id={`file-${node.cardId}`}
              hidden
            />
          </form>,
        ],
        buttons:
          node.cardType.cardType !== CardType.root
            ? [
                <img
                  key={uuidv4()}
                  src={AddItemIcon}
                  alt="item-add"
                  onClick={() => addNewNode(path, node)}
                />,
                <img
                  key={uuidv4()}
                  src={RemoveItemIcon}
                  alt="item-remove"
                  onClick={e => {
                    removeNode(
                      path,
                      data,
                      getNodeKey,
                      handleUpdateItem,
                      dispatch,
                      e,
                    )
                  }}
                />,
                <img
                  src={DuplicateItemIcon}
                  key={uuidv4()}
                  onClick={() => handleDuplicateItem(data, path, node)}
                  height="18px"
                  alt="duplicate"
                />,
              ]
            : [
                <img
                  key={uuidv4()}
                  src={AddItemIcon}
                  alt="item-add"
                  onClick={() => addNewNode(path, node, 'New')}
                />,
              ],
      })}
    />
  )
}

export default SequenceBoxItems
