import BrandItems from './BrandItems/BrandItems'
import React from 'react'

function Brands() {
  return (
    <>
      <BrandItems />
    </>
  )
}

export default Brands
