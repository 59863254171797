import React from 'react'
import styled from '@emotion/styled'

const ModalWrap = styled.div(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 7, 52, 0.2)',
  top: 0,
  left: 0,
  zIndex: 1232,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const Modal = ({ children, autoClose, onClose }: any) => (
  <ModalWrap
    onClick={() => {
      if (autoClose) {
        onClose()
      }
    }}
  >
    {children}
  </ModalWrap>
)
