import { API_URL_BO_SEQUENCE_POLL, routes } from 'src/constants'
import { ITreeItem } from 'src/redux/slices/sequenceSlice'
import { prepareSequencePollForBackend } from 'src/utils/sequenceSliceHelper'
import { request } from '../apiServices'
import {
  AISettings,
  OverlaySettings,
  SequencePollUpdateData,
  Template,
  WidgetSettings,
} from '../../api/types'

export const saveSequencePoll = async (
  items: ITreeItem[],
  contentName: string,
  brandId: string,
  sequenceId: string,
  status: string,
  template: Template,
  widgetSettings: WidgetSettings,
  overlay: OverlaySettings,
  ai: AISettings,
): Promise<SequencePollUpdateData> => {
  try {
    let sequencePoll = prepareSequencePollForBackend(
      items,
      contentName,
      brandId,
      status,
      template,
      widgetSettings,
      overlay,
      ai,
    )
    return await request({
      url: routes.SAVE_UPDATE_SEQUENCE_POLL,
      method: 'POST',
      data: {
        sequencePollId: sequenceId,
        ...sequencePoll,
      },
    })
  } catch (err) {
    throw err
  }
}

export const getSequencePollData = async (id: string) => {
  try {
    return await request({
      url: API_URL_BO_SEQUENCE_POLL + '/' + id,
      method: 'GET',
    })
  } catch (err) {
    return err
  }
}

export const archiveSequencePoll = async (id: string) => {
  try {
    return await request({
      url: `${API_URL_BO_SEQUENCE_POLL}/${id}/setArchived`,
      method: 'POST',
    })
  } catch (err) {
    return err
  }
}

export const activateSequencePoll = async (id: string) => {
  try {
    return await request({
      url: `${API_URL_BO_SEQUENCE_POLL}/${id}/setActive`,
      method: 'POST',
    })
  } catch (err) {
    return err
  }
}

export const setFeaturedListForSequencePoll = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request({
        url: `${API_URL_BO_SEQUENCE_POLL}/${id}/IS_IN_BRAND_FEATURED_LIST/set`,
        method: 'POST',
      })
      resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const unsetFeaturedListForSequencePoll = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request({
        url: `${API_URL_BO_SEQUENCE_POLL}/${id}/IS_IN_BRAND_FEATURED_LIST/unset`,
        method: 'POST',
      })
      resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}
