import React from 'react'
import { Video } from 'src/redux/slices/sequenceSlice'
import LoaderBar from '../../LoaderProgress/LoaderBar'
import styles from '../SequenceDemo.module.scss'
interface ISequenceHeaderVideo {
  currentCardVideo: Video
  isLoading: boolean
  loadingProgress: number
}

const SequenceHeaderVideo: React.FC<ISequenceHeaderVideo> = ({
  currentCardVideo,
  isLoading,
  loadingProgress,
}) => {
  if (isLoading) {
    return (
      <div className={styles.mobileBox__video__loader}>
        <LoaderBar bgcolor="#5569ff" completed={loadingProgress} />
      </div>
    )
  }
  return (
    <>
      {currentCardVideo?.videoLocation ? (
        <div className={styles.mobileBox__video}>
          <video
            controls
            className={styles.headingImage}
            src={currentCardVideo.videoLocation}
          ></video>
        </div>
      ) : (
        <div className={styles.mobileBox__video}>
          <p>Upload video from sidebar</p>
        </div>
      )}
    </>
  )
}

export default SequenceHeaderVideo
