import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BrandData, BrandUpdateRequest } from '../../api/types'
import {
  brandsCreateBrand,
  brandsDeleteBrand,
  brandsGetBrands,
  brandsUpdateBrand,
} from '../../api/services'

interface IBrandSliceState {
  brands: BrandData[]
}

export const getBrandsDataThunk = createAsyncThunk(
  'brands/getBrands',
  async () => {
    let brands = await brandsGetBrands()
    return { brands }
  },
)

export const addBrandDataThunk = createAsyncThunk(
  'brands/addBrand',
  async (brand: BrandUpdateRequest) => {
    await brandsCreateBrand(brand)
    let brands = await brandsGetBrands()
    return { brands, success: true }
  },
)

export const editBrandDataThunk = createAsyncThunk(
  'brands/editBrand',
  async (request: { id: string; brand: BrandUpdateRequest }) => {
    await brandsUpdateBrand(request.id, request.brand)
    let brands = await brandsGetBrands()
    return { brands, success: true }
  },
)

export const deleteBrandDataThunk = createAsyncThunk(
  'brands/deleteBrand',
  async (id: string) => {
    await brandsDeleteBrand(id)
    let brands = await brandsGetBrands()
    return { brands, success: true }
  },
)

let initialState: IBrandSliceState = {
  brands: [],
}

const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBrandsDataThunk.fulfilled, (state, action) => {
      state.brands = action.payload.brands.data
    })
    builder.addCase(addBrandDataThunk.fulfilled, (state, action) => {
      state.brands = action.payload.brands.data
    })
    builder.addCase(editBrandDataThunk.fulfilled, (state, action) => {
      state.brands = action.payload.brands.data
    })
    builder.addCase(deleteBrandDataThunk.fulfilled, (state, action) => {
      state.brands = action.payload.brands.data
    })
  },
})

export default brandSlice.reducer
