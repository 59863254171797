import { Card } from '@material-ui/core'
import { SetStateAction } from 'react'
import { TopUserChoice } from '../../../api/types'
import { TableType } from '../../../pages/Dashboard'
import { HighlightOff } from '@material-ui/icons'
import './SummaryTable.css'

interface Props {
  userChoices: TopUserChoice[]
  rowsToShow: number
  withHeading: boolean
  setShowFullList: React.Dispatch<SetStateAction<TableType>>
}

const UserChoiceTable = ({
  userChoices,
  rowsToShow,
  withHeading,
  setShowFullList,
}: Props) => {
  return (
    <div>
      <Card className="summary-table">
        <table>
          <thead
            className={`summary-table-header ${
              !withHeading && 'sticky-header'
            }`}
          >
            {!withHeading && (
              <p
                className="table-close"
                onClick={() => setShowFullList(undefined)}
              >
                <HighlightOff />
              </p>
            )}
            <tr>
              <th>Top user choices</th>
              <th>Clicks</th>
              <th>Page</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {userChoices?.slice(0, rowsToShow).map(choice => {
              return (
                <tr key={choice?.cardId}>
                  <td>
                    <img
                      className="table-avatar-square"
                      src={choice.cardImage}
                    />
                    {choice.campaignName}
                  </td>
                  <td>{choice?.leads?.displayValue} clicks</td>
                  <td>
                    <a target="_blank" href={choice?.leadUrl}>
                      {choice?.leadUrl}
                    </a>
                  </td>
                  <td>{choice?.tags?.join(', ')}</td>
                </tr>
              )
            })}
          </tbody>
          {withHeading && (
            <tfoot>
              <tr className="table-show-more">
                <td colSpan={5} onClick={() => setShowFullList('USER')}>
                  Show more
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </Card>
    </div>
  )
}

export default UserChoiceTable
