import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import isEmpty from 'lodash.isempty'
import DateTimePicker from '@material-ui/lab/DateTimePicker'
import moment from 'moment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import AdapterMoment from '@material-ui/lab/AdapterMoment'
import CloseIcon from '@material-ui/icons/Close'
import { experimentalStyled } from '@material-ui/core/styles'
import { Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'src/redux/hooks'
import * as Yup from 'yup'
import ImageHolder2 from '../../../assets/Image-holder2.jpg'
import {
  addLiveStreamThunk,
  updateLiveStreamThunk,
} from '../../../redux/slices/liveStreamsSlice'
import './LiveStreamDialog.css'
import { LiveStreamUpdateRequest } from '../../../api/types'
import { handleThunkPromiseToast } from '../../../utils/toast.config'

const Input = experimentalStyled('input')({
  display: 'none',
})

const AvatarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
      position: relative;
      width: 100%;
      .MuiAvatar-root {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
  `,
)

const PosterImageWrapper = experimentalStyled(Avatar)(
  () => `
    .MuiAvatar-root{
      width: 225px !important;
    }
    `,
)

const UploadButton = experimentalStyled(InputLabel)(
  () => `
    padding: 6px 16px;
    border-radius: 6px;
    background-color: #ffffff66;
    color: #fff;
    border: 0;
    text-shadow: 2px 2px 1px rgb(66 68 90 / 20%);
    cursor: pointer;
  `,
)

const ButtonUploadWrapper = experimentalStyled(Box)(
  ({ theme }) => `
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
  
      .MuiIconButton-root {
        border-radius: 100%;
        background: ${theme.colors.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: ${theme.colors.shadows.primary};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        padding: 0;
    
        &:hover {
          background: ${theme.colors.primary.dark};
        }
      }
  `,
)

const ImageGridItem = experimentalStyled(Grid)`
      padding: 0;
`
const ImageGridContainer = experimentalStyled(Grid)`
`
type VisibilityProps = 'Public' | 'Private'
type LiveStreamDialogProps = {
  formData: any
  closeHandler: () => void
  uploadPreviewFile: (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue,
  ) => any
  posterUrl: string
  posterId: string
  isPosterLoading: boolean
}

const LiveStreamDialog: React.FC<LiveStreamDialogProps> = ({
  formData,
  closeHandler,
  uploadPreviewFile,
  posterId,
  posterUrl,
  isPosterLoading,
}) => {
  const dispatch = useAppDispatch()
  const [visibilityValue, setVisibility] = React.useState<VisibilityProps>(
    formData.type || 'Public',
  )
  const [sourceTypeValue, setSourceType] = React.useState(
    formData.sourceType || 'WebRTC',
  )

  const handleVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibility((event.target as HTMLInputElement).value as VisibilityProps)
  }

  const handleSourceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSourceType((event.target as HTMLInputElement).value)
  }

  const handleCreateStreamSuccess = async values => {
    const stream = {
      title: values.title,
      scheduled: (moment(values.scheduled).valueOf() / 1000).toString(),
      posterId: posterId,
      sourceType: values.sourceType,
    }
    await handleThunkPromiseToast(
      () => dispatch(addLiveStreamThunk(stream)),
      'Stream created',
      'success',
      'Failed to create stream',
      'error',
    )
    closeHandler()
  }

  const handleUpdateStream = async values => {
    const stream: LiveStreamUpdateRequest = {
      title: values.title,
      scheduled: (moment(values.scheduled).valueOf() / 1000).toString(),
      posterId: posterId,
      visibility: visibilityValue,
      sourceType: sourceTypeValue,
    }
    const res = await dispatch(
      updateLiveStreamThunk({ id: values.id, liveStream: stream }),
    ).unwrap()
    if (res?.success) {
      toast.success('The stream is successfully edited')
    }
    closeHandler()
  }

  return (
    <>
      <Grid container>
        <DialogTitle
          sx={{ p: 3 }}
          disableTypography
          style={{ paddingLeft: '90px' }}
        >
          <Typography variant="h4" gutterBottom>
            {formData.title === '' ? 'Live stream setup' : 'Live stream edit'}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={closeHandler}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              borderRadius: '50%',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Formik
          initialValues={formData}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .max(100, 'The title must be up to 100 characters long')
              .required('The title field is required'),
            scheduled: Yup.string().required('The time zone field is required'),
            posterId: Yup.mixed().required('Required Field'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setStatus({ success: true })
              setSubmitting(false)
              if (formData.title === '') {
                await handleCreateStreamSuccess(values)
              } else {
                await handleUpdateStream(values)
              }
            } catch (err) {
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => {
            return (
              <Grid container>
                <form onSubmit={handleSubmit}>
                  <ImageGridContainer
                    container
                    spacing={3}
                    mb={1}
                    alignItems="center"
                  >
                    <ImageGridItem item xs={12} lg={7}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        sx={{
                          border: '1px solid #223354',
                          borderRadius: '10px',
                          maxWidth: '350px',
                          maxHeight: '400px',
                        }}
                      >
                        <AvatarWrapper>
                          <PosterImageWrapper
                            variant="rounded"
                            src={posterUrl === '' ? ImageHolder2 : posterUrl}
                          />
                          <ButtonUploadWrapper>
                            <Input
                              accept="image/*"
                              id="posterId"
                              name="posterId"
                              type="file"
                              onChange={e => {
                                uploadPreviewFile(e, setFieldValue)
                              }}
                            />
                            <UploadButton htmlFor="posterId">
                              Upload
                            </UploadButton>
                          </ButtonUploadWrapper>
                        </AvatarWrapper>
                      </Box>
                      {errors.posterId && !isPosterLoading && (
                        <p className="brand-dialog-error">
                          Poster image is required
                        </p>
                      )}
                    </ImageGridItem>

                    <Grid lg={5} item sx={{ pb: 3 }}>
                      <Grid container spacing={3} mt={0}>
                        <Grid item xs={12} md={12}>
                          <TextField
                            error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={touched.title && errors.title}
                            label="Live stream Title"
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormHelperText sx={{ mb: 0, ml: 0 }}>
                            Schedule
                          </FormHelperText>

                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                              minDate={new Date()}
                              maxDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() + 1,
                                  ),
                                )
                              }
                              value={values.scheduled}
                              onChange={value =>
                                setFieldValue(
                                  'scheduled',
                                  moment(value).format('DD MMM YYYY[,] HH:mm'),
                                  true,
                                )
                              }
                              label="Date Time"
                              renderInput={params => {
                                return (
                                  <TextField
                                    name="scheduled"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    onBlur={handleBlur}
                                    {...params}
                                    error={Boolean(
                                      touched.scheduled && errors.scheduled,
                                    )}
                                    helperText={
                                      touched.scheduled && errors.scheduled
                                    }
                                  />
                                )
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      {!isEmpty(formData.title) && (
                        <Grid xs={12} sx={{ mt: 1 }}>
                          <FormControl>
                            <FormHelperText sx={{ mb: 0, ml: 0 }}>
                              Visibility
                            </FormHelperText>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={visibilityValue}
                              onChange={handleVisibility}
                            >
                              <FormControlLabel
                                value="Public"
                                control={<Radio />}
                                label="Public"
                              />
                              <FormControlLabel
                                value="Private"
                                control={<Radio />}
                                label="Private"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      )}
                      {!isEmpty(formData.title) && (
                        <Grid xs={12} sx={{ mt: 1 }}>
                          <FormControl>
                            <FormHelperText sx={{ mb: 0, ml: 0 }}>
                              Input source type
                            </FormHelperText>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={sourceTypeValue}
                              onChange={handleSourceType}
                            >
                              <FormControlLabel
                                value="Rtmps"
                                control={<Radio />}
                                label="Rtmps"
                              />
                              <FormControlLabel
                                value="Srt"
                                control={<Radio />}
                                label="Srt"
                              />
                              <FormControlLabel
                                value="WebRTC"
                                control={<Radio />}
                                label="WebRTC"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </ImageGridContainer>
                  <DialogActions sx={{ py: 3 }}>
                    <Button
                      sx={{ pl: 5, pr: 5 }}
                      variant="outlined"
                      color="primary"
                      onClick={closeHandler}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ pl: 5, pr: 5 }}
                      type="submit"
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={Boolean(errors.submit) || isSubmitting}
                      variant="contained"
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              </Grid>
            )
          }}
        </Formik>
      </Grid>
    </>
  )
}

export default LiveStreamDialog
