import * as React from 'react'

import styles from '../SequenceDemo.module.scss'

export interface SequeceHeaderProps {}

const SequeceHeader: React.FC<SequeceHeaderProps> = () => (
  <div className={styles.mobileBox__header}>
    {/*<img src="https://www.tallinn.ee/gal_pildid/202228.jpg" alt="" />*/}
  </div>
)

export default SequeceHeader
