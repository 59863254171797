import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  content: string
  handleAction?: () => void
  isLoading: boolean
  showCancel?: boolean
}

const AlertDialog: React.FC<Props> = ({
  open,
  setOpen,
  title,
  content,
  handleAction,
  isLoading,
  showCancel = true,
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: 'pre' }}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showCancel && (
            <Button disabled={isLoading} onClick={handleClose} color="primary">
              Cancel
            </Button>
          )}
          <Button
            disabled={isLoading}
            onClick={handleAction}
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertDialog
