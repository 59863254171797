import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SequencePollListData } from '../../api/types'

import {
  sequencePollGetAll,
  sequencePollGetTemplates,
} from '../../api/services'
import { TabValue } from '../../components/SequencePollList/SequencePollList'

export const getSequencePollsDataThunk = createAsyncThunk(
  'sequencePoll/getSequencePolls',
  async (payload: { brandId: string; tab: TabValue; search: string }) => {
    let data: SequencePollListData[]

    if (payload?.tab === 'Templates') {
      data = (await sequencePollGetTemplates()).data.content
    } else if (payload?.tab === 'Creator experiences') {
      data = (
        await sequencePollGetAll({
          brandId: payload?.brandId,
          page: 0,
          owner: 'Creator',
          perPage: 500,
          searchTerm: payload?.search,
        })
      ).data.content
    } else {
      data = (
        await sequencePollGetAll({
          brandId: payload?.brandId,
          page: 0,
          owner: 'Member',
          perPage: 500,
          searchTerm: payload?.search,
          status: payload?.tab,
        })
      ).data.content
    }

    return {
      sequencePollList: data,
    }
  },
)

interface ISequencePollSliceState {
  sequencePollList: SequencePollListData[]
}

let initialState: ISequencePollSliceState = {
  sequencePollList: [],
}

const sequencePollSlice = createSlice({
  name: 'sequencePoll',
  initialState,
  reducers: {
    setSequencePollObjList(
      state,
      action: PayloadAction<SequencePollListData[]>,
    ) {
      state.sequencePollList = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getSequencePollsDataThunk.fulfilled, (state, action) => {
      state.sequencePollList = action.payload.sequencePollList
    })
  },
})

export const { setSequencePollObjList } = sequencePollSlice.actions

export default sequencePollSlice.reducer
