import { Card } from '@material-ui/core'
import { buildStyles } from 'react-circular-progressbar'
import { DashboardCount } from '../../../api/types'
import Gauge from '../Templates/RoundChart'
import './SummaryOverview.css'

interface Props {
  averageTime: DashboardCount
  bounceRate: DashboardCount
  ctr: DashboardCount
}
const Average = ({ bounceRate, ctr, averageTime }: Props) => {
  return (
    <Card className="dashboard-summary-card">
      <div className="dashboard-summary-title">
        <p>Average</p>
      </div>
      <div className="dashboard-summary-average">
        <div className="dashboard-summary-time">
          <div className="dashboard-summary-time-wrapper">
            <b>{averageTime?.displayValue}</b>
          </div>
          <p>Time spent</p>
        </div>
        <div className="dashboard-summary-gauge">
          <Gauge
            circleRatio={0.65}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 5.7,
              textSize: '22px',
            })}
            value={bounceRate?.current}
            strokeWidth={13}
            text={bounceRate?.displayValue}
            color="primary"
            size="large"
          ></Gauge>
          <p>Bounce rate</p>
        </div>
        <div className="dashboard-summary-gauge">
          <Gauge
            circleRatio={0.65}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 5.7,
              textSize: '22px',
            })}
            value={ctr?.current}
            strokeWidth={13}
            text={ctr?.displayValue}
            color="pink"
            size="large"
          ></Gauge>
          <p>CTR</p>
        </div>
      </div>
    </Card>
  )
}

export default Average
