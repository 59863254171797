import {
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from '@material-ui/core'

import WindowIcon from '@material-ui/icons/Window'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { history } from 'src/history'
import { getBrandsDataThunk } from 'src/redux/slices/brandSlice'
import {
  setBrandId,
  setContentName,
  setObjectFocusType,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import './SequenceBrand.scss'
import { AutoAwesome } from '@material-ui/icons'
import { sequencePollGetAvailableImageObjectFocusTypes } from '../../api/services'
import { PairMediaStorageItemObjectAggregatedTypeString } from '../../api/types'
import Button from '../UI/Button/Button'
import { useHistory } from 'react-router'

interface Props {
  sequencePollId?: string
}

const SequenceBrand = ({ sequencePollId }: Props) => {
  const dispatch = useDispatch()
  const router = useHistory()

  const { contentName, brandId } = useSelector(
    (state: RootState) => state.sequenceReducer,
  )
  const brands = useSelector((state: RootState) => state.brandsReducer.brands)

  const sequencePollObjectFocusType = useSelector(
    (state: RootState) => state.sequenceReducer?.ai?.cardFocusedObjectType,
  )

  const canUpdateAISettings = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.updateAISettings,
  )

  const [availableObjectFocusTypes, setAvailableObjectFocusTypes] = useState<
    PairMediaStorageItemObjectAggregatedTypeString[]
  >([])

  const getAvailableObjectFocusTypes = async () => {
    const res = await sequencePollGetAvailableImageObjectFocusTypes()
    setAvailableObjectFocusTypes(res.data)
  }

  useEffect(() => {
    getAvailableObjectFocusTypes()
  }, [dispatch])

  useEffect(() => {
    if (!brandId && brands && brands.length > 0) {
      dispatch(setBrandId(brands[0].id))
    }
  }, [brandId, brands])

  const changeContentName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    dispatch(setContentName(e.target.value))
  }

  useEffect(() => {
    dispatch(getBrandsDataThunk())
  }, [dispatch])

  return (
    <div>
      {!sequencePollId && (
        <div className="editor-buttons">
          <div className="home-buttons-wrapper">
            <Button
              onClick={() => {
                router.push('/content/experiences?tab=Templates')
              }}
            >
              <span style={{ verticalAlign: 'super', marginRight: '1em' }}>
                Use a Template
              </span>
              <WindowIcon />
            </Button>
          </div>
        </div>
      )}
      <div className="step-one-wrapper">
        <FormControl className="step-one-brand-form">
          <TextField
            value={contentName || ''}
            onChange={e => changeContentName(e)}
            label="Name your content*"
          />
          <TextField
            select
            onChange={e => {
              dispatch(setBrandId(e.target.value))
            }}
            disabled={brands.length === 0}
            value={brandId || 'default'}
            label="Brand *"
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="default">Select brand*</MenuItem>
            {Array.isArray(brands) && brands?.length !== 0 ? (
              brands?.map((brand, i) => {
                return (
                  <MenuItem key={i} value={brand.id}>
                    {brand.name}
                  </MenuItem>
                )
              })
            ) : (
              <option>No brands created</option>
            )}
          </TextField>

          <FormHelperText>
            <span>
              Add or change your brand{' '}
              <b
                className="clickable-elem"
                onClick={() => history.push('/brands')}
              >
                here &#62;&#62;
              </b>
            </span>
          </FormHelperText>

          {canUpdateAISettings && (
            <>
              <Divider />
              <h4 style={{ marginBottom: '0px' }}>
                <AutoAwesome sx={{ color: '#FF00EC' }} />
                <span className="ai-features-heading">AI features</span>
              </h4>
              <TextField
                select
                onChange={e => {
                  dispatch(setObjectFocusType({ focusType: e.target.value }))
                }}
                disabled={availableObjectFocusTypes.length === 0}
                value={sequencePollObjectFocusType}
                label="Select focus type"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {Array.isArray(availableObjectFocusTypes) &&
                  availableObjectFocusTypes?.length !== 0 &&
                  availableObjectFocusTypes?.map((focusType, i) => {
                    return (
                      <MenuItem key={i} value={focusType.first}>
                        {focusType.second}
                      </MenuItem>
                    )
                  })}
              </TextField>
              <FormHelperText>
                <span>
                  We use machine learning to auto-identify objects and faces
                  within uploaded images, here you can select which things to
                  focus within the images displayed to end users.
                </span>
              </FormHelperText>
            </>
          )}
        </FormControl>
      </div>
    </div>
  )
}

export default SequenceBrand
