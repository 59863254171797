import { useAuth0 } from '@auth0/auth0-react'
import { Box, CircularProgress, Container } from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Router, Switch } from 'react-router-dom'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import { IS_PRODUCTION, routes } from 'src/constants'
import { setFonts } from 'src/redux/slices/helperSlice'
import { RootState } from 'src/redux/store'
import { request } from 'src/services/apiServices'
import Brands from './components/Brands/Brands'
import Home from './components/Home/Home'
import SequencePollList from './components/SequencePollList/SequencePollList'
import SidebarLayout from './components/SidebarLayout/SidebarLayout'
import { history } from './history'
import ProtectedRoute from './pages/ProtectedRoute'
import { setAccessToken, setEmailVerification } from './redux/slices/authSlice'
import { getPermissionsThunk } from './redux/slices/organizationSlice'
import './styles/global.scss'
import ThemeProvider from './theme/ThemeProvider'
import UserSettings from './components/Account/UserSettings/UserSettings'
import OrganizationData from './components/Account/OrganizationData/OrganizationData'
import OrganizationMembers from './components/Account/OrganizationMembers/OrganizationMembers'
import Integration from './components/Integration/Integration'
import CreatorsList from './components/Creators/CreatorsList'
import Dashboard from './pages/Dashboard'
import Library from './components/Library/Library'
import Subscription from './components/Account/Subscription/Subscription'
import ShopifyClaim from './components/Integration/ShopifyClaim'
import ConfirmSubscription from './components/Integration/ConfirmSubscription'

const MainWrapper = experimentalStyled(Box)(
  () => `
    flex: 1;
    display: flex;
    height: 100%;
`,
)

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const script = document.createElement('script')
    if (IS_PRODUCTION) {
      script.src =
        'https://api.contester.net/api/public/scripts/experienceCollections/5d9019a0-7c45-47a6-82a6-cc1f3ca2f37d/js?spa=true'
    } else {
      script.src =
        'https://staging-api.contester.net/api/public/scripts/experienceCollections/6c2afb3d-0681-4fc3-aba7-836a9685a20f/js?spa=true'
    }
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const {
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0()
  const fonts = useSelector((state: RootState) => state.helperReducer.fonts)
  const [dataLoading, setDataLoading] = useState(true)
  const accessToken = useSelector((state: RootState) => state.authReducer.token)

  const permissions = useSelector(
    (state: RootState) => state.organizationReducer.permissions,
  )

  const initState = async () => {
    let res = await request({
      method: 'GET',
      url: routes.GET_FONTS,
    })

    dispatch(setFonts([...res?.fonts]))
    await dispatch(getPermissionsThunk())

    setDataLoading(false)
  }

  useEffect(() => {
    ;(async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
        })
      }
    })()
  }, [isLoading])

  useEffect(() => {
    dispatch(setEmailVerification(user?.email_verified))
  }, [user])

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      initState()
    }
  }, [isAuthenticated, accessToken])

  const setUserToken = async () => {
    let token = await getAccessTokenSilently()
    dispatch(setAccessToken({ token }))
  }

  if (isAuthenticated) {
    setUserToken()
  }

  if (isLoading || !isAuthenticated || !accessToken || dataLoading) {
    return <CircularProgress className="circular-progress-center" />
  }

  return (
    <div>
      <Router history={history}>
        {fonts.map(font => {
          return (
            font.url && <style key={font.url}>@import url('{font.url}');</style>
          )
        })}
        <ThemeProvider>
          <MainWrapper>
            <SidebarLayout>
              <div className="main-content">
                <Switch>
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.analytics === true}
                    path="/summary"
                    component={() => <Dashboard />}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.experience?.createExperience === true
                    }
                    path="/create-content"
                    component={Home}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.user ||
                      permissions?.menu?.creatorProfile
                    }
                    path="/account/user-settings"
                    component={UserSettings}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.organization === true}
                    path="/account/organization"
                    component={OrganizationData}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.team === true}
                    path="/account/team"
                    component={OrganizationMembers}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.billing === true}
                    path="/account/billing/subscription"
                    component={Subscription}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.integration === true}
                    path="/account/integration"
                    component={Integration}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.liveStreams === true}
                    path="/content/library"
                    component={Library}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.brands === true}
                    path="/content/brands"
                    component={Brands}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.experiences === true}
                    path="/content/experiences"
                    component={SequencePollList}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.creatorAnalytics === true
                    }
                    path="/creators/overview"
                    component={() => (
                      <Container maxWidth="lg">creators/overview</Container>
                    )}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.creatorAnalytics === true
                    }
                    path="/creators/list"
                    component={CreatorsList}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.creatorAnalytics === true
                    }
                    path="/creators/experiences"
                    component={() => (
                      <Container maxWidth="lg">creators/experiences</Container>
                    )}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.overall.hasAccess === true}
                    path="/integration/shopify/claim"
                    component={ShopifyClaim}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.overall.hasAccess === true}
                    path="/integration/confirmSubscription"
                    component={ConfirmSubscription}
                  />
                  <Redirect from="/" to="/summary" />
                </Switch>
              </div>
            </SidebarLayout>
          </MainWrapper>
        </ThemeProvider>
      </Router>
    </div>
  )
}

export default App
