import { Auth0Provider } from '@auth0/auth0-react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import store from './redux/store'
import { Provider } from 'react-redux'
import App from './App'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'nprogress/nprogress.css'
import { ToastContainer } from 'react-toastify'
import {
  IS_PRODUCTION,
  REACT_APP_DEV_AUDIENCE,
  REACT_APP_DEV_CLIENT_ID,
  REACT_APP_PROD_AUDIENCE,
  REACT_APP_PROD_CLIENT_ID,
} from './constants'
import { SidebarProvider } from './contexts/SidebarContext'
import { history } from './history'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'

Sentry.init({
  dsn: 'https://59e36c88d4904c7fbddf8fdfaf560a58@o590637.ingest.sentry.io/5994977',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const redirectCallback = appState => {
  history.replace(
    appState && appState.returnTo ? appState.returnTo : window.location.href,
  )
}
// @ts-ignore
ReactDOM.render(
  <>
    <Auth0Provider
      domain="contester.eu.auth0.com"
      clientId={
        (IS_PRODUCTION ? REACT_APP_PROD_CLIENT_ID : REACT_APP_DEV_CLIENT_ID) ||
        ''
      }
      redirectUri={window.location.origin}
      audience={
        IS_PRODUCTION ? REACT_APP_PROD_AUDIENCE : REACT_APP_DEV_AUDIENCE
      }
      onRedirectCallback={redirectCallback}
      scopes="read:default"
    >
      <HelmetProvider>
        <ToastContainer position="top-center" />
        <Provider store={store}>
          <SidebarProvider>
            <App />
          </SidebarProvider>
        </Provider>
      </HelmetProvider>
    </Auth0Provider>
  </>,
  document.getElementById('contester-creator-root'),
)
// serviceWorkerRegistration.register();
