import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  lighten,
  List,
  ListItem,
  Menu,
  MenuItem,
  Popover,
  Slide,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone'
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone'
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useContext,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SidebarContext } from 'src/contexts/SidebarContext'

import { RootState } from 'src/redux/store'
import { handleThunkPromiseToast } from 'src/utils/toast.config'
import { switchOrganizationThunk } from '../../../../redux/slices/organizationSlice'

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
)

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
)

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
)

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
)

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
)

const DialogWrapper = experimentalStyled(Dialog)(
  () => `
        .MuiDialog-paper {
          overflow: visible;
        }
  `,
)

const AvatarError = experimentalStyled(Avatar)(
  ({ theme }) => `
        background-color: ${theme.colors.error.lighter};
        color: ${theme.colors.error.main};
        width: ${theme.spacing(12)};
        height: ${theme.spacing(12)};
  
        .MuiSvgIcon-root {
          font-size: ${theme.typography.pxToRem(45)};
        }
  `,
)

const ButtonError = experimentalStyled(Button)(
  ({ theme }) => `
       background: ${theme.colors.primary.main};
       color: ${theme.palette.primary.contrastText};
  
       &:hover {
          background: ${theme.colors.primary.dark};
       }
      `,
)

const OrganizationsDropdown = experimentalStyled(Button)(
  () => `
  min-width: 125px;
`,
)

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

function HeaderUserbox() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
  const dispatch = useDispatch()

  const activeOrganizationData = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  const availableOrganizations = useSelector(
    (state: RootState) => state.organizationReducer.availableOrganizations,
  )
  const history = useHistory()

  const { user, loginWithRedirect, logout } = useAuth0()

  const ref = useRef<any>(null)
  const actionRef = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const [openSwitchOrganization, setOpenSwitchOrganization] =
    useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleSwitch = async (value: string, name?: string) => {
    await handleThunkPromiseToast(
      () => dispatch(switchOrganizationThunk(value)),
      `Successfully switched to organization ${name}`,
      'success',
      'Failed to switch organization',
      'error',
    )
    setOpenSwitchOrganization(false)
    window.location.reload()
    // dispatch(
    //   getSequencePollsDataThunk({
    //     brandId: '',
    //     search: '',
    //     status: 'Active',
    //   }),
    // );
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.picture} />
        <UserBoxText sx={{ display: { md: 'block', xs: 'none' } }}>
          <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
          <UserBoxDescription variant="body2">{user.email}</UserBoxDescription>
        </UserBoxText>
        <ExpandMoreTwoToneIcon
          sx={{ display: { sm: 'block', xs: 'none' }, ml: 1 }}
        />
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.picture} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem>
            <OrganizationsDropdown
              sx={{ mr: 1 }}
              variant="outlined"
              color="secondary"
              ref={actionRef}
              onClick={() => setOpenSwitchOrganization(true)}
              endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
            >
              Switch organization
            </OrganizationsDropdown>
            <Menu
              anchorEl={actionRef.current}
              onClose={() => setOpenSwitchOrganization(false)}
              open={openSwitchOrganization}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {availableOrganizations.map(_org => (
                <MenuItem
                  selected={activeOrganizationData?.id === _org.id}
                  key={_org.id}
                  onClick={() => {
                    handleSwitch(_org.id, _org.name)
                    setOpenSwitchOrganization(false)
                  }}
                >
                  {_org.name}
                </MenuItem>
              ))}
            </Menu>
            {/* <Button
              onClick={() => {
                dispatch(createOrganizationThunk());
              }}
            >
              Create organization
            </Button> */}
          </ListItem>
        </List>
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              logout({ returnTo: window.location.origin })
            }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
      <Tooltip
        sx={{ display: { lg: 'none', xs: 'block' } }}
        arrow
        title="Search"
      >
        <IconButton color="primary" onClick={toggleSidebar}>
          {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
        </IconButton>
      </Tooltip>
    </>
  )
}

export default HeaderUserbox
