import { Card } from '@material-ui/core'
import { HighlightOff } from '@material-ui/icons'
import { SetStateAction } from 'react'
import { useHistory } from 'react-router'
import { TopPerformingExperience } from '../../../api/types'
import { TableType } from '../../../pages/Dashboard'
import './SummaryTable.css'

interface Props {
  experiences: TopPerformingExperience[]
  rowsToShow: number
  setShowFullList: React.Dispatch<SetStateAction<TableType>>
  withHeading: boolean
}

const ExperienceTable = ({
  experiences,
  rowsToShow,
  setShowFullList,
  withHeading,
}: Props) => {
  const router = useHistory()

  const handleSequencePollClick = (id: string) => {
    router.push('/content/experiences?id=' + id)
  }

  return (
    <div className="summary-table-wrapper">
      <Card className="summary-table">
        <table>
          <thead
            className={`summary-table-header ${
              !withHeading && 'sticky-header'
            }`}
          >
            {!withHeading && (
              <p
                className="table-close"
                onClick={() => setShowFullList(undefined)}
              >
                <HighlightOff />
              </p>
            )}
            <tr>
              <th>Top performing experiences</th>
              <th>Visitors</th>
              <th>Avg Time Spent</th>
              <th>CTR</th>
            </tr>
          </thead>
          <tbody>
            {experiences?.slice(0, rowsToShow).map(experience => {
              return (
                <tr key={experience?.id}>
                  <td
                    className="summary-table-clickable"
                    onClick={() => handleSequencePollClick(experience?.id)}
                  >
                    <img className="table-avatar" src={experience.brandLogo} />
                    {experience?.brandName} / {experience?.campaignName}
                  </td>
                  <td>{experience?.statistics.visitors?.displayValue}</td>
                  <td>
                    {experience?.statistics.averageTimeSpent?.displayValue}
                  </td>
                  <td>
                    {experience?.statistics.clickThroughRate?.displayValue}
                  </td>
                </tr>
              )
            })}
          </tbody>
          {withHeading && (
            <tfoot>
              <tr className="table-show-more">
                <td colSpan={5} onClick={() => setShowFullList('EXPERIENCE')}>
                  Show more
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </Card>
    </div>
  )
}

export default ExperienceTable
