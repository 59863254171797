import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import PropTypes from 'prop-types'
import React, {
  ChangeEvent,
  FC,
  forwardRef,
  ReactElement,
  Ref,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'src/redux/store'
import {
  acceptUserInvitationThunk,
  declineUserInvitationThunk,
  getActiveOrganizationThunk,
  getUserInvitationsThunk,
  switchOrganizationThunk,
  getAvailableOrganizationsThunk,
} from '../../../redux/slices/organizationSlice'
import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import { OrganizationPendingInvitation } from '../../../api/types'

const DialogWrapper = experimentalStyled(Dialog)(
  () => `
          .MuiDialog-paper {
            overflow: visible;
          }
    `,
)

const OrganizationsDropdown = experimentalStyled(Button)(
  () => `
  min-width: 125px;
  max-width: 225px;
`,
)

const InvitationsOrganizationWrapper = experimentalStyled(Box)(
  () => `
  background-color: #add4e6;
`,
)

const AvatarError = experimentalStyled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.error.lighter};
          color: ${theme.colors.error.main};
          width: ${theme.spacing(12)};
          height: ${theme.spacing(12)};
    
          .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(45)};
          }
    `,
)

const ButtonError = experimentalStyled(Button)(
  ({ theme }) => `
         background: ${theme.colors.error.main};
         color: ${theme.palette.error.contrastText};
    
         &:hover {
            background: ${theme.colors.error.dark};
         }
        `,
)

interface ResultsProps {
  invitations?: OrganizationPendingInvitation[]
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const applyInvitationsPagination = (
  invitations?: OrganizationPendingInvitation[],
  page?: number,
  limit?: number,
): OrganizationPendingInvitation[] => {
  return invitations?.slice(page * limit, page * limit + limit)
}

const UserInvitations: FC<ResultsProps> = ({ invitations, ...rest }) => {
  //   const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()

  const activeOrganizationData = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  const availableOrganizations = useSelector(
    (state: RootState) => state.organizationReducer.availableOrganizations,
  )

  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [cancelInvitationId, setCancelInvitationId] = useState<string>('')
  const actionRef = useRef<any>(null)
  const [openSwitchOrganization, setOpenSwitchOrganization] =
    useState<boolean>(false)
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const { user } = useAuth0()

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  const paginatedInvitations = applyInvitationsPagination(
    invitations,
    page,
    limit,
  )

  const [openConfirmCancel, setOpenConfirmCancel] = useState(false)

  const closeConfirmCancel = () => {
    setOpenConfirmCancel(false)
    setCancelInvitationId('')
  }

  const handleSetCancelInvitationId = id => (event: React.MouseEvent) => {
    setOpenConfirmCancel(true)
    setCancelInvitationId(id)
  }

  const handleCancelInvitation = id => async (event: React.MouseEvent) => {
    await dispatch(declineUserInvitationThunk(id))
    setOpenConfirmCancel(false)
    setCancelInvitationId('')
    await dispatch(getUserInvitationsThunk())
  }

  const handleAcceptInvitation = id => async (event: React.MouseEvent) => {
    await dispatch(acceptUserInvitationThunk(id))
    await dispatch(getUserInvitationsThunk())
    await dispatch(getAvailableOrganizationsThunk())
  }

  const handleSwitch = async (value: string) => {
    await dispatch(switchOrganizationThunk(value))
    setOpenSwitchOrganization(false)
    await dispatch(getActiveOrganizationThunk())
    window.location.reload()
    // dispatch(
    //   getSequencePollsDataThunk({
    //     brandId: '',
    //     search: '',
    //     status: 'Active',
    //   }),
    // );
  }

  return (
    <>
      <Card>
        {paginatedInvitations.length > 0 && (
          <InvitationsOrganizationWrapper
            display="flex"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            p={2}
          >
            <Typography
              variant="h4"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              You have been invited to join one or more organizations
            </Typography>
          </InvitationsOrganizationWrapper>
        )}
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'center', sm: 'space-between' }}
          p={2}
        >
          <OrganizationsDropdown
            sx={{ mr: 1 }}
            variant="outlined"
            color="secondary"
            ref={actionRef}
            onClick={() => setOpenSwitchOrganization(true)}
            endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
          >
            Switch organization
          </OrganizationsDropdown>
          {/* <Button
            onClick={() => {
              dispatch(createOrganizationThunk());
            }}
          >
            Create organization
          </Button> */}
          <Menu
            anchorEl={actionRef.current}
            onClose={() => setOpenSwitchOrganization(false)}
            open={openSwitchOrganization}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {availableOrganizations?.map(_org => (
              <MenuItem
                selected={activeOrganizationData?.id === _org.id}
                key={_org.id}
                onClick={() => {
                  handleSwitch(_org.id)
                  setOpenSwitchOrganization(false)
                }}
              >
                {_org.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {paginatedInvitations?.length === 0 ? (
          <>
            <Typography
              sx={{ py: 3 }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              Welcome to {activeOrganizationData?.name}
            </Typography>
            <Typography
              sx={{ py: 3 }}
              variant="h4"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              Now you can switch between organizations by clicking on your email
              on the top right corner
            </Typography>
          </>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedInvitations?.map(invitation => {
                    return (
                      <TableRow hover key={invitation.id}>
                        <TableCell>
                          <Typography variant="h5">
                            Invitation to '{invitation.organization.name}'
                            organization
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title="Accept" arrow>
                              <IconButton
                                onClick={handleAcceptInvitation(invitation.id)}
                                color="primary"
                              >
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Decline" arrow>
                              <IconButton
                                onClick={handleSetCancelInvitationId(
                                  invitation.id,
                                )}
                                color="primary"
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={invitations.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </>
        )}
      </Card>
      <DialogWrapper
        open={openConfirmCancel}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmCancel}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            Are you sure you want to decline this invitation?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmCancel}
            >
              Cancel
            </Button>
            <ButtonError
              onClick={handleCancelInvitation(cancelInvitationId)}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              Decline
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  )
}

UserInvitations.propTypes = {
  invitations: PropTypes.array.isRequired,
}

UserInvitations.defaultProps = {
  invitations: [],
}

export default UserInvitations
