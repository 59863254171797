import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBrandsDataThunk } from '../../../redux/slices/brandSlice'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'
import { RootState } from 'src/redux/store'
import { putImageMedia, saveImage } from 'src/services/api/mediastorage'
import BrandDelete from '../BrandDelete/BrandDelete'
import BrandDialog from '../BrandDialog/BrandDialog'

const CardIndicatorWrapper = experimentalStyled(Card)(
  () => `
    position: relative;
    
    .MuiCard-indicator {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 5px;
    }
`,
)

const GridWrapper = experimentalStyled(Grid)(
  () => `
    .MuiGrid-container{
        overflow: hidden;
    }

    .MuiGrid-root{
        margin-bottom: 5px;
    }
`,
)

const TitleWrapper = experimentalStyled(Typography)(
  () => `
    overflow-wrap: break-word;
  `,
)

const AvatarAddWrapper = experimentalStyled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
    `,
)

const CardAddAction = experimentalStyled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        
        .MuiCardActionArea-root {
          height: 273px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[100]};
        }
`,
)

function BrandItems() {
  const { user } = useAuth0()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [brandDeleteId, setBrandDeleteId] = useState<string>('')
  const [logoFileSelected, setLogoFileSelected] = useState<File>()
  const [posterFileSelected, setPosterFileSelected] = useState<File>()
  const [logoUrl, setLogoUrl] = useState<string>('')
  const [posterUrl, setPosterUrl] = useState<string>('')
  const [logoId, setLogoId] = useState<string>('')
  const [posterId, setPosterId] = useState<string>('')

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    title: '',
    description: '',
    websiteUrl: '',
    websiteUrlTitle: '',
    logoMediaStorageItemId: '',
    posterMediaStorageItemId: '',
    submit: null,
  })

  const canCreateBrand = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.brand.createBrand,
  )

  const canUpdateBrand = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.brand.updateBrand,
  )

  const canDeleteBrand = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.brand.deleteBrand,
  )

  const brandsData = useSelector(
    (state: RootState) => state.brandsReducer.brands,
  )

  const activeOrganization = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  const handleCreateBrandOpen = () => {
    setFormData({
      id: '',
      name: '',
      title: '',
      description: '',
      websiteUrl: '',
      websiteUrlTitle: '',
      logoMediaStorageItemId: '',
      posterMediaStorageItemId: '',
      submit: null,
    })
    setLogoUrl('')
    setPosterUrl('')
    setOpen(true)
  }

  const handleCreateBrandClose = () => {
    setOpen(false)
  }

  const handleDeleteDialogOpen = brandId => {
    setBrandDeleteId(brandId)
    setOpenDelete(true)
  }

  const handleDeleteBrandClose = () => {
    setOpenDelete(false)
  }

  const handleEditBrandDialog = data => {
    setFormData({
      id: data?.id,
      name: data?.name,
      title: data?.title,
      description: data?.description,
      websiteUrl: data?.websiteUrl,
      websiteUrlTitle: data?.websiteUrlTitle,
      logoMediaStorageItemId: data?.logoMediaStorageItemId,
      posterMediaStorageItemId: data?.posterMediaStorageItemId,
      submit: null,
    })

    setLogoId(data?.logo?.id)
    setPosterId(data?.poster?.id)
    setLogoUrl(data?.logo?.location)
    setPosterUrl(data?.poster?.location)
    setOpen(true)
  }

  const uploadLogoFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const logoFile = event.target.files

    if (!logoFile) return

    if (event.target.files.length !== 0) {
      setLogoUrl(URL.createObjectURL(logoFile[0]))
    }
    setLogoFileSelected(logoFile[0])

    if (event?.target?.files && event?.target.files[0]) {
      let fileData = new FormData()
      fileData.append('file', event?.target?.files[0])
      putImageMedia().then(data => {
        saveImage(fileData, data.id).then(response => {
          setLogoId(response.id)
          setLogoUrl(response?.location)
        })
      })
    }
  }

  const uploadPosterFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const posterFile = event.target.files

    if (!posterFile) return

    if (event.target.files.length !== 0) {
      setPosterUrl(URL.createObjectURL(posterFile[0]))
    }
    setPosterFileSelected(posterFile[0])

    if (event?.target?.files && event?.target.files[0]) {
      let fileData = new FormData()
      fileData.append('file', event?.target?.files[0])
      putImageMedia().then(data => {
        console.log('SAVING IMAGE AND', data)
        saveImage(fileData, data.id).then(response => {
          console.log('SAVING IMAGE AND', response)
          setPosterId(response?.id)
          setPosterUrl(response?.location)
        })
      })
    }
  }

  // if (user?.email_verified === false) {
  //   return <EmailVerification />;
  // }

  useEffect(() => {
    dispatch(getBrandsDataThunk())
  }, [dispatch, activeOrganization])

  return (
    <>
      <Container>
        <GridWrapper container spacing={3}>
          {brandsData.map(brand => {
            return (
              <Grid key={brand.id} item xs={12} sm={6} md={5} lg={4}>
                <CardIndicatorWrapper>
                  <Box
                    className="MuiCard-indicator"
                    sx={{ background: `${theme.colors.info.main}` }}
                  />
                  <CardContent
                    sx={{ pb: 4, pt: 7, px: 3, textAlign: 'center' }}
                  >
                    <img
                      style={{ height: '80px' }}
                      src={brand.logo?.location}
                    />
                    <TitleWrapper variant="h3" sx={{ pt: 2 }} gutterBottom>
                      {brand.title}
                    </TitleWrapper>
                    {canUpdateBrand && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={async (
                          event: React.MouseEvent<HTMLElement>,
                        ) => {
                          await handleEditBrandDialog(brand)
                        }}
                        sx={{ mt: 3 }}
                      >
                        Edit
                      </Button>
                    )}
                    {canDeleteBrand && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={async (
                          event: React.MouseEvent<HTMLElement>,
                        ) => {
                          await handleDeleteDialogOpen(brand.id)
                        }}
                        sx={{ mt: 3, ml: 3 }}
                      >
                        Delete
                      </Button>
                    )}
                  </CardContent>
                </CardIndicatorWrapper>
              </Grid>
            )
          })}

          {canCreateBrand && (
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <CardIndicatorWrapper>
                <Tooltip arrow title="Click to add a new brand">
                  <CardAddAction onClick={handleCreateBrandOpen}>
                    <CardActionArea sx={{ px: 1 }}>
                      <CardContent>
                        <AvatarAddWrapper>
                          <AddTwoToneIcon fontSize="large" />
                        </AvatarAddWrapper>
                      </CardContent>
                    </CardActionArea>
                  </CardAddAction>
                </Tooltip>
              </CardIndicatorWrapper>
            </Grid>
          )}
          <BrandDialog
            open={open}
            uploadLogoFile={uploadLogoFile}
            uploadPosterFile={uploadPosterFile}
            closeHandler={handleCreateBrandClose}
            formData={formData}
            logoId={logoId}
            posterId={posterId}
            logoUrl={logoUrl}
            posterUrl={posterUrl}
          />
          <BrandDelete
            openDelete={openDelete}
            brandDeleteId={brandDeleteId}
            closeHandler={handleDeleteBrandClose}
          />
        </GridWrapper>
      </Container>
    </>
  )
}

export default BrandItems
