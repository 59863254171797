import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  setCreatorTemplate,
  setGlobalTemplate,
  setOrganizationTemplate,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import '../WidgetSettings.css'
import React from 'react'

const WidgetSettingsTemplate = () => {
  const dispatch = useDispatch()
  const globalTemplate = useSelector(
    (state: RootState) => state.sequenceReducer.template?.isGlobalTemplate,
  )

  const isGlobalTemplateEditable = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.templateSettings.global,
  )
  const organizationTemplate = useSelector(
    (state: RootState) =>
      state.sequenceReducer.template?.isOrganizationTemplate,
  )

  const isOrganizationTemplateEditable = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.templateSettings
        .organization,
  )
  const creatorTemplate = useSelector(
    (state: RootState) => state.sequenceReducer.template?.isCreatorTemplate,
  )

  const isCreatorTemplateEditable = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.templateSettings.creator,
  )

  const anyEditable =
    isGlobalTemplateEditable ||
    isOrganizationTemplateEditable ||
    isCreatorTemplateEditable

  return (
    anyEditable && (
      <>
        <Divider variant="middle" />

        <FormControl className="template-settings-wrapper">
          <p>
            <b>Template settings</b>
          </p>
          {isGlobalTemplateEditable && (
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Is global template"
              value={globalTemplate}
              checked={globalTemplate}
              onChange={(event, value) =>
                dispatch(setGlobalTemplate({ isGlobalTemplate: value }))
              }
            />
          )}
          {isOrganizationTemplateEditable && (
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Is organization template"
              value={organizationTemplate}
              checked={organizationTemplate}
              onChange={(event, value) =>
                dispatch(
                  setOrganizationTemplate({ isOrganizationTemplate: value }),
                )
              }
            />
          )}
          {isCreatorTemplateEditable && (
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Is creator template"
              value={creatorTemplate}
              checked={creatorTemplate}
              onChange={(event, value) =>
                dispatch(setCreatorTemplate({ isCreatorTemplate: value }))
              }
            />
          )}
        </FormControl>
      </>
    )
  )
}

export default WidgetSettingsTemplate
