import { Card, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { toast } from 'react-toastify'
import ContentWrapper from 'src/components/ContentWrapper/ContentWrapper'
import './Integration.css'
import { IS_PRODUCTION } from '../../constants'
import { experienceCollectionGetOrganizationOverview } from '../../api/services'
import { ExperienceCollectionData } from '../../api/types'

const sequenceHtmlCode = (collectionId: string) =>
  `
<script>

  document.addEventListener("DOMContentLoaded", function () {
    if (!window.contesterFloatingWidget) {
      var script = document.createElement("script");
      script.type = "text/javascript"
      script.src = "${
        IS_PRODUCTION
          ? 'https://w.contester.net/index.js'
          : 'https://staging-w.contester.net/index.js'
      }";
  
      document.body.appendChild(script);
  
      window.contesterFloatingWidget = {
        experienceCollectionId: "${collectionId}",
      }
    }
  
  }, false);

</script>
`

const sequenceScriptLink = (collectionId: string) =>
  `
<script type="text/javascript" defer="defer" src="${
    IS_PRODUCTION
      ? 'https://api.contester.net'
      : 'https://staging-api.contester.net'
  }/api/public/scripts/experienceCollections/${collectionId}/js"></script>
`

const Integration = () => {
  const [experienceCollections, setExperienceCollections] = useState<
    ExperienceCollectionData[]
  >([])
  const [copyButtons, setCopyButtons] = useState({
    a: 'Copy',
    b: 'Copy',
  })

  const initData = async () => {
    try {
      let res = await experienceCollectionGetOrganizationOverview()
      setExperienceCollections(res.data)
    } catch (err) {
      // TODO - handle error
    }
  }

  let copy = (value: string) => {
    setCopyButtons({
      ...copyButtons,
      a: 'Copied',
    })
    toast.info('Code is copied to the clipboard', {
      autoClose: 1500,
    })
    navigator.clipboard.writeText(value)
    setTimeout(() => {
      setCopyButtons({
        ...copyButtons,
        a: 'Copy',
      })
    }, 2000)
  }

  useEffect(() => {
    initData()
  }, [])
  return (
    <ContentWrapper title="Integration">
      <div className="root">
        <Card className="card-integration">
          <Typography variant="h4">
            Add the organization script to your domain{' '}
            <span
              className="learn-more"
              onClick={() =>
                window.open('https://docs.contester.net/', '_blank')
              }
            >
              Learn more &gt;
            </span>
          </Typography>

          <p>
            Add a link to script directly to either <b>head</b> or <b>body</b>{' '}
            element of your page:
          </p>
          <div className="code-wrapper">
            <SyntaxHighlighter language="html" style={docco}>
              {sequenceScriptLink(experienceCollections[0]?.id)}
            </SyntaxHighlighter>
            <button
              onClick={() =>
                copy(sequenceScriptLink(experienceCollections[0]?.id))
              }
            >
              {copyButtons.a}
            </button>
          </div>
        </Card>
        {/*<Card className="card-integration">*/}
        {/*  <div>*/}

        {/*    <p>Or copy/paste this code to either <b>head</b> or <b>body</b> element of your page:</p>*/}
        {/*    <div className="code-wrapper">*/}
        {/*      <SyntaxHighlighter language="javascript" style={docco}>*/}
        {/*        {sequenceHtmlCode(experienceCollections?.[0]?.id)}*/}
        {/*      </SyntaxHighlighter>*/}
        {/*      <button onClick={() => copy(sequenceHtmlCode(experienceCollections?.[0]?.id)) }>{copyButtons.a}</button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Card>*/}
        <Card className="card-integration">
          <div className="gtm-integration">
            <p>
              Or add the code via Google Tag Manager without changing your
              website code{' '}
              <span
                className="learn-more"
                onClick={() =>
                  window.open('https://docs.contester.net/', '_blank')
                }
              >
                Learn more &gt;
              </span>
            </p>
            <div className="gtm-code">
              <p>Copy this ID to your Contester Collection ID field</p>
              <div className="gtm-code-inner">
                <SyntaxHighlighter language="javascript" style={docco}>
                  {`"${experienceCollections[0]?.id}"`}
                </SyntaxHighlighter>
                <button onClick={() => copy(experienceCollections[0]?.id)}>
                  {copyButtons.a}
                </button>
              </div>
            </div>
          </div>
        </Card>
        {/* Removed for now - uncomment when we implement GA */}
        {/* <Card className="card-integration">
        <Typography variant="h4">Google analytics</Typography>
        <p>
          Authorize your Google account to receive the analytics on your Google
          analytics dashboards
        </p>
        <Button variant="contained" disabled>
          Coming soon...
        </Button>
      </Card> */}
      </div>
    </ContentWrapper>
  )
}

export default Integration
