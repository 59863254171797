import creatorSlice from 'src/redux/slices/creatorSlice'
import helperSlice from 'src/redux/slices/helperSlice'
import authSlice from './slices/authSlice'
import billingSlice from './slices/billingSlice'
import brandSlice from './slices/brandSlice'
import mediaSlice from './slices/mediaSlice'
import modalSlice from './slices/modalSlice'
import organizationSlice from './slices/organizationSlice'
import sequencePollSlice from './slices/sequencePollSlice'
import sequenceSlice from './slices/sequenceSlice'
import liveStreamsSlice from './slices/liveStreamsSlice'

export const rootReducer = {
  authReducer: authSlice,
  sequenceReducer: sequenceSlice,
  brandsReducer: brandSlice,
  sequencePollReducer: sequencePollSlice,
  mediaReducer: mediaSlice,
  organizationReducer: organizationSlice,
  modalReducer: modalSlice,
  billingReducer: billingSlice,
  creatorReducer: creatorSlice,
  helperReducer: helperSlice,
  liveStreamsReducer: liveStreamsSlice,
}
