import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeHeaderStyles, ITreeItem } from 'src/redux/slices/sequenceSlice'

interface Props {
  currentCard: ITreeItem
  styleProp: string
}

const ColorPickerHeader: React.FC<Props> = ({ currentCard, styleProp }) => {
  const dispatch = useDispatch()
  const [currColor, setCurrColor] = useState(
    currentCard.heading?.['styles']?.[styleProp],
  )
  const [colorQuery, setColorQuery] = useState(
    currentCard.heading?.['styles']?.[styleProp],
  )

  useEffect(() => {
    let color = currentCard.heading?.['styles']?.[styleProp]
    if (color) setCurrColor(color)
    else setCurrColor(styleProp === 'backgroundColor' ? '#000000' : '#ffffff')
  }, [currentCard])

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        changeHeaderStyles({
          [styleProp]: colorQuery,
        }),
      )
    }, 200)
    return () => clearTimeout(timeout)
  }, [colorQuery, dispatch])

  return (
    <input
      type="color"
      value={currColor || '#ffffff'}
      onChange={e => {
        setCurrColor(e.target.value)
        setColorQuery(e.target.value)
      }}
    />
  )
}

export default ColorPickerHeader
