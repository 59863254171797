import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { removeCreatorThunk } from 'src/redux/slices/organizationSlice'
import { RootState } from 'src/redux/store'

const CreatorsTable = () => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const dispatch = useDispatch()
  const teamPermission = useSelector(
    (state: RootState) => state.organizationReducer.permissions.team,
  )
  const creators = useSelector(
    (state: RootState) => state.organizationReducer.creators,
  )

  const handleCreatorDelete = (id: string) => {
    dispatch(removeCreatorThunk(id))
  }
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creators?.map(creator => {
              return (
                <TableRow key={creator.id} style={{ height: 80 }}>
                  <TableCell>
                    <b>{creator.email}</b>
                  </TableCell>
                  <TableCell align="center">
                    {teamPermission.removeCreator && (
                      <Typography noWrap>
                        <Tooltip title="Remove" arrow>
                          <IconButton
                            onClick={() => handleCreatorDelete(creator?.id)}
                            color="primary"
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={creators?.length}
          onPageChange={(e, p) => setPage(p)}
          onRowsPerPageChange={e => setLimit(parseInt(e.target.value))}
          page={creators?.length > 0 ? page : 0}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Box>
    </>
  )
}

export default CreatorsTable
