import React, { SetStateAction } from 'react'
import { TopPerformingExperience, TopUserChoice } from '../../../api/types'
import { TableType } from '../../../pages/Dashboard'
import ExperienceTable from '../Tables/ExperienceTable'
import UserChoiceTable from '../Tables/UserChoiceTable'
import './FullList.css'

interface Props {
  experiences: TopPerformingExperience[]
  userChoices: TopUserChoice[]
  tableType: TableType
  setShowFullList: React.Dispatch<SetStateAction<TableType>>
}

const FullList = ({
  experiences,
  tableType,
  setShowFullList,
  userChoices,
}: Props) => {
  return (
    <div className="full-list">
      {tableType === 'EXPERIENCE' && (
        <ExperienceTable
          experiences={experiences}
          rowsToShow={100}
          setShowFullList={setShowFullList}
          withHeading={false}
        />
      )}
      {tableType === 'USER' && (
        <UserChoiceTable
          withHeading={false}
          rowsToShow={100}
          setShowFullList={setShowFullList}
          userChoices={userChoices}
        />
      )}
    </div>
  )
}

export default FullList
