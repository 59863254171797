import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { API_URL } from '../constants'
import { history } from '../history'
import store from '../redux/store'

// TODO - add typescript types to everything
export const getToken = (): string => {
  return store.getState().authReducer.token
}

const client = axios.create({
  baseURL: API_URL,
})

client.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${getToken()}`
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

export const request = (options: AxiosRequestConfig) => {
  const onSuccess = response => {
    return response.data
  }

  const onError = err => {
    if (err) {
      let error = err as AxiosError
      if (error.response?.status === 401) {
        return history.push('/logout')
      }
    } else {
      // TODO HANDLE ERROR
      console.error('Error Message:', err.message)
    }

    return Promise.reject(err.response || err.message)
  }

  return client(options).then(onSuccess).catch(onError)
}
