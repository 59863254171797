import React from 'react'

import styles from '../SequenceDemo.module.scss'

import { useSelector } from 'react-redux'
import { CardType } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import sampleImage from '../../../assets/image_holder_x.jpg'
import SequenceImageCard from './SequenceImageCard'

export interface SequenceImageSingleProps {
  image: string
}

const SequenceImageSingle: React.FC<SequenceImageSingleProps> = ({ image }) => {
  const { currentCard } = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps,
  )

  return (
    <div
      className={`${styles.imageSingle} ${
        currentCard?.cardProps?.cardType === CardType.directLink
          ? styles.directLinkWrapper
          : ''
      }`}
    >
      <SequenceImageCard
        currentCard={currentCard}
        image={image}
        sampleImage={sampleImage}
      />
    </div>
  )
}

export default SequenceImageSingle
