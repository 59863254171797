import { HighlightOff } from '@material-ui/icons'
import { useState } from 'react'
import {
  DashboardData,
  DashboardGetCreatorDataQueryParams,
  PairDashboardTimeFilterString,
} from '../../../api/types'
import { FilterType, TableType } from '../../../pages/Dashboard'
import FullList from '../../Dashboard/FullList/FullList'
import SummaryFilter from '../../Dashboard/SummaryFilter'
import Average from '../../Dashboard/SummaryOverview/Average'
import Total from '../../Dashboard/SummaryOverview/Total'
import UserChoiceTable from '../../Dashboard/Tables/UserChoiceTable'
import './SequencePollAnalytics.css'

interface Props {
  analyticsData: DashboardData
  availableTimeFilters: PairDashboardTimeFilterString[]
  filterData: DashboardGetCreatorDataQueryParams
  setFilterData: React.Dispatch<
    React.SetStateAction<DashboardGetCreatorDataQueryParams>
  >
  handleFilterDataChange: (type: FilterType, value: any) => void
  showCloseButton?: boolean
  closeAnalytics?: () => void
}

const SequencePollAnalytics = ({
  analyticsData,
  availableTimeFilters,
  filterData,
  setFilterData,
  handleFilterDataChange,
  showCloseButton,
  closeAnalytics,
}: Props) => {
  const [showFullList, setShowFullList] = useState<TableType>(undefined)
  return (
    <div className="single-sp-overview">
      {showFullList ? (
        <FullList
          experiences={analyticsData?.topPerformingExperiences}
          setShowFullList={setShowFullList}
          tableType={showFullList}
          userChoices={analyticsData?.topUserChoices}
        />
      ) : (
        <>
          <div className="analytics-header">
            <SummaryFilter
              type="TIME"
              availableFilters={availableTimeFilters}
              filterData={filterData}
              setFilterData={handleFilterDataChange}
            />
            {showCloseButton && (
              <div className="close-wrapper" onClick={closeAnalytics}>
                <HighlightOff />
              </div>
            )}
          </div>
          <Total
            totalLastCardClicks={
              analyticsData?.overallStatistics?.lastCardClicks
            }
            totalTimeSpent={analyticsData?.overallStatistics?.timeSpent}
            totalVisitors={analyticsData?.overallStatistics?.visitors}
          />
          <Average
            averageTime={analyticsData?.overallStatistics?.averageTimeSpent}
            bounceRate={analyticsData?.overallStatistics?.bounceRate}
            ctr={analyticsData?.overallStatistics?.clickThroughRate}
          />
          <UserChoiceTable
            userChoices={analyticsData?.topUserChoices}
            rowsToShow={3}
            setShowFullList={setShowFullList}
            withHeading={true}
          />
        </>
      )}
    </div>
  )
}

export default SequencePollAnalytics
