const LoaderBar = props => {
  const { bgcolor, completed } = props

  const containerStyles = {
    height: 10,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
    margin: 10,
    display: 'flex',
    flexDirection: 'row',
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    transition: 'all ease-in-out 150ms',
  }

  const labelStyles = {
    padding: 0,
    fontWeight: 'bold',
    fontSize: 9,
    position: 'absolute',
    right: '5px',
    color: '#000',
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
      <span style={labelStyles}>{`${completed}%`}</span>
    </div>
  )
}

export default LoaderBar
