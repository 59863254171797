import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import WidgetSettingsOverlay from 'src/components/Home/WidgetSettingsOverlay/WidgetSettingsOverlay'

import {
  addUrl,
  overrideUrls,
  removeUrl,
  resetForm,
  toggleOpenLastCardLinksInNewTab,
  toggleShowOnAll,
  toggleUtm,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import MoreIconSvg from '../../assets/more-icon.svg'
import WidgetSettingsCheckbox from './WidgetSettingsCheckbox/WidgetSettingsCheckbox'
import WidgetSettingsUTMParams from './WidgetSettingsUTMParams/WidgetSettingsUTMParams'
import WidgetSettingsTemplate from './WidgetSettingsTemplate/WidgetSettingsTemplate'
import WidgetSettingsDisplayDeviceType from './WidgetSettingsDisplayDeviceType/WidgetSettingsDisplayDeviceType'
import { WidgetSettings } from '../../api/types'
import { sequencePollValidateExternalUrl } from '../../api/services'

interface Props {
  setShowWidgetSettings: React.Dispatch<React.SetStateAction<boolean>>
  alertData: {
    title: string
    msg: string
  }
  setAlertData: React.Dispatch<
    React.SetStateAction<{
      title: string
      msg: string
    }>
  >
  handleSaveChanges: (status?: boolean) => void
  initialWidgetSettings: WidgetSettings
  setInitialWidgetSettings: React.Dispatch<React.SetStateAction<WidgetSettings>>
}
const WidgetSettingsComponent: React.FC<Props> = ({
  setShowWidgetSettings,
  handleSaveChanges,
  initialWidgetSettings,
}) => {
  const dispatch = useDispatch()

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const utmParams = useSelector(
    (state: RootState) => state.sequenceReducer.widgetSettings.utm,
  )

  const showUrls = useSelector(
    (state: RootState) => state.sequenceReducer.widgetSettings.includeUrls,
  )

  const hideUrls = useSelector(
    (state: RootState) => state.sequenceReducer.widgetSettings.excludeUrls,
  )

  const showOnAll = useSelector(
    (state: RootState) =>
      state.sequenceReducer.widgetSettings.showOnAllLastCardURLs,
  )

  const openLastCardLinksInNewTab = useSelector(
    (state: RootState) =>
      state.sequenceReducer.widgetSettings.openLastCardURLsInNewTab,
  )

  const isWidgetSettingsEditable = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.updateWidgetSettings,
  )

  const experienceId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )

  const validateUrl = async (
    url: string,
    key: 'includeUrls' | 'excludeUrls',
  ) => {
    try {
      let res = await sequencePollValidateExternalUrl({ url: url })
      if (!res.data.valid) {
        toast.warning(
          'Please check if the URL is correct or valid for your organization',
        )
        dispatch(
          removeUrl({
            key,
            value: url,
          }),
        )
      }
    } catch (err) {
      dispatch(
        removeUrl({
          key,
          value: url,
        }),
      )
      toast.warning(
        'Please check if the URL is correct or valid for your organization',
      )
    }
  }

  const handleSaveChangesSubmit = () => {
    if (
      utmParams.enabled &&
      utmParams.source &&
      utmParams.medium &&
      utmParams.campaign
    ) {
      handleSaveChanges(undefined)
    }
    if (!utmParams.enabled) {
      handleSaveChanges(undefined)
    }
  }

  const handleShowUrlKeyDown = event => {
    switch (event.key) {
      case ',':
      case ' ':
      case 'Enter': {
        let url = event?.target?.value
        if (!url.includes('https://')) url = 'https://' + url
        validateUrl(url, 'includeUrls')

        event.preventDefault()
        event.stopPropagation()
        dispatch(
          addUrl({
            key: 'includeUrls',
            value: url,
          }),
        )
        // setShowUrls([...showUrls, url]);
        break
      }
      default:
    }
  }

  const handleHideUrlKeyDown = event => {
    switch (event.key) {
      case ',':
      case ' ':
      case 'Enter': {
        let url = event?.target?.value
        if (!url.includes('https://')) url = 'https://' + url
        validateUrl(url, 'excludeUrls')

        event.preventDefault()
        event.stopPropagation()
        dispatch(
          addUrl({
            key: 'excludeUrls',
            value: url,
          }),
        )
        break
      }
      default:
    }
  }

  const handleCancel = () => {
    dispatch(resetForm(initialWidgetSettings))
    setShowWidgetSettings(false)
  }

  return (
    <div className="home-widget-settings">
      <div className="widget-form-settings">
        <FormControl className="home-form-wrapper">
          {isWidgetSettingsEditable && (
            <>
              <p>
                <b>Display settings</b>
              </p>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Display on the last card URLs (Co-browse)"
                checked={showOnAll}
                onChange={() => dispatch(toggleShowOnAll())}
                style={{ marginBottom: '-15px', marginLeft: '10px' }}
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Open last card URLs in a new tab"
                checked={openLastCardLinksInNewTab}
                onChange={() => dispatch(toggleOpenLastCardLinksInNewTab())}
                style={{ marginBottom: '-15px', marginLeft: '10px' }}
              />
              <Grid className="home-widget__urls">
                <Box p={1}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="tags-outlined"
                    options={[]}
                    getOptionLabel={option => {
                      if (typeof option === 'string') {
                        return option
                      }
                      return option.toString()
                    }}
                    onChange={(event, newValue) => {
                      if (Array.isArray(newValue)) {
                        dispatch(
                          overrideUrls({
                            key: 'includeUrls',
                            value: newValue as string[],
                          }),
                        )
                      }
                    }}
                    value={showUrls}
                    filterSelectedOptions
                    renderInput={params => {
                      params.inputProps.onKeyDown = handleShowUrlKeyDown
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Page URL where you want this experience"
                          placeholder="ex. https://contester.net/"
                          margin="normal"
                          fullWidth
                          helperText="You can add multiple URLs. You can also use wildcards to display on all URLs matching a pattern (i.e. https://contester.net/*)"
                        />
                      )
                    }}
                  />
                </Box>
              </Grid>
            </>
          )}
        </FormControl>
        {isWidgetSettingsEditable && (
          <>
            <FormControl className="home-form-wrapper">
              <Grid className="home-widget__urls">
                <Box p={1}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="tags-outlined"
                    options={[]}
                    getOptionLabel={option => {
                      if (typeof option === 'string') {
                        return option
                      }
                      return option.toString()
                    }}
                    onChange={(event, newValue) => {
                      if (Array.isArray(newValue)) {
                        dispatch(
                          overrideUrls({
                            key: 'excludeUrls',
                            value: newValue as string[],
                          }),
                        )
                      }
                    }}
                    value={hideUrls}
                    filterSelectedOptions
                    renderInput={params => {
                      params.inputProps.onKeyDown = handleHideUrlKeyDown
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Page URL where you don't want to show this experience"
                          placeholder="ex. https://contester.net/"
                          margin="normal"
                          helperText="You can add multiple URLs"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Box>
              </Grid>
              <div className="home-utm-settings-checkbox">
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Display if the link contains UTM(s)"
                  checked={utmParams.enabled}
                  onChange={() => {
                    dispatch(toggleUtm(!utmParams.enabled))
                    if (utmParams.enabled) setIsButtonDisabled(false)
                  }}
                  style={{ marginBottom: '-15px', marginLeft: '10px' }}
                />
                {utmParams.enabled && (
                  <Grid>
                    <Box>
                      <WidgetSettingsUTMParams
                        setIsButtonDisabled={setIsButtonDisabled}
                        experienceId={experienceId}
                      />
                    </Box>
                  </Grid>
                )}
              </div>
            </FormControl>
          </>
        )}
        <WidgetSettingsDisplayDeviceType />
        <WidgetSettingsTemplate />
        <WidgetSettingsOverlay />
        <div className="home-buttons-wrapper-settings">
          <Button variant="outlined">
            Learn more{' '}
            <img
              width={16}
              height={16}
              style={{ marginLeft: '8px' }}
              src={MoreIconSvg}
            />
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            onClick={() => handleSaveChangesSubmit()}
          >
            Save changes
          </Button>
        </div>
      </div>
      <div className="widget-settings-checkbox-wrapper">
        <WidgetSettingsCheckbox />
      </div>
      {/* <AlertDialog
        open={!!alertData}
        setOpen={() => setAlertData(null)}
        isLoading={false}
        handleAction={() => setAlertData(null)}
        title={alertData?.title}
        content={alertData?.msg}
        showCancel={false}
      /> */}
    </div>
  )
}

export default WidgetSettingsComponent
