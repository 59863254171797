import { Box, InputAdornment, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CreatorsTable from 'src/components/Account/Creators/CreatorsTable'
import { InviteButtonWrapper } from 'src/components/Account/OrganizationMembers/OrganizationMembers'
import ContentWrapper from 'src/components/ContentWrapper/ContentWrapper'
import { inviteCreatorThunk } from 'src/redux/slices/organizationSlice'
import { handleThunkPromiseToast } from 'src/utils/toast.config'

interface Props {
  canInvite: boolean
}

const Creators = ({ canInvite }: Props) => {
  const dispatch = useDispatch()
  const [creatorEmail, setCreatorEmail] = useState('')

  const handleCreatorInvite = async () => {
    await handleThunkPromiseToast(
      () => dispatch(inviteCreatorThunk(creatorEmail)),
      'Invitation sent',
      'success',
      'Failed to send invitation',
      'error',
    )
  }

  return (
    <ContentWrapper title="Creators">
      {canInvite && (
        <Box p={2}>
          <TextField
            sx={{ m: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={e => setCreatorEmail(e.target.value)}
            placeholder={'Invite creator by email'}
            value={creatorEmail}
            size="small"
            margin="normal"
            variant="outlined"
            style={{
              width: '250px',
            }}
          />
          <InviteButtonWrapper
            variant="contained"
            onClick={handleCreatorInvite}
          >
            Invite creator
          </InviteButtonWrapper>
        </Box>
      )}
      <CreatorsTable />
    </ContentWrapper>
  )
}

export default Creators
