import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import {
  setDisplayDeviceTypeDesktop,
  setDisplayDeviceTypeEnabled,
  setDisplayDeviceTypeMobile,
  setDisplayDeviceTypeTablet,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import '../WidgetSettings.css'
import React from 'react'

const WidgetSettingsDisplayDeviceType = () => {
  const dispatch = useDispatch()

  const enabled = useSelector(
    (state: RootState) =>
      state.sequenceReducer.widgetSettings.displayDeviceTypeSettings.enabled,
  )
  const mobile = useSelector(
    (state: RootState) =>
      state.sequenceReducer.widgetSettings.displayDeviceTypeSettings.mobile,
  )
  const tablet = useSelector(
    (state: RootState) =>
      state.sequenceReducer.widgetSettings.displayDeviceTypeSettings.tablet,
  )
  const desktop = useSelector(
    (state: RootState) =>
      state.sequenceReducer.widgetSettings.displayDeviceTypeSettings.desktop,
  )

  return (
    <>
      <Divider variant="middle" />

      <FormControl className="device-type-settings-wrapper">
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Display by device"
          checked={enabled}
          onChange={() => {
            let value = !enabled
            if (value == false) {
              // reset values
              dispatch(setDisplayDeviceTypeMobile({ mobile: true }))
              dispatch(setDisplayDeviceTypeTablet({ tablet: true }))
              dispatch(setDisplayDeviceTypeDesktop({ desktop: true }))
            }
            dispatch(setDisplayDeviceTypeEnabled({ enabled: value }))
          }}
          style={{ marginLeft: '10px' }}
        />
        {enabled && (
          <>
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Display on mobile"
              value={mobile}
              checked={mobile}
              onChange={(event, value) =>
                dispatch(setDisplayDeviceTypeMobile({ mobile: value }))
              }
              style={{ marginLeft: '50px' }}
            />
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Display on tablet"
              value={tablet}
              checked={tablet}
              onChange={(event, value) =>
                dispatch(setDisplayDeviceTypeTablet({ tablet: value }))
              }
              style={{ marginLeft: '50px' }}
            />
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Display on desktop"
              value={desktop}
              checked={desktop}
              onChange={(event, value) =>
                dispatch(setDisplayDeviceTypeDesktop({ desktop: value }))
              }
              style={{ marginLeft: '50px', marginBottom: '20px' }}
            />
          </>
        )}
      </FormControl>
    </>
  )
}

export default WidgetSettingsDisplayDeviceType
