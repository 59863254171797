import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import {
  changeWidgetSetting,
  setDefaultDisplayType,
  setDefaultPlacement,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import React from 'react'

const WidgetSettingsCheckbox = () => {
  const dispatch = useDispatch()
  const displaySettings = useSelector(
    (state: RootState) => state.sequenceReducer.widgetSettings.displaySettings,
  )
  const availableDisplayTypes = [
    {
      key: 'MaximizedExpanded',
      label: 'Maximized and expanded',
    },
    {
      key: 'Maximized',
      label: 'Maximized',
    },
    {
      key: 'Minimized',
      label: 'Minimized',
    },
    // {
    //   key: 'Small',
    //   label: 'Small'
    // },
    // {
    //   key: 'Notification',
    //   label: 'Notification'
    // },
  ]

  const availablePlacements = [
    {
      key: 'BottomRight',
      label: 'Bottom right',
    },
    {
      key: 'BottomLeft',
      label: 'Bottom left',
    },
    {
      key: 'TopLeft',
      label: 'Top left',
    },
    {
      key: 'TopRight',
      label: 'Top right',
    },
  ]

  return (
    <div>
      <p>
        <b>Widget behaviour</b>
      </p>
      <FormGroup style={{ width: '300px' }}>
        <TextField
          select
          onChange={e => {
            dispatch(setDefaultDisplayType({ displayType: e.target.value }))
          }}
          value={displaySettings.defaultDisplayType}
          label="Select default display type"
          InputLabelProps={{
            shrink: true,
          }}
        >
          {availableDisplayTypes?.map((type, i) => {
            return (
              <MenuItem key={i} value={type.key}>
                {type.label}
              </MenuItem>
            )
          })}
        </TextField>
        <FormHelperText>
          <span>Initial size of a widget on your website.</span>
        </FormHelperText>

        <TextField
          style={{ marginTop: '1em' }}
          select
          onChange={e => {
            dispatch(setDefaultPlacement({ placement: e.target.value }))
          }}
          value={displaySettings.defaultPlacement}
          label="Select default widget placement"
          InputLabelProps={{
            shrink: true,
          }}
        >
          {availablePlacements?.map((placement, i) => {
            return (
              <MenuItem key={i} value={placement.key}>
                {placement.label}
              </MenuItem>
            )
          })}
        </TextField>
        <FormHelperText>
          <span>Initial placement of a widget on your website.</span>
        </FormHelperText>
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Remember when user closes the widget"
          value={displaySettings?.rememberClose}
          checked={displaySettings?.rememberClose}
          onChange={() =>
            dispatch(
              changeWidgetSetting({
                key: 'rememberClose',
                value: !displaySettings?.rememberClose,
              }),
            )
          }
        />
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Reset experience on new window"
          value={displaySettings?.resetExperienceInNewWindow}
          checked={displaySettings?.resetExperienceInNewWindow}
          onChange={() =>
            dispatch(
              changeWidgetSetting({
                key: 'resetExperienceInNewWindow',
                value: !displaySettings?.resetExperienceInNewWindow,
              }),
            )
          }
        />
      </FormGroup>
    </div>
  )
}

export default WidgetSettingsCheckbox
