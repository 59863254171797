import {
  setSequenceId,
  setSequenceStatus,
} from 'src/redux/slices/sequenceSlice'
import store from 'src/redux/store'
import { saveSequencePoll } from 'src/services/api/sequencePolls'

export default class SequenceSliceHandler {
  private static _instance: SequenceSliceHandler

  private constructor() {}

  interval: NodeJS.Timeout

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  startInterval() {
    this.interval = setInterval(async () => {
      const sequence = store.getState().sequenceReducer
      try {
        let res = await saveSequencePoll(
          sequence.sequenceBox.items,
          sequence.contentName,
          sequence.brandId,
          sequence.sequenceId,
          sequence.status,
          sequence.template,
          sequence.widgetSettings,
          sequence.overlay,
          sequence.ai,
        )
        if (sequence.sequenceId === '') {
          store.dispatch(setSequenceId(res.id))
        }
        store.dispatch(setSequenceStatus(res.status))
      } catch (err) {
        // TODO
      }
    }, 60000)
  }

  stopInterval() {
    clearInterval(this.interval)
  }
}
