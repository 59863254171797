import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@material-ui/core'
import React from 'react'
import { LiveHelp, PlayLesson, TextSnippet } from '@material-ui/icons'
import { IS_PRODUCTION } from '../../../constants'

function SidebarBottomLinks() {
  return (
    <>
      <MenuList>
        {!IS_PRODUCTION && (
          <a
            href={
              window.location.pathname +
              '?utm_enabled=true&utm_source=contester&utm_medium=creator&utm_campaign=tutorial'
            }
          >
            <MenuItem>
              <ListItemIcon>
                <PlayLesson fontSize="small" />
              </ListItemIcon>
              <ListItemText>Tutorial</ListItemText>
            </MenuItem>
          </a>
        )}
        <a href={'https://docs.contester.net'} target={'_blank'}>
          <MenuItem>
            <ListItemIcon>
              <TextSnippet fontSize="small" />
            </ListItemIcon>
            <ListItemText>Docs</ListItemText>
          </MenuItem>
        </a>
        {/*<MenuItem>*/}
        {/*  <ListItemIcon>*/}
        {/*    <LiveHelp fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText>Live chat</ListItemText>*/}
        {/*</MenuItem>*/}
      </MenuList>
    </>
  )
}

export default SidebarBottomLinks
