import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { history } from 'src/history'
import { resetSequenceData } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import styles from './SequencePreviewButtons.module.scss'
import { sequencePollGetOverlayData } from '../../api/services'
import { SequencePollOverlayData } from '../../api/types'
export interface SequeceHeaderProps {
  sequenceDataUrl: string
  sequenceId: string
}

const SequecePreviewButtons: React.FC<SequeceHeaderProps> = props => {
  const dispatch = useDispatch()
  const [state, setState] = React.useState(false)
  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )
  const isEmailVerified = useSelector(
    (state: RootState) => state.authReducer.isEmailVerified,
  )
  const [overlay, setOverlay] = useState<SequencePollOverlayData>()

  const handleClick = (text: string) => {
    navigator.clipboard.writeText(text)
    setState(true)
  }

  const handleOpenContent = () => {
    window.open(sequenceDataUrl, '_blank', 'noopener,noreferrer')
  }

  const handleEdit = (tab?: string) => {
    let search = `id=${props.sequenceId}`
    if (tab) search += `&tab=${tab}`
    history.push({
      pathname: '/content/experiences',
      search,
    })
  }

  const handleClose = () => {
    setState(false)
  }

  const getOverlayData = async () => {
    const overlay = await sequencePollGetOverlayData(props.sequenceId)
    if (overlay.data.url != '') {
      setOverlay(overlay.data)
    }
  }

  useEffect(() => {
    getOverlayData()
    return () => {
      dispatch(resetSequenceData())
    }
  }, [])

  useEffect(() => {
    if (sequencePollId) {
      toast.info(`Your new changes might take up to a few minutes to appear on the live experience. 
    Also to see the new changes make sure the browser cash is cleaned
    `)
    } else {
      toast.success('Your experience is live!')
    }
  }, [])

  const { sequenceDataUrl } = props

  return (
    <>
      {isEmailVerified ? (
        <div className={styles.previewWrapper}>
          <Typography variant="h3" className={styles.previewTitle}>
            Your content is published
          </Typography>
          <Paper
            className={`${styles.previewCard} ${styles.sidebarBox}`}
            variant="outlined"
          >
            <Button
              onClick={handleOpenContent}
              variant="outlined"
              className={`${styles.previewButton} ${styles.sidebarBox}`}
            >
              Open your content
            </Button>
          </Paper>
          <Paper
            className={`${styles.previewCard} ${styles.sidebarBox}`}
            variant="outlined"
          >
            <TextField
              id="outlined-basic"
              label="Link"
              variant="outlined"
              value={sequenceDataUrl}
              className={`${styles.previewButton} ${styles.sidebarBox}`}
            />
            <Button
              variant="outlined"
              className={`${styles.previewButton} ${styles.sidebarBox}`}
              onClick={() => handleClick(sequenceDataUrl)}
            >
              Copy the link
            </Button>
          </Paper>
          {overlay && (
            <Paper
              className={`${styles.previewCard} ${styles.sidebarBox}`}
              variant="outlined"
            >
              <TextField
                id="outlined-basic"
                label="Overlay URL"
                variant="outlined"
                value={overlay.publicUrl}
                className={`${styles.previewButton} ${styles.sidebarBox}`}
              />
              <Button
                variant="outlined"
                className={`${styles.previewButton} ${styles.sidebarBox}`}
                onClick={() => handleClick(overlay.publicUrl)}
              >
                Copy the link
              </Button>
            </Paper>
          )}
          <Paper
            className={`${styles.previewCard} ${styles.sidebarBox}`}
            variant="outlined"
          >
            <Button
              onClick={() => handleEdit()}
              variant="outlined"
              className={`${styles.previewButton} ${styles.sidebarBox}`}
            >
              Edit on content management
            </Button>
          </Paper>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={state}
            onClose={handleClose}
            message="Copied successfully!"
            key={sequenceDataUrl}
          />
        </div>
      ) : (
        <div className={styles.previewWrapper}>
          <Typography variant="h3" className={styles?.previewTitle}>
            The experience is saved as a draft on the content management. In
            order to publish it please confirm your email address.
          </Typography>
          <Button
            onClick={() => handleEdit('Draft')}
            variant="outlined"
            className={`${styles.previewButton} ${styles.sidebarBox}`}
          >
            Edit on content management
          </Button>
        </div>
      )}
    </>
  )
}

export default SequecePreviewButtons
