import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { routes } from 'src/constants'
import { request } from 'src/services/apiServices'

interface InitialProps {
  fonts: {
    name: string
    url: string
  }[]
}

export const getFontsThunk = createAsyncThunk('getFonts', async state => {
  return await request({
    method: 'GET',
    url: routes.GET_FONTS,
  })
})

const initialState: InitialProps = {
  fonts: [],
}

export const helperSlice = createSlice({
  name: 'helper',
  initialState,
  reducers: {
    setFonts: (state, action) => {
      state.fonts = action.payload
    },
  },
  //   extraReducers: builder => {
  //     builder.addCase(getFontsThunk.fulfilled, (state, action) => {
  //       state.fonts = [...action.payload?.fonts];
  //     });
  //   },
})

export const { setFonts } = helperSlice.actions
export default helperSlice.reducer
