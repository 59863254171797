import React, { FC, ReactNode, useEffect } from 'react'
import PropTypes from 'prop-types'
import { experimentalStyled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

import Sidebar from './Sidebar/Sidebar'
import Header from './Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import {
  getActiveOrganizationThunk,
  getAvailableOrganizationsThunk,
  getCreatorsThunk,
  getOrganizationMembersThunk,
  getPendingInvitationsThunk,
} from 'src/redux/slices/organizationSlice'
import { RootState } from 'src/redux/store'
import { useHistory } from 'react-router'
import { getBillingSubscriptionThunk } from '../../redux/slices/billingSlice'
import { billingSelectSubscriptionTierType } from '../../api/services'
import styled from '@emotion/styled'
import Contester from '../../assets/contester.svg'
import { Modal } from '../Modal/SimpleModal'

interface SidebarLayoutProps {
  children?: ReactNode
}

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`,
)

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`,
)

const TypographyCustom = styled(Typography)(() => ({
  fontSize: '27px',
  '@media (max-width: 768px)': {
    fontSize: '20px',
    textAlign: 'center',
  },
}))

const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '864px',
  height: '654px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 32px #00000021',
  borderRadius: '17px',
  '@media (max-width: 768px)': {
    width: '95%',
  },
}))

const Logo = styled.img(() => ({
  '@media (max-width: 768px)': {
    maxWidth: '90%',
  },
}))

const TitleBlock = styled.div(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  borderRadius: '17px',
  gap: '17px',
}))

const Footer = styled.div(() => ({
  width: '100%',
  minHeight: '210px',
  background: '#F6F8FB 0% 0% no-repeat padding-box',
  borderRadius: '17px',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  '& a': {
    color: '#930AB5',
    fontSize: '25px',
    textDecoration: 'none',
    '@media (max-width: 768px)': {
      fontSize: '20px',
    },
  },
  '& p': {
    fontSize: '24px',
    marginBottom: '4px',
    marginTop: '0',
    '@media (max-width: 768px)': {
      fontSize: '20px',
    },
  },
  '& span': {
    color: '#5F5F5F',
  },
}))

const Button = styled.div(() => ({
  marginTop: '17px',
  width: '431px',
  height: '87px',
  background: '#F6F8FB 0% 0% no-repeat padding-box',
  border: '1px solid #697BFF',
  boxShadow: '0px 3px 32px #00000021',
  borderRadius: '17px',
  fontSize: '26px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#2C00FF',
  cursor: 'pointer',
  '@media (max-width: 768px)': {
    width: '60%',
  },
}))

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
  const dispatch = useDispatch()

  const router = useHistory()

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event?.data?.type === 'contester-navigation-url') {
        router.push(event?.data?.url)
      }
    })
  }, [])

  const activeOrganization = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  const canViewInvitations = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.team.viewInvitations,
  )

  const canSelectTierType = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.billing.canSelectTierType,
  )

  const initData = async () => {
    await Promise.all([
      dispatch(getOrganizationMembersThunk()),
      dispatch(getCreatorsThunk()),
      dispatch(getBillingSubscriptionThunk()),
    ])
  }
  useEffect(() => {
    if (activeOrganization?.id) {
      initData()
    }
    if (canViewInvitations) {
      dispatch(getPendingInvitationsThunk())
    }
  }, [activeOrganization?.id, initData, dispatch])

  useEffect(() => {
    dispatch(getActiveOrganizationThunk())
    dispatch(getAvailableOrganizationsThunk())
  }, [])

  let selectTierType = function (type: 'Business' | 'Creator') {
    billingSelectSubscriptionTierType({ type: type }).then(() => {
      dispatch(getBillingSubscriptionThunk())
      window.location.reload()
    })
  }

  return (
    <>
      {canSelectTierType && (
        <Modal>
          <Container>
            <TitleBlock>
              <Logo src={Contester} />
              <TypographyCustom>
                You are entering into your Business account
              </TypographyCustom>
              <Button onClick={() => selectTierType('Business')}>
                Start Here
              </Button>
            </TitleBlock>
            <Footer>
              <p>
                If you are a creator you can join Contester Marketplace
                <br />
                <span> and use a Creator license with limited features</span>
              </p>
              <a href="#" onClick={() => selectTierType('Creator')}>
                Start as a Creator here{' '}
              </a>
            </Footer>
          </Container>
        </Modal>
      )}
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>{children}</MainContent>
      </MainWrapper>
    </>
  )
}

SidebarLayout.propTypes = {
  children: PropTypes.node,
}

export default SidebarLayout
