import * as React from 'react'
import ProductListFilter from './ProductListFilter/ProductListFilter'
import SequenceOptions from './SequenceOptions/SequenceOptions'

import styles from './Sidebar.module.scss'

export interface SidebarProps {
  step: number
}

const Products: React.FC<SidebarProps> = ({ step }) => {
  return (
    <div className={styles.sidebar}>
      {step === 1 && <ProductListFilter />}
      {step === 2 && <SequenceOptions />}
    </div>
  )
}

export default Products
