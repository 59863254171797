import { Card } from '@material-ui/core'
import { useHistory } from 'react-router'
import { RecentlyEdited } from '../../api/types'
import './RecentlyCard.css'

interface Props {
  recentlyEdited: RecentlyEdited[]
}

const RecentlyCard = ({ recentlyEdited }: Props) => {
  const router = useHistory()

  const handleSequencePollClick = (id: string) => {
    router.push('/content/experiences?id=' + id)
  }

  return (
    <div className="recentlyEditedCard">
      <Card>
        <div className="header">
          <h4>Recently edited</h4>
        </div>
        <ul>
          {recentlyEdited.map(item => {
            return (
              <li
                className="summary-recently-poll-item"
                key={item.experienceId}
                onClick={() => handleSequencePollClick(item.experienceId)}
              >
                <img className="round-avatar" src={item.image} />
                {item.campaignName}
              </li>
            )
          })}
        </ul>
      </Card>
    </div>
  )
}

export default RecentlyCard
