import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import { Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'src/redux/hooks'
import * as Yup from 'yup'
import ImageHolder1 from '../../../assets/Image-holder1.jpg'
import ImageHolder2 from '../../../assets/Image-holder2.jpg'
import {
  addBrandDataThunk,
  editBrandDataThunk,
} from '../../../redux/slices/brandSlice'
import './BrandDialog.css'
import { BrandUpdateRequest } from '../../../api/types'
import { handleThunkPromiseToast } from '../../../utils/toast.config'

const Input = experimentalStyled('input')({
  display: 'none',
})

const AvatarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
      position: relative;

      .MuiAvatar-root {
        width: ${theme.spacing(16)};
        height: ${theme.spacing(16)};
        margin-top: 5px;
      }
  `,
)

const PosterImageWrapper = experimentalStyled(Avatar)(
  () => `
    .MuiAvatar-root{
      width: 225px !important;
    }
    `,
)

const ButtonUploadWrapper = experimentalStyled(Box)(
  ({ theme }) => `
      position: absolute;
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      bottom: -${theme.spacing(2)};
      right: -${theme.spacing(2)};
  
      .MuiIconButton-root {
        border-radius: 100%;
        background: ${theme.colors.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: ${theme.colors.shadows.primary};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        padding: 0;
    
        &:hover {
          background: ${theme.colors.primary.dark};
        }
      }
  `,
)

type BrandDialogProps = {
  open: boolean
  formData: any
  closeHandler: () => void
  uploadLogoFile: (e: React.ChangeEvent<HTMLInputElement>) => any
  uploadPosterFile: (e: React.ChangeEvent<HTMLInputElement>) => any
  logoId: string
  posterId: string
  logoUrl: string
  posterUrl: string
}

const BrandDialog: React.FC<BrandDialogProps> = ({
  open,
  formData,
  closeHandler,
  uploadLogoFile,
  uploadPosterFile,
  logoId,
  posterId,
  logoUrl,
  posterUrl,
}) => {
  const dispatch = useAppDispatch()

  const handleCreateBrandSuccess = async values => {
    const brand = {
      name: values.name,
      title: values.title,
      description: values.description,
      websiteUrl: values.websiteUrl,
      websiteUrlTitle: values.websiteUrlTitle,
      logoMediaStorageItemId: logoId,
      logoMediaStorageItemLocation: logoUrl,
      posterMediaStorageItemId: posterId,
      posterMediaStorageItemLocation: posterUrl,
    }
    await handleThunkPromiseToast(
      () => dispatch(addBrandDataThunk(brand)),
      'Brand created',
      'success',
      'Failed to create brand',
      'error',
    )
    closeHandler()
  }

  const handleEditBrandSuccess = async values => {
    const brand: BrandUpdateRequest = {
      name: values.name,
      title: values.title,
      description: values.description,
      websiteUrl: values.websiteUrl,
      websiteUrlTitle: values.websiteUrlTitle,
      logoMediaStorageItemId: logoId,
      posterMediaStorageItemId: posterId,
    }
    const res = await dispatch(
      editBrandDataThunk({ id: values.id, brand: brand }),
    ).unwrap()
    if (res?.success) {
      toast.success('The brand is successfully edited')
    }
    closeHandler()
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open} onClose={closeHandler}>
        <DialogTitle
          sx={{ p: 3 }}
          disableTypography
          style={{ paddingLeft: '90px' }}
        >
          <Typography variant="h4" gutterBottom>
            {formData.name === '' ? 'Add new brand' : 'Edit brand'}
          </Typography>
          <Typography variant="subtitle2">
            {formData.name === ''
              ? 'Fill in the fields below to create and add a new brand'
              : 'Fill in the fields below to edit the brand'}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={formData}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(30, 'The name must be up to 30 characters long')
              .required('The name field is required'),
            title: Yup.string()
              .max(30, 'The name must be up to 30 characters long')
              .required('The title field is required'),
            description: Yup.string()
              .max(70, 'The description must be up to 70 characters long')
              .required('The description field is required'),
            websiteUrl: Yup.string()
              .max(255)
              .required('The website url field is required'),
            websiteUrlTitle: Yup.string()
              .max(255)
              .required('The website url title field is required'),
            // "icon-button-file-1": Yup.mixed().required(),
            // "icon-button-file-2": Yup.mixed().required()
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting },
          ) => {
            try {
              // await wait(1000);
              resetForm()
              setStatus({ success: true })
              setSubmitting(false)
              if (formData.name === '') await handleCreateBrandSuccess(values)
              else await handleEditBrandSuccess(values)
            } catch (err) {
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{ p: 3 }}
                style={{ paddingLeft: '90px' }}
              >
                <Grid container spacing={3} mb={1}>
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3} mt={5}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Name"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={Boolean(touched.title && errors.title)}
                          fullWidth
                          helperText={touched.title && errors.title}
                          label="Title"
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.title}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.description && errors.description,
                          )}
                          fullWidth
                          helperText={touched.description && errors.description}
                          label="Description"
                          name="description"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.websiteUrl && errors.websiteUrl,
                          )}
                          fullWidth
                          helperText={touched.websiteUrl && errors.websiteUrl}
                          label="Website URL"
                          name="websiteUrl"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.websiteUrl}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.websiteUrlTitle && errors.websiteUrlTitle,
                          )}
                          fullWidth
                          margin="normal"
                          helperText={
                            touched.websiteUrlTitle && errors.websiteUrlTitle
                          }
                          label="Website URL title"
                          name="websiteUrlTitle"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.websiteUrlTitle}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={5} justifyContent="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      mt={3}
                    >
                      <FormHelperText>Upload the logo image</FormHelperText>
                      <AvatarWrapper>
                        <Avatar
                          variant="rounded"
                          src={logoUrl === '' ? ImageHolder2 : logoUrl}
                        />
                        <ButtonUploadWrapper>
                          <Input
                            accept="image/*"
                            id="icon-button-file-1"
                            name="icon-button-file-1"
                            type="file"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              uploadLogoFile(e)
                            }}
                          />
                          <label htmlFor="icon-button-file-1">
                            <IconButton component="span" color="primary">
                              <CloudUploadTwoToneIcon />
                            </IconButton>
                          </label>
                        </ButtonUploadWrapper>
                      </AvatarWrapper>
                      {!logoUrl && errors['icon-button-file-1'] && (
                        <p className="brand-dialog-error">
                          Logo image is required
                        </p>
                      )}
                      <Divider flexItem sx={{ m: 4 }} />
                      <FormHelperText>Upload the poster image</FormHelperText>
                      <AvatarWrapper>
                        <PosterImageWrapper
                          variant="rounded"
                          src={posterUrl === '' ? ImageHolder1 : posterUrl}
                        />
                        <ButtonUploadWrapper>
                          <Input
                            accept="image/*"
                            id="icon-button-file-2"
                            name="icon-button-file-2"
                            type="file"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              uploadPosterFile(e)
                            }}
                          />
                          <label htmlFor="icon-button-file-2">
                            <IconButton component="span" color="primary">
                              <CloudUploadTwoToneIcon />
                            </IconButton>
                          </label>
                        </ButtonUploadWrapper>
                      </AvatarWrapper>
                      {!posterUrl && errors['icon-button-file-2'] && (
                        <p className="brand-dialog-error">
                          Poster image is required
                        </p>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Button color="secondary" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {formData.name === '' ? 'Add new brand' : 'Edit brand'}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default BrandDialog
