import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useTheme,
} from '@material-ui/core'
import Moment from 'react-moment'
import { experimentalStyled } from '@material-ui/core/styles'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { handleThunkPromiseToast } from 'src/utils/toast.config'
import AlertDialog from 'src/components/Modal/Modal'
import NoDataImage from '../../assets/undraw_no_data.svg'

import {
  getLiveStreamsThunk,
  deleteLiveStreamThunk,
} from '../../redux/slices/liveStreamsSlice'
import {
  getAllLiveStreamsSelector,
  isStreamDataLoadingSelector,
} from '../../redux/selectors/liveStreamsSelectors'
import { putImageMedia, saveImage } from 'src/services/api/mediastorage'
import StreamDataManager from './LiveStreamDataManager/LiveStreamDataManager'
import LiveStreamDialog from './LiveStreamDialog/LiveStreamDialog'
import LiveStreams from '../LiveStreams/LiveStreams'
import styles from './Library.module.scss'
import { LiveStreamListData } from '../../api/types'
import { scrollToElm } from '../../utils/scroll'

const DrawerWrapper = experimentalStyled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 315px;
        height: calc(100% - ${theme.header.height});
        position: fixed;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.gradients.blue3};
        color: ${theme.colors.alpha.white[100]};
    }
`,
)

const ListDrawerWrapper = experimentalStyled(Drawer)(
  () => `
    width: calc(100% - 280px);
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: calc(100% - 675px);
        min-width: 315px;
        height: calc(100% - 255px);
        top: 250px;
        z-index: 1;
        border: 0;
    }

    @media only screen and (min-width: 1280px) {
      & > .MuiPaper-root {
        left: 300px;        
        width: calc(100% - 670px);
      }
    }

    @media only screen and (max-width: 1279px) {
      & > .MuiPaper-root {
        left: 25px;
        width: calc(100% - 375px);
      }
    }    
`,
)
const ListDrawerWrapperExpand = experimentalStyled(ListDrawerWrapper)(
  `
  & > .MuiPaper-root {
    width: calc(100% - 675px);
    min-width: 315px;
    height: 100%;
    top: 100px;
    z-index: 1;
    border: 0;
}
`,
)

const StreamDrawerWrapper = experimentalStyled(ListDrawerWrapper)(
  `
& > .MuiPaper-root {
  width: calc(100% - 640px);
  min-width: 315px;
  height: 100%;
  top: 100px;
  z-index: 1;
  border: 0;
}
`,
)

const CardActionAreaWrapper = experimentalStyled(CardActionArea)(
  () => `
      height: 225px;
      min-height: 225px;
      max-height: 225px;
      padding: 0px;
      margin-top: 0px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;

      .MuiSvgIcon-root {
        opacity: .5;
      }

      .MuiTouchRipple-root {
        opacity: .3;
      }

      img {
        height: auto;
        width: 100%;
      }

      .MuiCardActionArea-focusHighlight {
        background: blue;
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: 0.04;
        }
      }
`,
)

const StreamCard = experimentalStyled(Card)(
  () => `
    height: 310px;
    min-height: 310px;
    max-height: 310px;
  `,
)

const AddLiveButton = experimentalStyled(Button)(
  () => `
  background-color: #000;
  color: #fff;
  &:hover {
    background-color: #000;
  }
  @media only screen and (max-width: 959px) {
    margin-right: 24px;
  }
`,
)

const HeaderBox = experimentalStyled(Box)(
  () => `
      z-index: 1;
      background: white;
      padding-left: 23px;
      position: fixed;
`,
)

const SortButton = experimentalStyled(Button)(
  () => `
      min-width: 105px;
`,
)

const SequenceContainerWrapper = experimentalStyled(Container)(
  () => `
    overflow: hidden;
`,
)

const ImageContentWrapper = experimentalStyled(Box)(
  () => `
    position: relative;
    width: 100%;
    margin-bottom: 2px;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      animation: fadeIn 0.5s ease 1;
    }
`,
)

export type TabValue = 'liveStream'
export type SubTabValue = 'Scheduled' | 'Ended'
export type ActiveSection = 'StreamsList' | 'LiveStream' | 'StreamSettings'

const Library: React.FC = () => {
  const location = useLocation()
  const theme = useTheme()

  const [isInitial, setIsInitial] = useState(true)
  const selectedSpQueryRef = useCallback(
    node => {
      if (node && isInitial) {
        setIsInitial(false)
        const container = document.querySelector('#streamsGrid')?.firstChild
        if (container) {
          scrollToElm(container, node)
        }
      }
    },
    [isInitial],
  )

  const handleLiveStreamDialogClose = () => {
    setActiveSection('StreamsList')
  }

  const actionRef1 = useRef<any>(null)
  const [currentTab] = useState<TabValue>('liveStream')
  const [currentSubTab, setCurrentSubTab] = useState<SubTabValue>('Scheduled')
  const [posterUrl, setPosterUrl] = useState<string>('')
  const [posterId, setPosterId] = useState<string>('')

  const [isPosterLoading, setPosterLoading] = useState<boolean>(false)
  const allStreams = useSelector(getAllLiveStreamsSelector)
  const isLoading = useSelector(isStreamDataLoadingSelector)

  const tabs: { value: TabValue; label: string }[] = [
    { value: 'liveStream', label: 'Live stream' },
  ]
  const subTabs: { value: SubTabValue; label: string }[] = [
    { value: 'Scheduled', label: 'Scheduled' },
    { value: 'Ended', label: 'Ended' },
  ]

  const sortings = {
    'By date': 'scheduledAt',
    'By title': 'title',
  }

  const handleTabsChange = (
    event: ChangeEvent<{}>,
    value: SubTabValue,
  ): void => {
    setCurrentSubTab(value)
  }

  const dispatch = useDispatch()
  const [selectedStream, setSelectedStreamId] = useState<string>(
    allStreams?.[0]?.id,
  )

  const [openSort, setOpenMenuSort] = useState<boolean>(false)
  const [sort, setSort] = useState<string>('By date')

  const [isActiveLiveStreamVisible, setActiveLiveStreamVisible] =
    useState<boolean>(false)

  const [activeSection, setActiveSection] =
    useState<ActiveSection>('StreamsList')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [modalText, setModalText] = useState({
    title: '',
    content: '',
  })

  const [formData, setFormData] = useState({
    id: '',
    title: '',
    scheduled: '',
    posterUrl: '',
    posterId: '',
    sourceType: '',
    submit: null,
  })

  const liveStreamDialogOpen = () => {
    setFormData({
      id: '',
      title: '',
      scheduled: '',
      posterUrl: '',
      posterId: '',
      sourceType: 'Srt',
      submit: null,
    })
    setPosterUrl('')
    setPosterId('')
    setActiveSection('StreamSettings')
  }

  const openEditStreamDialog = streamData => {
    setFormData({
      id: streamData.id,
      title: streamData.title,
      scheduled: streamData.scheduledAt,
      posterUrl: streamData.posterUrl,
      posterId: streamData.poster.id,
      sourceType: streamData.sourceType,
      submit: null,
    })
    setPosterUrl(streamData.poster.location)
    setPosterId(streamData.poster.id)
    setActiveSection('StreamSettings')
  }

  const handleSelectOneStream = (streamId: string): void => {
    if (selectedStream !== streamId) {
      setSelectedStreamId(streamId)
      handleDrawerToggle()
    }
  }

  const uploadPosterFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue,
  ) => {
    setPosterLoading(true)
    const posterFile = event.target.files

    if (!posterFile) return

    if (event.target.files.length !== 0) {
      setPosterUrl(URL.createObjectURL(posterFile[0]))
    }

    if (event?.target?.files && event?.target.files[0]) {
      let fileData = new FormData()
      fileData.append('file', event?.target?.files[0])
      putImageMedia().then(data => {
        console.log('SAVING IMAGE AND', data)

        // Add poster loader
        saveImage(fileData, data.id).then(response => {
          console.log('SAVING IMAGE AND', response)
          setFieldValue('posterId', response?.id)
          setPosterId(response?.id)
          setPosterLoading(false)
          setPosterUrl(response?.location)
        })
      })
    }
  }

  useEffect(() => {
    dispatch(getLiveStreamsThunk(currentSubTab === 'Scheduled', sortings[sort]))
  }, [dispatch, currentSubTab])

  useEffect(() => {
    if (!location.search.includes('id') && !selectedStream) {
      setSelectedStreamId(allStreams?.[0]?.id)
    }
  }, [allStreams])

  const activeStream = allStreams?.find(stream => {
    if (stream.id === selectedStream) {
      return stream
    }
  })

  useEffect(() => {
    if (
      allStreams.length !== 0 &&
      !allStreams.some(liveStream => liveStream.id === selectedStream)
    ) {
      debugger
      setSelectedStreamId(allStreams[0].id)
    }
  }, [allStreams.length])

  const hideStream = () => {
    setActiveLiveStreamVisible(false)
    setActiveSection('StreamsList')
  }

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const deleteLiveStream = () => {
    setIsModalOpen(true)
    setModalText({
      title: 'Confirmation',
      content: 'Are you sure you want to delete this live stream?',
    })
  }

  const handleDialogAction = async () => {
    await handleThunkPromiseToast(
      () => dispatch(deleteLiveStreamThunk(selectedStream)),
      'Live stream was deleted',
      'success',
      'Failed to delete live stream',
      'error',
    )
    setIsModalOpen(false)
  }

  const renderGalleryContent = () => {
    switch (activeSection) {
      case 'LiveStream':
        return (
          <StreamDrawerWrapper variant="permanent" anchor="left" open>
            <LiveStreams
              liveStreamId={selectedStream}
              isActiveLiveStreamVisible={isActiveLiveStreamVisible}
              disconnectCallback={hideStream}
              goLiveCallback={() => {}}
            />
          </StreamDrawerWrapper>
        )
      case 'StreamSettings':
        return (
          <ListDrawerWrapperExpand variant="permanent" anchor="left" open>
            <Container maxWidth="md">
              <LiveStreamDialog
                formData={formData}
                closeHandler={handleLiveStreamDialogClose}
                uploadPreviewFile={uploadPosterFile}
                posterUrl={posterUrl}
                posterId={posterId}
                isPosterLoading={isPosterLoading}
              />
            </Container>
          </ListDrawerWrapperExpand>
        )
      default:
        return (
          <>
            <HeaderBox
              sx={{
                width: {
                  sm: 'calc(100% - 390px)',
                  md: 'calc(100% - 400px)',
                  lg: 'calc(100% - 700px)',
                },
                padding: 0,
                left: {
                  lg: '310px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 3,
                  mb: 3,
                }}
              >
                <Tabs
                  onChange={handleTabsChange}
                  value={currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {tabs.map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </Tabs>
                <AddLiveButton
                  onClick={liveStreamDialogOpen}
                  variant="contained"
                >
                  Create Live Stream +
                </AddLiveButton>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" mt={3}>
                <Tabs
                  onChange={handleTabsChange}
                  value={currentSubTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {subTabs.map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </Tabs>
                <SortButton
                  size="small"
                  variant="outlined"
                  ref={actionRef1}
                  onClick={() => setOpenMenuSort(true)}
                  endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
                >
                  {sort}
                </SortButton>
                <Menu
                  anchorEl={actionRef1.current}
                  onClose={() => setOpenMenuSort(false)}
                  open={openSort}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {Object.keys(sortings).map(_sort => (
                    <MenuItem
                      key={_sort}
                      onClick={() => {
                        setSort(_sort)
                        setOpenMenuSort(false)
                      }}
                    >
                      {_sort}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </HeaderBox>
            <ListDrawerWrapper variant="permanent" anchor="left" open>
              <Scrollbars id="streamsGrid">
                <Container>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={3}
                  >
                    {isLoading ? (
                      <Grid item xs={12}>
                        <CircularProgress className={styles.circularProgress} />
                      </Grid>
                    ) : allStreams?.length === 0 ? (
                      <Grid item xs={12}>
                        <div className="empty-list-experience">
                          <img alt="no images" src={NoDataImage} />
                          <h1>Seems like there isn't any streams created.</h1>
                        </div>
                      </Grid>
                    ) : (
                      allStreams.map((stream: LiveStreamListData) => {
                        const { createdAt, id, scheduledAt, title, poster } =
                          stream
                        const isStreamSelected = selectedStream === id
                        return (
                          <Grid
                            ref={
                              isStreamSelected ? selectedSpQueryRef : undefined
                            }
                            key={id}
                            item
                            xs={12}
                            sm={4}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              handleSelectOneStream(id)
                            }}
                          >
                            <StreamCard
                              style={{
                                border: isStreamSelected
                                  ? '1px solid blue'
                                  : '1px solid transparent',
                              }}
                            >
                              <CardActionAreaWrapper>
                                <ImageContentWrapper>
                                  <img src={poster?.location} />
                                </ImageContentWrapper>
                              </CardActionAreaWrapper>
                              <Divider />
                              <CardActions
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  p: 2,
                                }}
                              >
                                {title}
                              </CardActions>
                              <CardActions
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  fontSize: '14px',
                                  pl: 2,
                                  pt: 0,
                                  fontWeight: 'bold',
                                  color: 'rgb(153,158,187)',
                                }}
                              >
                                <Moment format="DD MMM YYYY[,] HH:mm">
                                  {scheduledAt}
                                </Moment>
                              </CardActions>
                            </StreamCard>
                          </Grid>
                        )
                      })
                    )}
                  </Grid>
                </Container>
              </Scrollbars>
            </ListDrawerWrapper>
          </>
        )
    }
  }

  return (
    <SequenceContainerWrapper maxWidth="lg">
      {renderGalleryContent()}
      <Scrollbars>
        <DrawerWrapper
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          container={document.body}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '330px',
            },
          }}
        >
          <StreamDataManager
            removeLiveStream={deleteLiveStream}
            stream={activeStream}
            setActiveSection={setActiveSection}
            openEditStreamDialog={() => openEditStreamDialog(activeStream)}
            setActiveLiveStreamVisible={setActiveLiveStreamVisible}
            isActiveLiveStreamVisible={isActiveLiveStreamVisible}
          />
        </DrawerWrapper>
      </Scrollbars>
      <DrawerWrapper
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '330px' },
        }}
        variant="permanent"
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        open
      >
        <StreamDataManager
          removeLiveStream={deleteLiveStream}
          stream={activeStream}
          setActiveSection={setActiveSection}
          openEditStreamDialog={() => openEditStreamDialog(activeStream)}
          setActiveLiveStreamVisible={setActiveLiveStreamVisible}
          isActiveLiveStreamVisible={isActiveLiveStreamVisible}
        />
      </DrawerWrapper>

      <AlertDialog
        open={isModalOpen}
        setOpen={setIsModalOpen}
        isLoading={isLoading}
        handleAction={handleDialogAction}
        title={modalText.title}
        content={modalText.content}
      />
    </SequenceContainerWrapper>
  )
}

export default Library
