import { Draft } from '@reduxjs/toolkit'
import {
  HeadingProps,
  ITreeItem,
  SequenceSlice,
} from 'src/redux/slices/sequenceSlice'
import { v4 as uuidv4 } from 'uuid'

export const getObject = (
  elem: Draft<ITreeItem>,
  id: string,
): Draft<ITreeItem> | null => {
  if (elem['cardId'] === id) return elem
  if (Array.isArray(elem)) {
    for (let el of elem) {
      let temp = getObject(el, id)
      if (temp) return temp
    }
  }
  if (Array.isArray(elem.children)) {
    for (let el of elem.children) {
      let temp = getObject(el, id)
      if (temp) return temp
    }
  }
  return null
}

export const getParent = (
  elem: Draft<ITreeItem>,
  id: string,
  parent: Draft<ITreeItem> | null,
): Draft<ITreeItem> | null => {
  if (elem['cardId'] === id) return parent
  if (Array.isArray(elem)) {
    for (let el of elem) {
      let temp = getParent(el, id, parent)
      if (temp) return temp
    }
  }
  if (Array.isArray(elem.children)) {
    for (let el of elem.children) {
      let temp = getParent(el, id, elem)
      if (temp) return temp
    }
  }
  return null
}

export const changeHeadersOfChildren = (
  elem: Draft<ITreeItem>,
  heading: HeadingProps,
) => {
  if (Array.isArray(elem.children)) {
    for (let el of elem.children) {
      let treeItem = el as ITreeItem
      if (
        treeItem?.heading?.isAsPrevious ||
        treeItem?.headingType === 'INHERIT_PARENT'
      ) {
        treeItem.heading = {
          ...heading,
          isAsPrevious: true,
        }
        changeHeadersOfChildren(treeItem, heading)
      }
    }
  }
}

export const changeIds = (elems: ITreeItem[]) => {
  for (let el of elems) {
    el.cardId = uuidv4()
    if (Array.isArray(el.children)) changeIds(el.children)
  }
}

export const updateCurrentSelectedCard = (state: Draft<SequenceSlice>) => {
  let currentCard = getObject(
    state.sequenceBox.items,
    state.currentProps.currentCard.cardId,
  )
  if (currentCard) state.currentProps.currentCard = currentCard
}
