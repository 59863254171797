import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TreeItem } from 'react-sortable-tree'
import { RootState } from 'src/redux/store'
import {
  DisplaySettings,
  HEADING_TYPE,
  UTMParams,
} from 'src/utils/sequenceSliceHelper'
import {
  changeHeadersOfChildren,
  changeIds,
  getObject,
  getParent,
  updateCurrentSelectedCard,
} from 'src/utils/sequenceSliceUtils'
import { v4 as uuidv4 } from 'uuid'
import {
  AISettings,
  OverlaySettings,
  Template,
  WidgetSettings,
} from '../../api/types'

interface UndoRedo {
  undoRedo: {
    history: (SequenceSlice & UndoRedo)[]
    present: SequenceSlice & UndoRedo
    future: (SequenceSlice & UndoRedo)[]
  }
}

export enum HeadingType {
  noHeading = 'No heading',
  title = 'Title heading',
  video = 'Video heading',
  image = 'Image heading',
  asPrevious = 'As previous',
}

export enum CardTreeType {
  child = 'child',
  parent = 'parent',
}

export enum CardType {
  directLink = 'DIRECT_LINK',
  intermediate = 'INTERMEDIATE',
  videoOnly = 'VIDEO_ONLY',
  root = 'ROOT',
}

export interface NoHeading {
  type: HeadingType
  titleText?: string
  isAsPrevious?: boolean
}

export interface Image extends NoHeading {
  imageId: string
  imageLocation: string
}

export interface Video extends NoHeading {
  videoId: string
  videoLocation: string
}

export interface Title extends NoHeading {
  titleText: string
  styles?: object
}

export interface DirectLink {
  cardType: CardType.directLink
  directLinkUrl: string
  directLinkLabel: string
  styles?: object
}

interface Intermediate {
  cardType: CardType.intermediate
  directLinkUrl?: string
  directLinkLabel?: string
  styles?: object
}

interface VideoOnly {
  cardType: CardType.videoOnly
}

interface Root {
  cardType: CardType.root
  directLinkUrl?: string
  directLinkLabel?: string
  styles?: object
}

export type HeadingProps = NoHeading | Image | Title | Video

export type ImageProps = {
  id: string
  location: string
}

export type CardProps = { styles?: object } & (
  | DirectLink
  | Intermediate
  | VideoOnly
  | Root
)

export interface ITreeItem extends TreeItem {
  cardId?: string
  name?: string
  heading?: HeadingProps
  headingType?: HEADING_TYPE
  cardType?: CardProps
  file?: File
  fileName?: string
  cardTreeType?: CardTreeType
  media?: ImageProps
  tags?: string[]
  liveStreamId: string | null
}

type TUrl = 'includeUrls' | 'excludeUrls'

export interface SequenceSlice {
  sequenceId?: string
  contentName: string
  brandId: string
  status: string
  liveStreamId: string | null
  sequenceBox: {
    items: ITreeItem[]
  }
  currentProps: {
    currentCard: ITreeItem
  }
  headings: {
    [cardId: string]: HeadingProps
  }
  cardType: {
    [cardId: string]: CardProps
  }
  widgetSettings: WidgetSettings
  overlay: OverlaySettings
  template: Template
  ai: AISettings
}

const initialCard: ITreeItem = {
  cardId: uuidv4(),
  cardTreeType: CardTreeType.parent,
  cardType: {
    cardType: CardType.root,
  },
  children: [],
  expanded: true,
  heading: {
    type: HeadingType.video,
    isAsPrevious: false,
  },
  headingType: 'NONE',
  name: 'ROOT',
  tags: [],
  liveStreamId: null,
}

export const initialState: SequenceSlice & UndoRedo = {
  sequenceId: '',
  contentName: '',
  brandId: '',
  status: 'Draft',
  sequenceBox: {
    items: [initialCard],
  },
  currentProps: {
    currentCard: initialCard,
  },
  headings: {},
  cardType: {},
  widgetSettings: {
    displayDeviceTypeSettings: {
      enabled: false,
      mobile: true,
      tablet: true,
      desktop: true,
    },
    displaySettings: {
      enabled: true,
      defaultDisplayType: 'Minimized',
      defaultPlacement: 'BottomRight',
      rememberClose: false,
      resetExperienceInNewWindow: false,
    },
    utm: {
      enabled: false,
      content: '',
      medium: '',
      campaign: '',
      source: '',
      term: '',
    },
    openLastCardURLsInNewTab: false,
    showOnAllLastCardURLs: true,
    includeUrls: [],
    excludeUrls: [],
  },
  overlay: {
    url: '',
  },
  template: {
    isGlobalTemplate: false,
    isOrganizationTemplate: false,
    isCreatorTemplate: false,
  },
  ai: {
    cardFocusedObjectType: 'NONE',
  },
  undoRedo: {
    history: [],
    present: null,
    future: [],
  },
}

const handleUndoRedoChange = (state: SequenceSlice & UndoRedo) => {
  let temp = state.undoRedo.present
  state.undoRedo.present = state
  if (state.undoRedo.present != null) {
    state.undoRedo.history.push(temp)
  }

  if (state.undoRedo.history.length > 100) {
    state.undoRedo.history.shift()
  }
}

const sequenceSlice = createSlice({
  name: 'sequence',
  initialState,
  reducers: {
    setSequenceId(state, action: PayloadAction<string>) {
      state.sequenceId = action.payload
    },
    setSequenceStatus(state, action: PayloadAction<string>) {
      state.status = action.payload
    },
    resetSequenceData(state, action: PayloadAction) {
      return (state = initialState)
    },
    setSequenceSliceData(state, action: PayloadAction<SequenceSlice>) {
      return (state = {
        ...action.payload,
        undoRedo: state.undoRedo,
      })
    },
    setContentName(state, action: PayloadAction<string>) {
      state.contentName = action.payload
    },
    setBrandId(state, action: PayloadAction<string>) {
      state.brandId = action.payload
    },
    setTreeItems(state, action: PayloadAction<ITreeItem[]>) {
      state.sequenceBox.items = action.payload
      let found = getObject(
        action.payload,
        state.currentProps.currentCard.cardId,
      )
      if (found) {
        if (
          Array.isArray(found.children) &&
          found.children.length > 0 &&
          found.cardType.cardType !== CardType.root
        ) {
          found = {
            ...found,
            cardTreeType: CardTreeType.parent,
            cardType: {
              cardType: CardType.intermediate,
            },
          }
          state.currentProps.currentCard = found
        } else {
          found = {
            ...found,
            cardTreeType: CardTreeType.child,
            cardType: {
              ...found.cardType,
              cardType: CardType.directLink,
            } as DirectLink,
          }
          state.currentProps.currentCard = found
        }
      }
    },
    addNewTreeItem(state, action: PayloadAction<ITreeItem>) {
      state.sequenceBox.items.push(action.payload)
    },
    changeCardLabelStyles(state, action: PayloadAction<object>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      if (found) {
        if ([undefined, null].includes(found.cardType.styles))
          found.cardType.styles = {}
        found.cardType.styles = Object.assign(
          found.cardType.styles,
          action.payload,
        )
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
    },
    changeCardHeader(
      state,
      action: PayloadAction<{ cardId: string; options: HeadingProps }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found) {
        if (action.payload.options.type === HeadingType.asPrevious) {
          let parentCard = getParent(
            state.sequenceBox.items,
            action.payload.cardId,
            null,
          )
          if (parentCard) {
            if (found.heading) {
              found.heading = Object.assign(found.heading, parentCard.heading)
            } else {
              found.heading = parentCard.heading
            }
            found.heading.isAsPrevious = true
            found.headingType = 'INHERIT_PARENT'
            // updateCurrentSelectedCard(state);
            state.currentProps.currentCard = found
          }
        } else {
          if (found.heading) {
            found.heading = Object.assign(found.heading, action.payload.options)
          } else {
            found.heading = action.payload.options
          }
          if ('titleText' in found.heading) {
            found.headingType = 'TEXT'
          }
          if ('imageLocaton' in found.heading) {
            found.headingType = 'IMAGE'
          } else if ('videoLocation' in found.heading) {
            found.headingType = 'VIDEO'
          }
          found.heading.isAsPrevious = false
          // updateCurrentSelectedCard(state);
          state.currentProps.currentCard = found
        }
        if (found.cardTreeType === CardTreeType.parent) {
          changeHeadersOfChildren(found, found.heading)
        }
      }
    },
    changeHeaderStyles(state, action: PayloadAction<object>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      if (found) {
        if (found.heading.type === HeadingType.title) {
          let temp = found.heading as Title
          if ([undefined, null].includes(temp.styles)) temp.styles = {}
          temp.styles = Object.assign(temp.styles, action.payload)
          // updateCurrentSelectedCard(state);
          state.currentProps.currentCard = found
        }
      }
    },
    changeImageProps(
      state,
      action: PayloadAction<{ cardId: string; props: ImageProps }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found) {
        found.media = action.payload.props
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
      state.currentProps.currentCard = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
    },
    changeCardType(
      state,
      action: PayloadAction<{ cardId: string; options: CardProps }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found && found.cardType.cardType != CardType.root) {
        // cannot change type of root card
        found.cardType = action.payload.options
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
    },
    setCurrentCard(state, action: PayloadAction<{ card: ITreeItem }>) {
      if (action.payload) {
        state.currentProps.currentCard = action.payload.card
      } else {
        state.currentProps.currentCard = state.sequenceBox.items[0]
      }
    },
    addFileToCard(state, action: PayloadAction<{ file: File }>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.file = action.payload.file
      found.fileName = action.payload.file.name
      state.currentProps.currentCard = found
    },
    addLiveStreamToCard(state, action: PayloadAction<any>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.liveStreamId = action.payload.id
      state.currentProps.currentCard = found
    },
    removeLiveStreamFromCard(state) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.liveStreamId = null
      state.currentProps.currentCard = found
    },
    changeCardTreeType(
      state,
      action: PayloadAction<{
        cardId: string
        treeType: CardTreeType
        type: CardType
      }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found) {
        found.cardTreeType = action.payload.treeType
        if (found.cardType.cardType !== CardType.root) {
          // cannot change type of root card
          found.cardType.cardType = action.payload.type
        }
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
    },
    duplicateTreeItem(
      state,
      action: PayloadAction<{ parentId: string; itemId: string }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.parentId)
      if (Array.isArray(found.children)) {
        let copyItem: ITreeItem = JSON.parse(
          JSON.stringify(
            found.children.find(
              (el: ITreeItem) => el.cardId === action.payload.itemId,
            ),
          ),
        )
        copyItem.cardId = uuidv4()
        if (Array.isArray(copyItem.children)) changeIds(copyItem.children)
        let tempArr = [...found.children, copyItem]
        if (tempArr.length > 6) tempArr = tempArr.slice(0, 6)
        if (copyItem) found.children = tempArr
      }
    },
    addTagsToCard(state, action: PayloadAction<string[]>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.tags = action.payload
      // updateCurrentSelectedCard(state);
      state.currentProps.currentCard = found
    },
    changeWidgetSetting(
      state,
      action: PayloadAction<{
        key: keyof DisplaySettings
        value: boolean
      }>,
    ) {
      state.widgetSettings.displaySettings[action.payload.key] =
        action.payload.value
    },
    setGlobalTemplate(
      state,
      action: PayloadAction<{ isGlobalTemplate: boolean }>,
    ) {
      state.template.isGlobalTemplate = action.payload.isGlobalTemplate
    },
    setOrganizationTemplate(
      state,
      action: PayloadAction<{ isOrganizationTemplate: boolean }>,
    ) {
      state.template.isOrganizationTemplate =
        action.payload.isOrganizationTemplate
    },
    setCreatorTemplate(
      state,
      action: PayloadAction<{ isCreatorTemplate: boolean }>,
    ) {
      state.template.isCreatorTemplate = action.payload.isCreatorTemplate
    },
    setDisplayDeviceTypeEnabled(
      state,
      action: PayloadAction<{ enabled: boolean }>,
    ) {
      state.widgetSettings.displayDeviceTypeSettings.enabled =
        action.payload.enabled
    },
    setDisplayDeviceTypeMobile(
      state,
      action: PayloadAction<{ mobile: boolean }>,
    ) {
      state.widgetSettings.displayDeviceTypeSettings.mobile =
        action.payload.mobile
    },
    setDisplayDeviceTypeTablet(
      state,
      action: PayloadAction<{ tablet: boolean }>,
    ) {
      state.widgetSettings.displayDeviceTypeSettings.tablet =
        action.payload.tablet
    },
    setDisplayDeviceTypeDesktop(
      state,
      action: PayloadAction<{ desktop: boolean }>,
    ) {
      state.widgetSettings.displayDeviceTypeSettings.desktop =
        action.payload.desktop
    },
    toggleShowOnAll(state) {
      state.widgetSettings.showOnAllLastCardURLs =
        !state.widgetSettings.showOnAllLastCardURLs
    },
    toggleOpenLastCardLinksInNewTab(state) {
      state.widgetSettings.openLastCardURLsInNewTab =
        !state.widgetSettings.openLastCardURLsInNewTab
    },
    setDefaultDisplayType(
      state,
      action: PayloadAction<{
        displayType:
          | 'MaximizedExpanded'
          | 'Maximized'
          | 'Minimized'
          | 'Small'
          | 'Notification'
      }>,
    ) {
      state.widgetSettings.displaySettings.defaultDisplayType =
        action.payload.displayType
    },
    setDefaultPlacement(
      state,
      action: PayloadAction<{
        placement: 'BottomRight' | 'BottomLeft' | 'TopLeft' | 'TopRight'
      }>,
    ) {
      state.widgetSettings.displaySettings.defaultPlacement =
        action.payload.placement
    },
    setObjectFocusType(
      state,
      action: PayloadAction<{
        focusType: 'FACE' | 'ACCESSORY' | 'OUTFIT' | 'UNKNOWN' | 'ANY' | 'NONE'
      }>,
    ) {
      state.ai.cardFocusedObjectType = action.payload.focusType
    },
    addUrl(
      state,
      action: PayloadAction<{
        key: TUrl
        value: string
      }>,
    ) {
      let { key, value } = action.payload
      state.widgetSettings[key] = [...state.widgetSettings[key], value]
    },
    overrideUrls(
      state,
      action: PayloadAction<{
        key: TUrl
        value: string[]
      }>,
    ) {
      let { key, value } = action.payload
      if (Array.isArray(value)) state.widgetSettings[key] = value
      else state.widgetSettings[key] = [value]
    },
    removeUrl(
      state,
      action: PayloadAction<{
        key: TUrl
        value: string
      }>,
    ) {
      let { key, value } = action.payload
      state.widgetSettings[key] = state.widgetSettings[key].filter(
        el => el !== value,
      )
    },
    changeUtmParam(
      state: SequenceSlice,
      action: PayloadAction<{ key: keyof UTMParams; value: string }>,
    ) {
      const { key, value } = action.payload
      if (key !== 'enabled') {
        state.widgetSettings.utm[key] = value
      }
    },
    resetForm(state, action?: PayloadAction<WidgetSettings>) {
      state.widgetSettings = action.payload || initialState.widgetSettings
    },
    toggleUtm(state, action: PayloadAction<boolean>) {
      state.widgetSettings.utm.enabled = action.payload
    },
    changeOverlayUrl(state, action: PayloadAction<string>) {
      state.overlay.url = action.payload
    },
    saveActionToHistory(state) {
      handleUndoRedoChange(state)
    },
    undoChange(state) {
      const previousChange = state.undoRedo.history.pop()
      if (previousChange) {
        state.undoRedo.future.push(state.undoRedo.present)
        state.undoRedo.present = previousChange
        state.sequenceBox = previousChange.sequenceBox
        state.currentProps.currentCard = previousChange.currentProps.currentCard
        updateCurrentSelectedCard(state)
      }
    },
    redoChange(state) {
      const futureChange = state.undoRedo.future.pop()
      if (futureChange) {
        state.undoRedo.history.push(state.undoRedo.present)
        state.undoRedo.present = futureChange
        state.sequenceBox = futureChange.sequenceBox
        state.currentProps.currentCard = futureChange.currentProps.currentCard
        updateCurrentSelectedCard(state)
      }
    },
  },
})

// SELECTORS
export const selectUtmParams = (state: RootState) =>
  state.sequenceReducer.widgetSettings.utm
export const selectShowUrls = (state: RootState) =>
  state.sequenceReducer.widgetSettings.includeUrls

export const {
  setSequenceId,
  resetSequenceData,
  setSequenceSliceData,
  setSequenceStatus,
  setContentName,
  setBrandId,
  setTreeItems,
  addNewTreeItem,
  setCurrentCard,
  changeCardHeader,
  changeCardType,
  addFileToCard,
  changeCardTreeType,
  changeImageProps,
  changeCardLabelStyles,
  duplicateTreeItem,
  changeHeaderStyles,
  addTagsToCard,
  changeWidgetSetting,
  addUrl,
  removeUrl,
  overrideUrls,
  toggleShowOnAll,
  toggleOpenLastCardLinksInNewTab,
  changeUtmParam,
  resetForm,
  toggleUtm,
  changeOverlayUrl,
  undoChange,
  redoChange,
  setGlobalTemplate,
  setOrganizationTemplate,
  setCreatorTemplate,
  setDisplayDeviceTypeEnabled,
  setDisplayDeviceTypeMobile,
  setDisplayDeviceTypeTablet,
  setDisplayDeviceTypeDesktop,
  setObjectFocusType,
  setDefaultDisplayType,
  setDefaultPlacement,
  addLiveStreamToCard,
  removeLiveStreamFromCard,
} = sequenceSlice.actions
export default sequenceSlice.reducer
